import { useConfig } from '../../providers/config'
import MultiSendABI_0_2_4 from '../../abi/v0.2.4/MultiSend.json'
import ITermPriceOracleABI_0_2_4 from '../../abi/v0.2.4/ITermPriceOracle.json'
import { ITermPriceOracle as ITermPriceOracle_0_2_4 } from '../../abi-generated/abi/v0.2.4/ITermPriceOracle'
import { MultiSend as MultiSend_0_2_4 } from '../../abi-generated/abi/v0.2.4/MultiSend'
import { Wrappable } from '../../abi-generated'
import WrappableABI from '../../abi-external/Wrappable.json'
import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Contract } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useClock } from '../../providers/time'
import { useGlobalRefresher } from '../../providers/refresher'
import {
  AppraiseWalletResponse,
  BlockNumberMetadata,
} from '../model'
import { CallResult, ChainId, useCalls } from '@usedapp/core'
import { AnyVariables, Client, UseQueryArgs } from 'urql'
import { useClient, useClients } from '../../providers/graph-provider'
import { captureException } from '@sentry/react'
import config from '../../config'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { paths } from '../../models/profile-api'
import { paths as protocolPaths } from '../../models/protocol-api'
import { DEFAULT_BUCKET_SIZE_SECONDS } from '../../helpers/constants'
import { useAnalytics } from '../analytics/use-analytics'
import { SourceTrack } from '../analytics/model'
import { keccak256, toUtf8Bytes } from 'ethers/lib/utils'

type Query = {
  chainId: ChainId
  client: Client
  query: UseQueryArgs['query']
  variables: UseQueryArgs['variables']
}

type UseMultiEndpointQueryResult<T> = {
  results: Record<string, T>
  fetching: boolean
  error: Error | undefined
  refresh: (isIgnoreCache?: boolean) => Promise<void>
}

type UseMyCustomQueryArgs<V extends AnyVariables> = Omit<
  UseQueryArgs<V>,
  'context'
> & {
  chainId: ChainId
  url: string
  variables: V // Ensure variables is always defined
}

export interface CallObject {
  contract: Contract
  method: string
  args: any[]
  meta?: any
}

export interface MultichainCalls {
  [chainId: string]: CallObject[]
}

const emptyArgs = [] as any[]

export function useDebug(
  label: string,
  value: any,
  printTrace: boolean = false
) {
  useEffect(() => {
    console.log(`useDebug - ${label}: `, value)
    if (printTrace) {
      console.trace()
    }
  }, [label, printTrace, value])
}

// Handles calls across multiple chains and returns results grouped by chain id
export function useMultichainCalls(calls: MultichainCalls | undefined) {
  const mainnetCalls = useCalls(calls?.[ChainId.Mainnet] ?? emptyArgs, {
    chainId: ChainId.Mainnet,
  })
  const avalancheCalls = useCalls(calls?.[ChainId.Avalanche] ?? emptyArgs, {
    chainId: ChainId.Avalanche,
  })
  const baseCalls = useCalls(calls?.[ChainId.Base] ?? emptyArgs, {
    chainId: ChainId.Base,
  })
  const polygonCalls = useCalls(calls?.[ChainId.Polygon] ?? emptyArgs, {
    chainId: ChainId.Polygon,
  })
  const sepoliaCalls = useCalls(calls?.[ChainId.Sepolia] ?? emptyArgs, {
    chainId: ChainId.Sepolia,
  })
  const mumbaiCalls = useCalls(calls?.[ChainId.Mumbai] ?? emptyArgs, {
    chainId: ChainId.Mumbai,
  })
  const bscCalls = useCalls(calls?.[ChainId.BSC] ?? emptyArgs, {
    chainId: ChainId.BSC,
  })
  const arbitrumCalls = useCalls(calls?.[ChainId.Arbitrum] ?? emptyArgs, {
    chainId: ChainId.Arbitrum,
  })

  const localhostCalls = useCalls(calls?.[ChainId.Localhost] ?? emptyArgs, {
    chainId: ChainId.Localhost,
  })

  const hardhatCalls = useCalls(calls?.[ChainId.Hardhat] ?? emptyArgs, {
    chainId: ChainId.Hardhat,
  })

  return useMemo(() => {
    const results: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}

    // if (!calls) {
    //   return undefined
    // }

    if (calls?.[ChainId.Mainnet]) {
      results[ChainId.Mainnet] = mainnetCalls
    }

    if (calls?.[ChainId.Avalanche]) {
      results[ChainId.Avalanche] = avalancheCalls
    }

    if (calls?.[ChainId.Base]) {
      results[ChainId.Base] = baseCalls
    }

    if (calls?.[ChainId.Polygon]) {
      results[ChainId.Polygon] = polygonCalls
    }

    if (calls?.[ChainId.Sepolia]) {
      results[ChainId.Sepolia] = sepoliaCalls
    }

    if (calls?.[ChainId.Mumbai]) {
      results[ChainId.Mumbai] = mumbaiCalls
    }

    if (calls?.[ChainId.BSC]) {
      results[ChainId.BSC] = bscCalls
    }

    if (calls?.[ChainId.Arbitrum]) {
      results[ChainId.Arbitrum] = arbitrumCalls
    }

    if (calls?.[ChainId.Localhost]) {
      results[ChainId.Localhost] = localhostCalls
    }

    if (calls?.[ChainId.Hardhat]) {
      results[ChainId.Hardhat] = hardhatCalls
    }

    return results
  }, [
    calls,
    mainnetCalls,
    avalancheCalls,
    baseCalls,
    polygonCalls,
    sepoliaCalls,
    mumbaiCalls,
    bscCalls,
    arbitrumCalls,
    localhostCalls,
    hardhatCalls,
  ])
}

// query multiple subgraphs for data and combine response
const useMultiEndpointQuery = <T>(
  queries: Query[]
): UseMultiEndpointQueryResult<T> => {
  const [results, setResults] = useState<{ [chainId: string]: T }>({})
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const { trackEvent } = useAnalytics()

  const fetchData = useCallback(
    async (isIgnoreCache?: boolean) => {
      // Check if all clients are available before fetching
      if (queries.some((query) => query.client === undefined)) {
        console.debug('subgraph clients not ready')
        return
      }

      setFetching(true)
      setError(undefined)

      try {
        const graphData = await Promise.all(
          queries.map(async (query) => {
            // @ts-ignore
            const queryName = query.query?.definitions?.[0]?.name?.value

            const hash = `${queryName}_${query.chainId.toString()}_${keccak256(toUtf8Bytes(JSON.stringify(query.variables)))}`
            trackEvent(
              'subgraph',
              hash,
              {
                chainId: query.chainId.toString(),
                url: query.client.name,
                query: queryName,
                variables: JSON.stringify(query.variables),
              },
              SourceTrack.reactGA
            )

            const { data, error } = await query.client
              .query(
                query.query,
                query.variables,
                isIgnoreCache
                  ? { fetchOptions: { headers: { 'x-no-cache': 'true' } } }
                  : undefined
              )
              .toPromise()

            // Check if the response contains errors
            if (error) {
              // Extract the error message from the response
              const errorMessage = error.graphQLErrors
                .map((error: { message: string }) => error.message)
                .join(', ')

              // Handle network errors
              const networkError = error.networkError?.message
                ? ` Network error: ${error.networkError.message}`
                : ''

              throw new Error(
                'chainID: ' +
                  query.chainId +
                  ' - ' +
                  errorMessage +
                  networkError
              )
            }

            return { chainId: query.chainId, data }
          })
        )

        const combinedGraphResponse = graphData.reduce(
          (acc, { chainId, data }) => {
            acc[chainId] = data
            return acc
          },
          {} as Record<string, T>
        )
        setResults(combinedGraphResponse)
      } catch (err) {
        if (err instanceof Error) {
          setError(err)
        } else {
          setError(new Error('An unknown error occurred'))
        }
        console.error('error fetching graph data: ', err)
        captureException(err)
      } finally {
        setFetching(false)
      }
    },
    [queries]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { results, fetching, error, refresh: fetchData }
}

/**
 * This extends the functionality of URQL's useQuery by allowing queries to be made to a specified GraphQL endpoint.
 * The correct graph client is chosen by the URL parameter
 * @param args
 * @returns
 */
export const useGraphQuery = <T, V extends AnyVariables>(
  args: UseMyCustomQueryArgs<V>
): {
  results: T
  fetching: boolean
  error: Error | undefined
  refresh: (isIgnoreCache?: boolean) => Promise<void>
} => {
  const { chainId, query, variables, url } = args
  const client = useClient(url)

  const queries = useMemo(
    () => [
      {
        chainId,
        client,
        query,
        variables,
      } as Query,
    ],
    [chainId, client, query, variables]
  )

  const { results, fetching, error, refresh } =
    useMultiEndpointQuery<T>(queries)

  const singleResult = results[chainId]

  return { results: singleResult, fetching, error, refresh }
}

/**
 * Uses a custom hook to query multiple graphql endpoints and returns results indexed by ChainId
 * Exposes a refresh callback too
 * @param argsArray An array of arguments where each item is an object of type UseMyCustomQueryArgs<V>. Each object includes:
    url: The endpoint URL for the GraphQL client.
    chainId: A unique identifier for the blockchain associated with the query.
    query: The GraphQL query string.
    variables: An object representing the variables to be passed to the GraphQL query
 * @returns the following:
    results: A record object where keys are chainId strings and values are arrays of type T. Each array represents the results of the GraphQL query associated with the corresponding chainId.
    fetching: A boolean indicating whether any of the GraphQL queries are currently being fetched.
    error: An optional Error object, present if an error occurred during any of the GraphQL queries.
    refresh: A function that, when invoked, will re-fetch the GraphQL queries.
 */
export const useGraphQueries = <T, V extends AnyVariables>(
  argsArray: UseMyCustomQueryArgs<V>[]
): {
  results: { [chainId: string]: T }
  fetching: boolean
  error: Error | undefined
  refresh: (isIgnoreCache?: boolean) => Promise<void>
} => {
  const urls = useMemo(() => argsArray.map((args) => args.url), [argsArray])
  const clients = useClients(urls)

  const queries = useMemo(() => {
    return argsArray
      .map((args, index) => {
        const { chainId, query, variables } = args
        const client = clients[index]
        return client
          ? ({
              chainId,
              client,
              query,
              variables,
            } as Query)
          : undefined
      })
      .filter(Boolean) as Query[]
  }, [argsArray, clients])

  const { results, fetching, error, refresh } =
    useMultiEndpointQuery<T>(queries)

  return { results, fetching, error, refresh }
}

export function useMultiSends(
  provider: JsonRpcProvider | FallbackProvider | undefined
) {
  const config = useConfig()
  const chainConfig = config.chains

  // TODO: all chains are hardcoded to same abi version!

  return useMemo(() => {
    const multisends: Record<string, MultiSend_0_2_4> = {}
    Object.entries(chainConfig).forEach(([chainId, chainConfig]) => {
      if (chainConfig.contracts.multisend) {
        multisends[chainId] = new Contract(
          chainConfig.contracts.multisend,
          MultiSendABI_0_2_4,
          provider
        ) as MultiSend_0_2_4
      }
    })
    return multisends
  }, [provider, chainConfig])
}

export function useWrappedGasTokens(
  provider: JsonRpcProvider | FallbackProvider | undefined
) {
  const config = useConfig()
  const chainConfig = config.chains

  // TODO: all chains are hardcoded to same abi version!

  return useMemo(() => {
    const wrappedGasTokens: Record<string, Wrappable> = {}
    Object.entries(chainConfig).forEach(([chainId, chainConfig]) => {
      wrappedGasTokens[chainId] = new Contract(
        chainConfig.contracts.wrappedGasToken,
        WrappableABI,
        provider
      ) as Wrappable
    })
    return wrappedGasTokens
  }, [provider, chainConfig])
}

export function usePriceOracles(
  provider: JsonRpcProvider | FallbackProvider | undefined
) {
  const config = useConfig()
  const chainConfig = config.chains

  // TODO: all chains are hardcoded to same abi version!
  return useMemo(() => {
    const oracles: Record<string, ITermPriceOracle_0_2_4> = {}
    Object.entries(chainConfig).forEach(([chainId, chainConfig]) => {
      oracles[chainId] = new Contract(
        chainConfig.contracts.termPriceOracle,
        ITermPriceOracleABI_0_2_4,
        provider
      ) as ITermPriceOracle_0_2_4
    })
    return oracles
  }, [provider, chainConfig])
}

export function useCurrentTime(): Dayjs {
  const { now } = useClock()
  // Requires RefreshingProvider to be used.
  const { fast: autoRefresher } = useGlobalRefresher()
  return useMemo(now, [now, autoRefresher])
}

export function useCurrentTimeSlow(): Dayjs {
  const { now } = useClock()
  // Requires RefreshingProvider to be used.
  const { slow: autoRefresher } = useGlobalRefresher()
  return useMemo(now, [now, autoRefresher])
}

export function useBucketedCurrentTime(
  bucketIntervalInSeconds?: number
): Dayjs {
  const { now } = useClock()
  const { slow: autoRefresher } = useGlobalRefresher()
  return useMemo(() => {
    const current = now()
    const currentUnix = current.unix()
    const bucketedUnix =
      currentUnix -
      (currentUnix % (bucketIntervalInSeconds ?? DEFAULT_BUCKET_SIZE_SECONDS))
    return dayjs.unix(bucketedUnix)
  }, [now, bucketIntervalInSeconds, autoRefresher])
}

export function useTxBlock(
  provider: JsonRpcProvider | FallbackProvider | undefined
) {
  const lookup = useCallback(
    async (txHash: string) => {
      if (!provider) {
        return
      }
      const receipt = await provider.getTransactionReceipt(txHash)
      if (!receipt) {
        return
      }
      return await provider.getBlock(receipt.blockNumber)
    },
    [provider]
  )
  return lookup
}

export function useTxTimestamp(
  provider: JsonRpcProvider | FallbackProvider | undefined
) {
  const blockLookup = useTxBlock(provider)
  const lookup = useCallback(
    async (txHash: string) => {
      const block = await blockLookup(txHash)
      return block?.timestamp
    },
    [blockLookup]
  )
  return lookup
}

export function useOutsideClick(
  containerRef: React.RefObject<any>,
  onClose: () => void
) {
  useEffect(() => {
    const detectClickOutside = (event: Event) => {
      if (
        !!containerRef &&
        !containerRef?.current?.contains(event.target as HTMLElement)
      ) {
        onClose()
      }
    }
    document.addEventListener('click', detectClickOutside)
    return () => document.removeEventListener('click', detectClickOutside)
  }, [containerRef, onClose])
}

export const fetchKytStatus = async (
  account: string | undefined,
  guardianServerUrl: string,
  isMainnet: boolean
): Promise<boolean> => {
  try {
    if (account === undefined) {
      return false
    }

    if (!isMainnet) {
      console.info('Not production - AML/KYT service check skipped')
      return true
    }

    const response = await fetch(`${guardianServerUrl}/wallet/${account}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    })

    // service is not available
    if (!response.ok) {
      console.error('AML/KYT service unavailable, bad response')
      return false
    }
    const kyt = (await response.json()) as AppraiseWalletResponse
    // wallet is denied
    if (!kyt?.allowed) {
      console.error('AML/KYT service check fails: %o', kyt.reason)
      return false
      // wallet is allowed
    } else {
      console.info('AML/KYT service check passes')
      return true
    }
  } catch (error) {
    // an unexpected error occurred while checking kyt status
    console.error('AML/KYT service check error: %o', error)
    return false
  }
}

export const fetchBlockNumber = async (
  chainId: string
): Promise<{
  blockNumber: number
  hasError: boolean
}> => {
  try {
    if (chainId === undefined) {
      throw new Error('chainId is undefined')
    }

    const subgraphUrl = config?.chains?.[chainId]?.subgraphUrl

    if (subgraphUrl === undefined) {
      throw new Error('subgraphUrl is undefined')
    }

    const response = await fetch(`${subgraphUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `{_meta { block { number hash timestamp } hasIndexingErrors } }`,
      }),
    })

    // service is not available
    if (!response.ok) {
      throw new Error('unable to fetch latest block number')
    }

    const jsonResponse = (await response.json()).data as BlockNumberMetadata

    const blockNumber = jsonResponse._meta.block.number

    if (jsonResponse._meta.hasIndexingErrors) {
      console.warn('subgraph has indexing errors')
    }

    return {
      blockNumber,
      hasError: false,
    }
  } catch (error) {
    console.error('Error fetching block number: ', error)
    captureException(error)
    return {
      blockNumber: 0,
      hasError: true,
    }
  }
}

export const useProfilePublicData = (account?: string, token?: string) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const headers = useMemo(() => {
    const h = new Headers()
    h.set('Content-Type', 'application/json')
    if (token) {
      h.set('Authorization', `Bearer ${token}`)
    }
    return h
  }, [token])
  const { data, error, isLoading, refresh } = useJsonRestCalls<
    | {
        '#call': RequestInfo
      }
    | {},
    paths['/profile/profile-public/{address}']['get']['responses']['200']['content']['application/json']
  >(
    useMemo(
      () =>
        account
          ? {
              '#call': {
                url: `${config.profileServerUrl}/profile-public/${account}`,
                method: 'GET',
                headers,
              } as RequestInfo,
            }
          : {},
      [account, headers]
    )
  )

  useEffect(() => {
    if (!isLoading) {
      setIsCompleted(true)
    }
  }, [isLoading])

  return {
    data: isCompleted ? data : undefined,
    error: isCompleted ? error : undefined,
    isLoading,
    refresh,
  }
}

export const useProfileData = (token: string | undefined | null) => {
  const headers = useMemo(() => {
    const h = new Headers()
    h.set('Content-Type', 'application/json')
    if (token) {
      h.set('Authorization', `Bearer ${token}`)
    }
    return h
  }, [token])
  const { data, error, isLoading, refresh } = useJsonRestCalls<
    | {
        '#call': RequestInfo
      }
    | {},
    paths['/profile/profile']['get']['responses']['200']['content']['application/json']
  >(
    token
      ? {
          '#call': {
            url: `${config.profileServerUrl}/profile`,
            method: 'GET',
            headers,
          } as RequestInfo,
        }
      : {}
  )

  return {
    data,
    error,
    isLoading,
    refresh,
  }
}

export const useIdleVaultRates = (
  llamaPools?: string[]
): { [llamaPools: string]: number | undefined } | undefined => {
  const queries = useMemo(() => {
    if (!llamaPools?.length) return {}

    return llamaPools.reduce(
      (acc, poolId) => {
        const url = `${config.protocolServerUrl}/protocol/defillama-proxy/pools-enriched?pool=${poolId}`
        acc[poolId] = {
          '#call': {
            url,
            method: 'GET',
          } as RequestInfo,
        }
        return acc
      },
      {} as Record<string, { '#call': RequestInfo }>
    )
  }, [llamaPools])

  const {
    data: idleVaultRates,
    // error,
    // isLoading,
  } = useJsonRestCalls<
    typeof queries,
    Record<
      string,
      Record<
        string,
        protocolPaths['/protocol/defillama-proxy/pools-enriched']['get']['responses']['200']['content']['application/json']
      >
    >
  >(queries)

  return useMemo(() => {
    if (!idleVaultRates) {
      return undefined
    }

    const result: Record<string, number | undefined> = {}
    Object.entries(idleVaultRates).forEach(([id, rate]) => {
      result[id] = parseFloat((rate[id].data?.[0]?.apy ?? 0).toFixed(3))
    })

    return result
  }, [idleVaultRates])
}
