import { Box, Text, Link, CloseButton } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import website_icon from '../../../../assets/icons/website.svg'
import twitter_icon from '../../../../assets/icons/twitter_black.svg'
import linkedin_icon from '../../../../assets/icons/linkedin_black.svg'
import { VaultStatistics } from '../../../../models/vault'

export default function AboutCuratorBox({
  vaultStatistics,
  handleCloseBox,
}: {
  vaultStatistics: VaultStatistics
  handleCloseBox: () => void
}) {
  return (
    <Box
      p={6}
      bg="blue.1"
      borderBottomLeftRadius="8px"
      borderBottomRightRadius="8px"
    >
      <HStack justifyContent="space-between">
        <HStack>
          <Text variant="body-l/semibold">{vaultStatistics?.vaultCurator}</Text>

          <HStack spacing={0}>
            {vaultStatistics?.links?.website && (
              <Link
                href={vaultStatistics?.links?.website}
                isExternal
                color="blue.5"
                textDecoration="none"
              >
                <img src={website_icon} alt="Website" />
              </Link>
            )}

            {vaultStatistics?.links?.twitter && (
              <Link
                href={vaultStatistics?.links?.twitter}
                isExternal
                color="blue.5"
                textDecoration="none"
              >
                <img src={twitter_icon} alt="Twitter" />
              </Link>
            )}

            {vaultStatistics?.links?.linkedIn && (
              <Link
                href={vaultStatistics?.links?.linkedIn}
                isExternal
                color="blue.5"
                textDecoration="none"
              >
                <img src={linkedin_icon} alt="Linkedin" />
              </Link>
            )}
          </HStack>
        </HStack>
        <CloseButton colorScheme="blue" onClick={handleCloseBox} />
      </HStack>

      <HStack mt={1} alignItems="start" spacing={8}>
        <Text variant="body-md/normal" color="gray.6" w="50%">
          {vaultStatistics?.about?.curator}
        </Text>
        <Text variant="body-md/normal" color="gray.6" w="50%">
          {' '}
          <strong>Strategy:</strong> {vaultStatistics?.about?.strategy}
        </Text>
      </HStack>
    </Box>
  )
}
