import { Box, BoxProps, HStack, Skeleton, Text, VStack } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import CollateralHoldingBar from '../CollateralHoldingBar'
import CollateralPieChart from './CollateralPieChart'
import HoldingsBarChart from './HoldingsBarChart'
import {
  groupRepoTokenHoldingsByCollateral,
  groupRepoTokenHoldingsByMaturity,
} from '../../utils'
import { useMemo } from 'react'
import { VaultHolding } from '../../../../models/vault'

type Props = {
  isActiveTab: boolean // this is to handle recharts rendering issue, stop repeated console warnings
  idleCapitalUsd: FixedNumber
  holdings: VaultHolding[]
  isDataLoaded?: boolean
}

function ChartCard(props: { children?: React.ReactNode } & BoxProps) {
  return <Box p="20px" bg="blue.0" borderRadius="6px" {...props} />
}

const COLORS = ['#5FD0FD', '#FBD38D', '#9375FF', '#0A5ED9', '#9AE6B4']
const OPACITY_CODES = [
  'FF', // 100% opacity (fully opaque)
  // "E6", // 90% opacity
  'CC', // 80% opacity
  // "B3", // 70% opacity
  '99', // 60% opacity
  // "80", // 50% opacity
  '66', // 40% opacity
  // "4D", // 30% opacity
  '33', // 20% opacity
  // "1A", // 10% opacity
]

export const getColor = (index: number): string => {
  let color = COLORS[index % COLORS.length]
  const d = Math.floor(index / COLORS.length)
  const opacity = OPACITY_CODES[d % OPACITY_CODES.length]

  return `${color}${opacity}`
}

export default function VaultCharts({
  isActiveTab,
  holdings,
  idleCapitalUsd,
  isDataLoaded = true,
}: Props) {
  const pieChartData = useMemo(
    () =>
      holdings.length > 0 ? groupRepoTokenHoldingsByCollateral(holdings) : [],
    [holdings]
  )
  const barChartValues = useMemo(
    () =>
      holdings.length > 0
        ? groupRepoTokenHoldingsByMaturity(holdings, idleCapitalUsd)
        : [],
    [holdings, idleCapitalUsd]
  )

  return (
    <HStack spacing="42px" alignItems="start">
      <Skeleton isLoaded={isDataLoaded} h="328px" w="514px">
        <ChartCard w="514px">
          <Box mb="30px">
            <Text variant="body-l/semibold" color="blue.9">
              Holdings Breakdown by Maturity
            </Text>
            <Text variant="body-md/normal" color="gray.6" mt="2px">
              The maturity of the REPO tokens held in vault
            </Text>
          </Box>
          <Box maxWidth="468px" w="100%" h="208px">
            <HoldingsBarChart
              isActiveTab={isActiveTab}
              holdings={barChartValues}
            />
          </Box>
        </ChartCard>
      </Skeleton>
      <Skeleton isLoaded={isDataLoaded} h="328px" w="100%">
        <ChartCard flexGrow="1">
          <HStack spacing="0" alignItems="start">
            <Box padding="0px 12px 8px 24px" w="calc(223 / 381 * 100%)">
              <Box mb="16px">
                <Text
                  variant="body-l/semibold"
                  color="blue.9"
                  style={{ textWrap: 'nowrap' }}
                >
                  Collateral Breakdown
                </Text>
                <Text variant="body-md/normal" color="gray.6" mt="10px">
                  This is the distribution of repo tokens by collateral that are
                  backing this vault.
                </Text>
              </Box>
              <Box px="14px" pt="0px" pb="14px" h="134px">
                <CollateralPieChart
                  isActiveTab={isActiveTab}
                  holdings={pieChartData}
                />
              </Box>
            </Box>
            <Box w="full" mt={6}>
              <VStack
                spacing="24px"
                w="full"
                maxHeight="276px"
                overflowY="auto"
              >
                {pieChartData.map((holding, index) => (
                  <CollateralHoldingBar
                    key={index}
                    token={holding.collateralToken}
                    holdingPercent={holding.ratio}
                    color={getColor(index)}
                  />
                ))}
              </VStack>
            </Box>
          </HStack>
        </ChartCard>
      </Skeleton>
    </HStack>
  )
}
