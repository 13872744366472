export const toReceiptToken = (tokenSymbol: string) => {
  switch (tokenSymbol.toUpperCase()) {
    case 'USDC':
      return 'USDCR'
    case 'WETH':
      return 'WETHR'
    case 'USDS':
      return 'USDSR'
    default:
      return tokenSymbol
  }
}
