import { Box, Button, Flex } from '@chakra-ui/react'
import { TokenClaim } from '../../models/rewards'
import ClaimSection from '../Rewards/elements/ClaimSection'
import { Address } from '../../data/model'
import ClaimSectionLoading from '../Rewards/elements/ClaimSectionLoading'
import { useTermTokenPrice } from '../../data/hooks/use-term-token-price'

export default function ClaimsNoProfilePage({
  account,
  tokenClaim,
  onConnect,
}: {
  account?: Address
  tokenClaim:
  | {
    [season: number]: TokenClaim
  }
  | undefined
  onConnect: () => void
}) {
  const connectedSocials = {
    twitter: false,
    discord: false,
  }
  const { currentPrice: termTokenPrice } = useTermTokenPrice() || {}

  return (
    <>
      <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto">
        <Flex w="100%" flexDirection="column" pt={4}>
          {!account ? (
            <Button
              variant="primary"
              onClick={onConnect}
              w="fit-content"
              alignSelf="center"
            >
              Connect Wallet
            </Button>
          ) : (tokenClaim && termTokenPrice) ? (
            <ClaimSection
              isBasicPage={true}
              isDataLoading={!!account && !tokenClaim}
              isSignedIn={true}
              account={account}
              selectedAccount={account}
              claimEarnings={tokenClaim}
              termTokenPrice={termTokenPrice}
              connectedSocials={connectedSocials}
              onLinkWallet={async () => {}}
              onLinkTwitter={() => {}}
              onLinkDiscord={() => {}}
              onConnect={onConnect}
            />
          ) : (
            <ClaimSectionLoading />
          )}
        </Flex>
      </Box>
    </>
  )
}
