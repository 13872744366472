import { FixedNumber } from 'ethers'
import { TabOption } from '../../types'
import { Grid, Text } from '@chakra-ui/react'
import { formatFixedToken } from '../../../../helpers/utils'
import { fixedCompare } from '../../../../helpers/math'

export default function Balances({
  tab,
  assetSymbol,
  vaultAssetBalance,
  vaultReceiptBalanceInAssetTerms,
  availableWithdrawLimit,
  isBalanceError,
  isAvailabilityError,
  onClickBalance,
  onClickLimit,
}: {
  tab: TabOption
  assetSymbol: string
  vaultAssetBalance: FixedNumber
  vaultReceiptBalanceInAssetTerms: FixedNumber
  availableWithdrawLimit: FixedNumber
  isBalanceError: boolean
  isAvailabilityError: boolean
  onClickBalance?: () => void
  onClickLimit?: () => void
}) {
  const balance =
    tab === TabOption.Deposit
      ? vaultAssetBalance
      : vaultReceiptBalanceInAssetTerms

  const limit = fixedCompare(
    availableWithdrawLimit,
    'gt',
    vaultAssetBalance
  ) ? vaultAssetBalance : availableWithdrawLimit
  return (
    <Grid
      templateColumns="auto 1fr"
      columnGap={2}
      rowGap={0}
      textAlign="right"
      alignItems="center"
      color="gray.5"
    >
      <>
        <Text
          as="span"
          variant="body-xs/normal"
          color={isBalanceError ? 'red.5' : undefined}
        >
          Your Balance:
        </Text>
        <Text
          as="span"
          variant="body-xs/semibold"
          color={isBalanceError ? 'red.5' : undefined}
          _hover={{
            textDecoration: 'underline',
          }}
          cursor="pointer"
          onClick={onClickBalance}
        >
          {formatFixedToken(balance, assetSymbol, true)}
        </Text>
      </>
      {tab === TabOption.Withdraw && (
        <>
          <Text
            as="span"
            variant="body-xs/normal"
            color={isAvailabilityError ? 'red.5' : undefined}
          >
            Available:
          </Text>
          <Text
            as="span"
            variant="body-xs/semibold"
            color={isAvailabilityError ? 'red.5' : undefined}
            _hover={{
              textDecoration: 'underline',
            }}
            cursor="pointer"
            onClick={onClickLimit}
          >
            {formatFixedToken(limit, assetSymbol, true)}
          </Text>
        </>
      )}
    </Grid>
  )
}
