import { Text, VStack } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { formatFixedUsd } from '../../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import dayjs from 'dayjs'

export default function EarningsStatistics({
  title,
  isDataLoading,
  amountEarnings,
  expirationDate = 0,
}: {
  title: string
  isDataLoading?: boolean
  amountEarnings: FixedNumber
  expirationDate?: number | null | undefined
}) {
  const hasExpired = expirationDate && dayjs(expirationDate).isBefore(dayjs())
  return (
    <VStack spacing={1} alignItems="start">
      <Trans>
        <Text variant="body-md/medium" color="blue.9">
          {title}
        </Text>

        {isDataLoading ? (
          <Skeleton h="32px" w="120px" />
        ) : (
          <Text
            color={hasExpired ? 'gray.6' : 'blue.5'}
            variant="body-2xl/medium"
          >
            {formatFixedUsd(amountEarnings)}
          </Text>
        )}

        {isDataLoading ? (
          <Skeleton h="16px" w="96px" />
        ) : !hasExpired ? (
          <Text variant="body-xs/medium" color="gray.6" opacity="0.55">
            Expires {dayjs(expirationDate).fromNow()}
          </Text>
        ) : (
          <Text variant="body-xs/medium" color="gray.6" opacity="0.55">
            &mdash;
          </Text>
        )}
      </Trans>
    </VStack>
  )
}
