import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useMemo, useState } from 'react'
import TokenIcon from '../../../../../components/elements/TokenIcon'
import ClaimableEarningsStatistic from '../ClaimableEarningsStatistic'
import { VStack } from '../../../../../components/elements/Stack'
import { formatFixedToken, formatFixedUsd } from '../../../../../helpers/utils'
import ConfirmationClaimModal from '../ConfirmationClaimModal'
import ClickCopy from '../../../../../components/elements/ClickCopy'
import { useConfig } from '../../../../../providers/config'
import { FixedNumber } from 'ethers'
import { TokenClaim } from '../../../../../models/rewards'
import { useTermToast } from '../../../../../hooks/toasts'
import { termToastMessages } from '../../../../../helpers/toasts'
import { Mainnet, Sepolia } from '@usedapp/core'
import dayjs from 'dayjs'
import { Address } from '../../../../../data/model'

export default function ClaimableData({
  activeWallet,
  primaryWallet,
  claimEarnings,
  termTokenPrice,
  isLoading = false,
  onConnect,
}: {
  activeWallet?: Address
  primaryWallet?: Address
  claimEarnings: TokenClaim
  termTokenPrice: FixedNumber
  isLoading?: boolean
  onConnect?: () => void
}) {
  const {
    isOpen: isConfirmationClaimModalOpen,
    onOpen: onConfirmationClaimModalOpen,
    onClose: onCloseConfirmationClaimModal,
  } = useDisclosure()
  const [isDetailVisible, setDetailVisible] = useState(true)
  const toggleTableBody = () => {
    setDetailVisible(!isDetailVisible)
  }

  const termToast = useTermToast()

  const config = useConfig()

  const termTokenAddressLink = useMemo(() => {
    if (config.isMainnet) {
      return config.chains[Mainnet.chainId]?.getExplorerAddressLink(
        config.termTokenAddress
      )
    } else {
      return config.chains[Sepolia.chainId]?.getExplorerAddressLink(
        config.termTokenAddress
      )
    }
  }, [config])
  const airdropCALink = useMemo(() => {
    if (config.isMainnet) {
      return claimEarnings?.airdropCA
        ? config.chains[Mainnet.chainId]?.getExplorerAddressLink(
            claimEarnings.airdropCA
          )
        : ''
    } else {
      return claimEarnings?.airdropCA
        ? config.chains[Sepolia.chainId]?.getExplorerAddressLink(
            claimEarnings.airdropCA
          )
        : ''
    }
  }, [claimEarnings, config])

  const onClaim = async () => {
    termToast.pending(termToastMessages.rewardClaiming.pending())
    try {
      if (
        claimEarnings &&
        !claimEarnings.hasClaimedTokens &&
        !claimEarnings.claimableAmount.isZero()
      ) {
        await claimEarnings.claimTokens()
        termToast.success(
          termToastMessages.rewardClaiming.success(
            claimEarnings.seasonName,
            formatFixedToken(
              claimEarnings.claimableAmount,
              'TERM',
              true,
              true
            ) as string
          )
        )
        onConfirmationClaimModalOpen()
      }
    } catch (error) {
      console.error(error)
      termToast.failure(termToastMessages.rewardClaiming.failure())
    }
  }

  const isConnectedWalletNotMatchingPrimaryWallet =
    !!activeWallet &&
    !!primaryWallet &&
    activeWallet.toLowerCase() !== primaryWallet.toLowerCase()

  return (
    <Box
      w="full"
      bg="white"
      borderWidth="1px"
      borderColor="gray.3"
      borderRadius="6px"
      p={6}
    >
      <HStack onClick={toggleTableBody} cursor="pointer">
        <Box
          color="blue.9"
          as="span"
          transform={isDetailVisible ? 'rotate(0deg)' : 'rotate(180deg)'}
          transition="transform 0.2s"
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-up']}
            role="button"
            width="20px"
          />
        </Box>

        <Text color="gray.6" fontWeight="500" fontSize="16px">
          <Trans>{isDetailVisible ? 'Collapse' : 'Open'} </Trans>
        </Text>
      </HStack>

      <Box mt={4}>
        <HStack justifyContent="space-between" alignItems="start">
          {isLoading ? (
            <Box>
              <HStack>
                <Skeleton h="42px" w="42px" />

                <Box>
                  <Skeleton h="28px" w="200px" />

                  <Skeleton h="24px" w="70px" mt={2} />
                </Box>
              </HStack>
              <Skeleton h="24px" w="500px" mt={4} />
            </Box>
          ) : (
            <Box>
              <HStack>
                <TokenIcon boxSize="42px" key={'TERM'} token={'TERM'} />

                <Box>
                  <Text variant="body-xl/semibold" color="blue.9">
                    Term Token
                  </Text>

                  <HStack>
                    <Text variant="body-md/normal" color="gray.5">
                      TERM
                    </Text>
                    <Link href={termTokenAddressLink} isExternal color="gray.3">
                      <FontAwesomeIcon
                        icon={['far', 'arrow-up-right']}
                        width="16px"
                        height="16px"
                      />
                    </Link>{' '}
                    <ClickCopy
                      copyText={config.termTokenAddress}
                      helperText={'Copy address'}
                      tooltipProps={{
                        placement: 'bottom-end',
                        offset: [8, 8],
                      }}
                    />
                  </HStack>
                </Box>
              </HStack>

              <Text variant="body-md/normal" color="blue.9" mt={4}>
                <Trans>
                  Your tokens for {claimEarnings?.seasonName} are available.
                  Claim your tokens before{' '}
                  <Text as="span" variant="body-md/semibold">
                    {dayjs(claimEarnings?.expirationDate).format(
                      'MMM D, YYYY hh:mm A'
                    )}
                  </Text>{' '}
                  and stake them today.
                </Trans>{' '}
              </Text>
            </Box>
          )}
          {isDetailVisible ? (
            <></>
          ) : (
            <ClaimableEarningsStatistic
              claimEarnings={claimEarnings}
              termTokenPrice={termTokenPrice}
              isLoading={isLoading}
            />
          )}
        </HStack>
      </Box>

      <Box display={isDetailVisible ? 'block' : 'none'} mt={6}>
        <HStack justifyContent="space-between" alignItems="start">
          <VStack spacing={2} alignItems="start">
            {isConnectedWalletNotMatchingPrimaryWallet && (
              <Box
                w="full"
                mb={1}
                padding={3}
                borderRadius="6px"
                backgroundColor={'red.2'}
              >
                <Text color="blue.9" variant="body-sm/normal">
                  Please switch to your primary wallet{' '}
                  <Text as="span" variant="body-sm/semibold">
                    {primaryWallet}
                  </Text>{' '}
                  to claim your earnings.
                </Text>
              </Box>
            )}
            <HStack bg="blue.0" pl={6} py="10px" pr={12} borderRadius="6px">
              <ColDataItem
                label="Season"
                value={claimEarnings?.seasonName}
                isLoading={isLoading}
              />

              <ColDataItem
                label={
                  <Text as="span" color="blue.9">
                    Tokens Available{' '}
                    <Link href={airdropCALink} isExternal color="gray.3">
                      <FontAwesomeIcon
                        icon={['far', 'arrow-up-right']}
                        width="12px"
                        height="12px"
                      />
                    </Link>{' '}
                  </Text>
                }
                value={formatFixedToken(
                  claimEarnings?.claimableAmount,
                  'TERM',
                  true,
                  true
                )}
                isLoading={isLoading}
              />
              <ColDataItem
                label="Claimed"
                value={claimEarnings?.hasClaimedTokens ? 'Yes' : 'No'}
                isLoading={isLoading}
              />
              <ColDataItem
                label="Staked"
                value={claimEarnings?.hasStakedTokens ? 'Yes' : 'No'}
                isLoading={isLoading}
              />

              {!activeWallet && onConnect ? (
                <Button
                  variant="primary"
                  onClick={onConnect}
                  isDisabled={isLoading}
                >
                  Connect wallet
                </Button>
              ) : (
                  <Button
                    variant="secondary"
                    isDisabled={
                      isLoading ||
                      isConnectedWalletNotMatchingPrimaryWallet ||
                      claimEarnings?.claimableAmount?.isZero()
                    }
                    onClick={() => {
                      !!claimEarnings?.hasClaimedTokens
                        ? claimEarnings?.stakeTokens()
                        : onClaim()
                    }}
                  >
                    <Trans>
                      {' '}
                      {claimEarnings?.hasClaimedTokens
                        ? 'Stake Tokens'
                        : 'Claim and Stake Tokens'}
                    </Trans>
                  </Button>
              )}
            </HStack>
          </VStack>

          <Box>
            <ClaimableEarningsStatistic
              claimEarnings={claimEarnings}
              termTokenPrice={termTokenPrice}
              isLoading={isLoading}
            />
          </Box>
        </HStack>
      </Box>

      <ConfirmationClaimModal
        isOpen={isConfirmationClaimModalOpen}
        onClose={() => {
          onCloseConfirmationClaimModal()
        }}
        onStakeTokens={claimEarnings?.stakeTokens}
        claimedAmount={formatFixedUsd(claimEarnings?.claimableAmountUSD, true)}
        onAddRepoTokenToWallet={claimEarnings?.importTokens}
      />
    </Box>
  )
}

const ColDataItem = ({
  label,
  value,
  isLoading,
}: {
  label: string | React.ReactNode
  value: string | React.ReactNode
  isLoading: boolean
}) => {
  return (
    <>
      <VStack spacing={2} pr={6} alignItems="start">
        <Text variant="body-xs/semibold" color="blue.9">
          <Trans>{label}</Trans>
        </Text>

        {isLoading ? (
          <Skeleton h="20px" w="80px" />
        ) : (
          <Text variant="body-sm/normal" color="blue.9">
            {value}
          </Text>
        )}
      </VStack>
      <Divider pr={6} orientation="vertical" height="89px" />
    </>
  )
}
