import { TooltipProps, useTheme } from '@chakra-ui/react'
import { WalletAnalyzerUserTypeEnum } from '../../models/rewards'
import { components } from '../../models/profile-api'

export type PointsCategory =
  | 'auctions'
  | 'lending'
  | 'invites'
  | 'other'
  | '3xMultiplier'
  | 'unlock'
  | 'none'

export type CardStatus = 'complete' | 'incomplete' | 'ongoing' | 'started'

export type PointsCardProps = {
  children?: React.ReactNode
  complete?: boolean
  category: PointsCategory
  points?: string
  status?: CardStatus
  indicatorTotalSteps?: number
  indicatorCurrentStep?: number
  indicatorText?: string
  title: string
  requiresProfile?: boolean
  buttonText?: string
  buttonAction?: () => void
  thumbnail?: string
  startedTotal?: number
  startedValue?: number
  multiplier?: number
  isLoading?: boolean
  isDisabled?: boolean
  tooltipProps?: Omit<TooltipProps, 'children'>
  bgGradient?: string
  alignItems?: string
}

type PointsCategoryMap = {
  [category in PointsCategory]: string
}

export const pointsCategoryFactory = (category: PointsCategory): string => {
  return pointsCategoryMap[category]
}

export const pointsCategoryMap: PointsCategoryMap = {
  auctions: 'cyan.4',
  lending: 'green.3',
  invites: 'orange.3',
  other: '#9375FF',
  '3xMultiplier': '',
  unlock: '#959595',
  none: '',
}

export interface TagIconProps {
  variant: PointsCategory
  points: string
}

export interface PointsCardStatusProps {
  variant: CardStatus
  startedTotal?: number
  startedValue?: number
  multiplier?: number
}

export type EarningsData = {
  category: string
  value: number
  color: string
}

export type PointsBreakdownProps = {
  earningsData: EarningsData[]
  season: components['schemas']['Season']
  totalPoints: number
  lastUpdated: number
}

export type InviteURLButtonType = {
  icon: [string, string] | any
  ariaLabel: string
  bg?: string
  variant?: string
  hoverBg?: string
  onClick: () => void
}

export type InviteTableCols =
  | 'code'
  | 'meta.progress.currentStep'
  | 'meta.inviteeName'
  | 'meta.appPoints'
  | 'meta.appPoints'

export type WalletAnalyzerProps = {
  isAnimating?: boolean
  walletUserType: WalletAnalyzerUserTypeEnum
  title: string
  comments: string[]
  bonusPoints: { points: number | undefined; address: string }[]
  onCloseWalletAnalyzer: () => void
  onAddAnotherWallet: () => Promise<void>
}

export const compareValues = (a: number, b: number) => {
  const diff = a - b
  return diff !== 0 ? diff : 0
}

export const calculateTotalTermPoints = (earningsData: EarningsData[]) => {
  return earningsData.reduce((total, data) => {
    return total + data.value
  }, 0)
}

interface IUnitStrategy {
  check: (num: number) => boolean
  unit: (capitalise: boolean) => string
  divisor: number
}

const strategies: IUnitStrategy[] = [
  {
    check: (num: number) => num >= 1_000_000_000_000,
    unit: (capitalise: boolean) => (capitalise ? 'T' : 't'),
    divisor: 1_000_000_000_000,
  },
  {
    check: (num: number) => num >= 1_000_000_000,
    unit: (capitalise: boolean) => (capitalise ? 'B' : 'b'),
    divisor: 1_000_000_000,
  },
  {
    check: (num: number) => num >= 1_000_000,
    unit: (capitalise: boolean) => (capitalise ? 'M' : 'm'),
    divisor: 1_000_000,
  },
  {
    check: (num: number) => num >= 1_000,
    unit: (capitalise: boolean) => (capitalise ? 'K' : 'k'),
    divisor: 1_000,
  },
  {
    check: (num: number) => num < 1_000,
    // eslint-disable-next-line unused-imports/no-unused-vars
    unit: (capitalise: boolean) => '',
    divisor: 1,
  },
]

const formatToFourDigits = (num: number) => {
  let str = num.toString().replace('.', '')
  str = str.length > 4 ? str.substring(0, 4) : str
  const decimalIndex = num.toString().indexOf('.')
  const power = decimalIndex >= 0 ? str.length - decimalIndex : 0
  return (Number(str) / Math.pow(10, power)).toString()
}

export const formatNumber = (
  num: number,
  capitalise: boolean = false,
  decimals: number | undefined = undefined,
  fourDigits: boolean = false,
  limitPlaces: boolean = false
) => {
  if (num === 0) return '0'
  if (num < 1) return '~0'

  const strategy =
    strategies.find(({ check }) => check(num)) ||
    strategies[strategies.length - 1]

  const { unit, divisor } = strategy

  const formattedNum = num / divisor

  let formattedStr = fourDigits
    ? formatToFourDigits(formattedNum)
    : formattedNum.toFixed(decimals ?? 3)

  const formattedNumStr = formattedNum.toString()
  const [integerPart] = formattedNumStr.split('.')

  const isThreeOrMoreLeadingDigits = integerPart.length >= 3
  if (limitPlaces && isThreeOrMoreLeadingDigits) {
    formattedStr = formattedNum.toFixed(0)
  }

  return formattedStr + unit(capitalise)
}
const isHexColor = (color: string) => /^#([0-9A-F]{3}){1,2}$/i.test(color)

export const CategoryTagIcon = ({ variant }: { variant: PointsCategory }) => {
  const theme = useTheme()
  if (variant === 'none') {
    return
  }
  let color = pointsCategoryFactory(variant)

  if (!isHexColor(color)) {
    const colorParts = color.split('.')
    color = theme.colors[colorParts[0]][colorParts[1]]
  }

  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8" height="8" rx="2" fill={color} />
    </svg>
  )
}

export const timeSinceLastUpdated = (lastUpdated: number) => {
  const now = Date.now() / 1000
  const differenceInSeconds = Math.floor(now - lastUpdated)
  const differenceInMinutes = Math.floor(differenceInSeconds / 60)
  const differenceInHours = Math.floor(differenceInMinutes / 60)
  const differenceInDays = Math.floor(differenceInHours / 24)

  if (differenceInDays > 0) {
    return `${differenceInDays}d`
  } else if (differenceInHours > 0) {
    return `${differenceInHours}h`
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}m`
  } else {
    return `${differenceInSeconds}s`
  }
}
