import { HStack } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import NavBarOption from '../../../../components/elements/NavBarOption'
import { useConfig } from '../../../../providers/config'

export default function NavBar({
  hasProfile,
  onSignUp,
  isDataLoading,
  isInviteDisabled,
}: {
  hasProfile: boolean
  onSignUp: () => void
  isDataLoading?: boolean
  isInviteDisabled?: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const isTrack = location.pathname.includes('track')
  const isInvite = location.pathname.includes('invite')
  const isClaim = location.pathname.includes('claim')

  const config = useConfig()

  return (
    <HStack>
      <NavBarOption
        // isDisabled={isDataLoading}
        selected={!isTrack && !isInvite && !isClaim}
        onClick={() => navigate('')}
        label="Earn"
      />
      <NavBarOption
        // isDisabled={isDataLoading}
        selected={isTrack}
        onClick={() => navigate('track')}
        label="Track"
      />
      <NavBarOption
        selected={isInvite}
        onClick={() => (hasProfile ? navigate('invite') : onSignUp())}
        label="Invite"
        isDisabled={isInviteDisabled}
        // isDisabled={isInviteDisabled || isDataLoading}
      />
      <NavBarOption
        selected={isClaim}
        onClick={() => navigate('claim')}
        label="Claim"
        // isDisabled={isDataLoading}
      />
    </HStack>
  )
}
