// import { PureComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { formatNumber } from '../../../Rewards/utils'
import { RepoTokenBarChartMapping } from '../../../../models/vault'

interface CustomizedTickProps {
  x?: number
  y?: number
  payload?: { value: string }
}

const CustomizedXAxisTick: React.FC<CustomizedTickProps> = ({
  x = 0,
  y = 0,
  payload,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={10}
      textAnchor="middle"
      fill="#4A5667"
      fontSize="10px"
      fontWeight="normal"
    >
      {payload?.value}
    </text>
  </g>
)

const CustomizedYAxisTick: React.FC<CustomizedTickProps> = ({
  x = 0,
  y = 0,
  payload,
}) => {
  const amount = Number(payload?.value) // Ensure it's treated as a number

  const formattedTick =
    amount < 1000000
      ? formatNumber(amount, true, 0, false)
      : formatNumber(amount, true, 1, false)

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={0}
        textAnchor="end"
        fill="#4A5667"
        fontSize="10px"
        fontWeight="normal"
      >
        ${formattedTick}
      </text>
    </g>
  )
}

export default function HoldingsBarChart({
  isActiveTab,
  holdings,
  width,
  height,
}: {
  isActiveTab: boolean
  holdings: RepoTokenBarChartMapping[]
  width?: number
  height?: number
}) {
  const preparedHoldings = holdings.map((item) => ({
    bucket: item.bucket,
    amount: item.amount.toUnsafeFloat(),
  }))

  return (
    <div
      style={{
        width: width || '100%',
        height: height || '144px',
        minHeight: '144px',
      }}
    >
      {isActiveTab && (
        <ResponsiveContainer width="100%" height="100%" minHeight={'208px'}>
          <BarChart
            width={500}
            height={300}
            data={preparedHoldings}
            margin={{
              left: 0, // No unnecessary horizontal shifting
              right: 0,
              top: 20,
              bottom: 20,
            }}
          >
            <XAxis
              dataKey="bucket"
              tickLine={false}
              tick={<CustomizedXAxisTick />}
              padding={{ left: 0, right: 0 }}
              stroke="#E4E7EB"
            />
            <YAxis
              dataKey="amount"
              tickLine={false}
              tick={<CustomizedYAxisTick />}
              stroke="#E4E7EB"
            />
            <Bar dataKey="amount" barSize={53} fill="#B8D6FF" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
