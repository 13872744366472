// import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { patchProfile, patchProfileWallet } from '../../../helpers/swr'
import { paths } from '../../../models/profile-api'
import { useCallback, useEffect, useState } from 'react'

type PatchProfileResult = {
  success: boolean
  error?: { message: string }
}
type PatchProfileWalletResult = PatchProfileResult

export function useProfileUpdate(onSuccessCallback?: () => void): {
  isLoading: boolean
  update: (
    extraArguments: paths['/profile/profile']['patch']['requestBody']['content']['application/json'] & {
      token?: string
    }
  ) => Promise<PatchProfileResult>
  error?: any
} {
  const { error, isMutating, trigger } = useSWRMutation(
    `/profile`,
    patchProfile,
    {
      // throwOnError: false,
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback()
      },
    }
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const update = async (
    extraArguments: paths['/profile/profile']['patch']['requestBody']['content']['application/json'] & {
      token?: string
    }
  ): Promise<PatchProfileResult> => {
    try {
      const data = await trigger(extraArguments)
      return { success: !!data?.message }
    } catch (e) {
      // TODO [Dospore] this wont ever get caught since the useSWRMutation has throwOnError: false
      return { success: false, error: parseErrorMessage(e) }
    }
  }

  const parseErrorMessage = useCallback((error: any) => {
    try {
      console.log('inside parseErrorMessage: %o', error.message)
      return JSON.parse(error.message)
    } catch (e) {
      console.error('error parsing message: %o', e)
    }
  }, [])

  useEffect(() => {
    if (error) {
      setErrorMessage(parseErrorMessage(error))
    }
  }, [isMutating, error, parseErrorMessage])

  return {
    isLoading: isMutating,
    update,
    error: errorMessage,
  }
}

export function useUpdateProfileWallet(onSuccessCallback?: () => void): {
  isLoading: boolean
  update: (
    token: string,
    walletAddress: string
  ) => Promise<PatchProfileWalletResult>
  error?: any
} {
  const { error, isMutating, trigger } = useSWRMutation(
    `/profile/wallet`,
    patchProfileWallet,
    {
      throwOnError: false,
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback()
      },
    }
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const parseErrorMessage = useCallback((error: any) => {
    try {
      console.log('inside parseErrorMessage: %o', error.message)
      return JSON.parse(error.message)
    } catch (e) {
      console.error('error parsing message: %o', e)
    }
  }, [])

  const update = async (
    token: string,
    walletAddress: string
  ): Promise<PatchProfileWalletResult> => {
    try {
      const data = await trigger({ token, walletAddress })
      return { success: !!data?.message }
    } catch (e) {
      // This catch may not be triggered if throwOnError is false in useSWRMutation
      return { success: false, error: parseErrorMessage(e) }
    }
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(parseErrorMessage(error))
    }
  }, [isMutating, error, parseErrorMessage])

  return {
    isLoading: isMutating,
    update,
    error: errorMessage,
  }
}
