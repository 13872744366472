import { Button } from '@chakra-ui/react'

const colors = {
  selected: {
    background: 'blue.5',
    color: 'white',
  },
  unselected: {
    background: 'white',
    color: 'blue.9',
  },
}

interface ChipProps {
  label: string
  selected?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

export default function NavBarOption({
  isDisabled,
  label,
  selected = false,
  onClick,
}: ChipProps) {
  const { background, color } = colors[selected ? 'selected' : 'unselected']

  return (
    <Button
      px={5}
      isDisabled={isDisabled}
      background={background}
      border={!selected ? '1px solid' : undefined}
      borderColor={!selected ? 'gray.7' : undefined}
      color={color}
      textTransform="uppercase"
      borderRadius="30px"
      textDecoration="none"
      fontSize="10px"
      letterSpacing="2px"
      fontWeight={500}
      height="28px"
      _hover={{ textDecoration: 'none' }}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}
