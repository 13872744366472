import {
  Chain,
  ChainId,
  CoinbaseWalletConnector,
  Base,
  Sepolia,
  Hardhat,
  Localhost,
  Mainnet,
  MetamaskConnector,
  Mumbai,
  Config as UseDappConfig,
  Avalanche,
} from '@usedapp/core'
import { ChainConfigItem, getChains } from './chain-config'
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector'
import { isAddress } from 'ethers/lib/utils'
// import { SubgraphVersion } from './managers/subgraphManager'
import { SubgraphVersion } from './helpers/constants'
import { NativeCurrency } from './data/model'
import { GnosisSafeConnector } from './connectors/safe'
import { Opts } from '@safe-global/safe-apps-sdk'
import { SafaryEvents } from './data/analytics/model'

export type Config = {
  chains: {
    [chainId: string]: ChainSpecificConfig
  }
  termTokenAddress: string
  protocolServerUrl: string
  guardianServerUrl: string
  profileServerUrl: string
  sentry: SentryConfig
  provider: Partial<UseDappConfig>
  approveAmount: string
  version: string
  googleAnalytics?: GoogleAnalyticsConfig
  environment: string
  allowedTestnetTokenClaims: number
  isMainnet: boolean
  isTestnet: boolean
  isInternalEnvironment: boolean
  resultsBucket?: string
  meticulousProjectId?: string
  sentioApiUrl?: string

  safary?: SafaryEvents

  oauth: {
    twitter: OAuth2Config
    discord: OAuth2Config
  }
}

export type ChainSpecificConfig = {
  chainId: ChainId
  chainName: string
  contracts: ContractsConfig
  multicallAddress: string
  multicall2Address?: string
  subgraphUrl: string
  rpcUrl: string
  listingsSubgraphUrl: string
  vaultsSubgraphUrl: string
  listingsContractAddress: string
  subgraphPageLimit: number
  revealServerUrl: string
  blockExplorerUrl: string
  testnetTokens: string[]
  auctionsToFilterOut: string[]
  termsToFilterOut: string[]
  vaultsToFilterOut: string[]
  nativeCurrency: NativeCurrency
  gradient: string
  verticalGradient: string
  opacity: number
  wrappedTokenMapping?: { [wrapped: string]: string }
  getExplorerAddressLink: (address: string) => string
  getExplorerTransactionLink: (address: string) => string
  getSubgraphVersion: () => SubgraphVersion
}

const safeOpts: Opts = {
  allowedDomains: [/app.safe.global$/],
  debug: false,
}

type ContractsConfig = {
  termPriceOracle: string
  multisend?: string
  wrappedGasToken: string
  airdrop?: string
}

type SentryConfig = {
  dsn?: string
  tracesSampleRate: number
  replaySampleRate: number
  replayErrorSampleRate: number
  profileSampleRate: number
}
type GoogleAnalyticsConfig = {
  trackingId?: string
}

type OAuth2Config = {
  clientId: string
  redirectUri: string
  authUrl: string
  tokenUrl: string
  scopes: string
}

type ChainConfigMapping = Record<
  number,
  {
    network: Chain
    multicallVersion: 2 | 1 | undefined
  }
>

enum Environment {
  Development = 'development',
  Staging = 'staging',
  Testnet = 'testnet',
  Mainnet = 'mainnet',
}

function getEnvironment(): Environment {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case Environment.Staging:
    case Environment.Testnet:
    case Environment.Mainnet:
    case Environment.Development:
      return process.env.REACT_APP_ENVIRONMENT
    default:
      console.log(
        'Failed to get environment, setting environment to ' +
          Environment.Development.toString()
      )
      return Environment.Development
  }
}

function getSentioApiUrl(): string | undefined {
  switch (getEnvironment()) {
    case Environment.Mainnet:
    case Environment.Testnet:
    case Environment.Development:
    case Environment.Staging:
      return 'https://public-sentio-proxy.global.termfinance.io/query'
    default:
      return undefined
  }
}

const chainConfigurationMapping: ChainConfigMapping = {
  [ChainId.Mainnet]: { network: Mainnet, multicallVersion: 2 },
  [ChainId.Avalanche]: { network: Avalanche, multicallVersion: 2 },
  [ChainId.Base]: { network: Base, multicallVersion: 2 },
  [ChainId.Sepolia]: { network: Sepolia, multicallVersion: 2 },
  [ChainId.Mumbai]: { network: Mumbai, multicallVersion: 2 },
  [ChainId.Localhost]: { network: Localhost, multicallVersion: 2 },
  [ChainId.Hardhat]: { network: Hardhat, multicallVersion: 2 },
}

function getProvider(activeChains: ChainConfigItem[]): Partial<UseDappConfig> {
  const readOnlyUrls: { [key: number]: string } = {}
  const networks: Chain[] = []
  const multicallAddresses: { [key: number]: string } = {}

  activeChains.forEach((item) => {
    const chainInfo = chainConfigurationMapping[item.chainId]
    if (!chainInfo) {
      throw new Error(`Unknown chainId mapping - ${item.chainId}`)
    }
    readOnlyUrls[item.chainId] = item.rpcUrl
    networks.push(chainInfo.network)
    multicallAddresses[item.chainId] =
      item.multicallAddress ?? chainInfo.network.multicallAddress
  })

  const providerConfig: Partial<UseDappConfig> = {
    readOnlyChainId: activeChains[0].chainId,
    readOnlyUrls,
    networks,
    connectors: {
      metamask: new MetamaskConnector(),
      coinbase: new CoinbaseWalletConnector(),
      safe: new GnosisSafeConnector(safeOpts),
    },
    multicallAddresses,
    multicallVersion: 2,
    noMetamaskDeactivate: true,
  }

  // Configure WalletConnect connector if Infura ID is passed to app
  if (
    process.env.REACT_APP_WALLET_CONNECT_INFURA_ID &&
    providerConfig.connectors
  ) {
    const projectId = process.env.REACT_APP_WALLET_CONNECT_INFURA_ID
    const networks = activeChains.map(
      (item) => chainConfigurationMapping[item.chainId].network
    )

    providerConfig.connectors.walletConnectV2 = new WalletConnectV2Connector({
      projectId,
      chains: networks,
      rpcMap: readOnlyUrls,
    })
  }

  return providerConfig
}

function isValidSubgraphVersion(subgraphVersion: string): boolean {
  const validSubgraphVersions = process.env.REACT_APP_VALID_SUBGRAPH_VERSIONS
    ? process.env.REACT_APP_VALID_SUBGRAPH_VERSIONS.split(',')
    : []
  return validSubgraphVersions.includes(subgraphVersion)
}

function validateChainConfigRecord(
  generalChainInfo: Chain,
  chainConfigItem: ChainConfigItem
) {
  if (!generalChainInfo.chainName || generalChainInfo.chainName === '') {
    throw new Error(
      `chainName is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    (!chainConfigItem.blockExplorerUrl && !generalChainInfo.blockExplorerUrl) ||
    chainConfigItem.blockExplorerUrl === ''
  ) {
    throw new Error(
      `blockExplorerUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!isAddress(chainConfigItem.wrappedGasAddress)) {
    throw new Error(
      `wrappedGasAddress is not defined/invalid for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem?.multisendAddress &&
    !isAddress(chainConfigItem.multisendAddress)
  ) {
    throw new Error(
      `multisendAddress is invalid for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.subgraphUrl) {
    throw new Error(
      `subgraphUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.listingsSubgraphUrl) {
    throw new Error(
      `listingsSubgraphUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.vaultsSubgraphUrl) {
    throw new Error(
      `vaultsSubgraphUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!isAddress(chainConfigItem.listingsContractAddress)) {
    throw new Error(
      `listingsSubgraphUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem.subgraphVersion &&
    !isValidSubgraphVersion(chainConfigItem.subgraphVersion)
  ) {
    throw new Error(
      `Invalid subgraph version provided: ${chainConfigItem.subgraphVersion}`
    )
  }

  if (!chainConfigItem.rpcUrl) {
    throw new Error(
      `rpcUrl is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!isAddress(chainConfigItem.priceOracleAddress)) {
    throw new Error(
      `priceOracleAddress is not defined/invalid for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem.testnetTokens &&
    !Array.isArray(chainConfigItem.testnetTokens)
  ) {
    throw new Error(
      `testnetTokens is not an array for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem.auctionsToFilterOut &&
    !Array.isArray(chainConfigItem.auctionsToFilterOut)
  ) {
    throw new Error(
      `auctionsToFilterOut is not an array for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem.termsToFilterOut &&
    !Array.isArray(chainConfigItem.termsToFilterOut)
  ) {
    throw new Error(
      `termsToFilterOut is not an array for chainId ${chainConfigItem.chainId}`
    )
  }

  if (
    chainConfigItem.vaultsToFilterOut &&
    !Array.isArray(chainConfigItem.vaultsToFilterOut)
  ) {
    throw new Error(
      `vaultsToFilterOut is not an array for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!generalChainInfo.nativeCurrency) {
    throw new Error(
      `nativeCurrency object is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.gradient) {
    throw new Error(
      `gradient is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.verticalGradient) {
    throw new Error(
      `vertical gradient is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  if (!chainConfigItem.opacity) {
    throw new Error(
      `opacity is not defined for chainId ${chainConfigItem.chainId}`
    )
  }

  // TODO: refactor to make this not optional
  if (
    chainConfigItem.contractAddresses &&
    chainConfigItem.contractAddresses.airdrop[1] &&
    !isAddress(chainConfigItem.contractAddresses.airdrop[1])
  ) {
    throw new Error(
      `contractAddresses - airdrop is not a valid address for chainId ${chainConfigItem.chainId}`
    )
  }
}

const currentEnvironment = getEnvironment()
const activeChains = getChains(currentEnvironment)
// Ensure there's at least one chain in activeChains
if (activeChains.length === 0) {
  throw new Error('No active chains found')
}

// if (!process.env.REACT_APP_GUARDIAN_SERVER_URL) {
//   throw new Error(`guardian server url is not set`)
// }

const chains: { [chainId: number]: ChainSpecificConfig } = activeChains.reduce(
  (acc: { [chainId: number]: ChainSpecificConfig }, item: ChainConfigItem) => {
    const chainMapping = chainConfigurationMapping[item.chainId]
    if (!chainMapping) {
      throw new Error(`Unknown chainId mapping - ${item.chainId}`)
    }

    const useDappChainInfo = chainMapping.network

    validateChainConfigRecord(useDappChainInfo, item)

    const blockchainConfig: ChainSpecificConfig = {
      ...useDappChainInfo,
      chainId: item.chainId,
      chainName: item.chainName ?? useDappChainInfo.chainName,
      contracts: {
        termPriceOracle: item.priceOracleAddress,
        multisend: item.multisendAddress,
        wrappedGasToken: item.wrappedGasAddress,
        airdrop: item.contractAddresses?.airdrop?.[1],
      },
      multicallAddress:
        item.multicallAddress ?? useDappChainInfo.multicallAddress,
      multicall2Address:
        item.multicall2Address ?? useDappChainInfo.multicall2Address,
      subgraphUrl: item.subgraphUrl,
      listingsSubgraphUrl: item.listingsSubgraphUrl,
      vaultsSubgraphUrl: item.vaultsSubgraphUrl,
      listingsContractAddress: item.listingsContractAddress,
      subgraphPageLimit: 1000,
      revealServerUrl: item.revealServerUrl,
      blockExplorerUrl:
        item.blockExplorerUrl ?? useDappChainInfo.blockExplorerUrl ?? '',
      testnetTokens: item.testnetTokens ?? [],
      auctionsToFilterOut: item.auctionsToFilterOut ?? [],
      termsToFilterOut: item.termsToFilterOut ?? [],
      vaultsToFilterOut: item.vaultsToFilterOut ?? [],
      wrappedTokenMapping: item.wrappedTokenMapping,
      gradient: item.gradient,
      rpcUrl: item.rpcUrl,
      verticalGradient: item.verticalGradient,
      opacity: item.opacity,
      // existence check for nativeCurrency performed in validaeChainConfigRecord above
      nativeCurrency: {
        symbol: useDappChainInfo.nativeCurrency!.symbol,
        decimals: useDappChainInfo.nativeCurrency!.decimals,
        wrappedGasSymbol: item.wrappedGasSymbol,
        wrapConversionFactor: item.wrapConversionFactor,
      },
      getSubgraphVersion: () => {
        const subgraphVersion: string = item.subgraphVersion ?? 'latest'
        switch (subgraphVersion) {
          case 'latest':
          default:
            return SubgraphVersion.Latest
        }
      },
    }

    acc[blockchainConfig.chainId] = blockchainConfig
    return acc
  },
  {}
)

const sortedChains = Object.fromEntries(
  Object.entries(chains).sort(([, a], [, b]) => a.chainId - b.chainId)
  // .map(([key, value]) => [Number(key), value])
)

const config: Config = {
  chains: sortedChains,
  termTokenAddress:
    Environment.Mainnet === currentEnvironment
      ? '0xc3d21f79c3120a4ffda7a535f8005a7c297799bf'
      : '0x571ef527bee601439fc319c42960c06a6867f4c0',
  protocolServerUrl:
    process.env.REACT_APP_PROTOCOL_SERVER_URL ??
    'https://api.global.termfinance.io',
  profileServerUrl:
    process.env.REACT_APP_PROFILE_SERVER_URL ??
    'https://api.testnet.termfinance.io/profile',
  guardianServerUrl:
    process.env.REACT_APP_GUARDIAN_SERVER_URL ??
    'https://guardian.global.termfinance.io',
  provider: getProvider(activeChains),
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 0.1,
    replaySampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE
      ? parseFloat(process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE)
      : 0.1,
    replayErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE
      ? parseFloat(process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE)
      : 1.0,
    profileSampleRate: process.env.REACT_APP_SENTRY_PROFILE_SAMPLE_RATE
      ? parseFloat(process.env.REACT_APP_SENTRY_PROFILE_SAMPLE_RATE)
      : 0.1,
  },
  allowedTestnetTokenClaims: 3,
  approveAmount: '10000000000000000000000000000',
  version: process.env.REACT_APP_VERSION ?? 'dev',
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS
    ? {
        trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS,
      }
    : undefined,
  environment: currentEnvironment,
  isMainnet: Environment.Mainnet === currentEnvironment,
  isTestnet: Environment.Testnet === currentEnvironment,
  isInternalEnvironment: ![Environment.Mainnet, Environment.Testnet].includes(
    currentEnvironment
  ),
  resultsBucket: process.env.REACT_APP_RESULTS_BUCKET,
  meticulousProjectId: process.env.REACT_APP_METICULOUS_PROJECT_ID,
  sentioApiUrl: getSentioApiUrl(),

  oauth: {
    twitter: {
      clientId: process.env.REACT_APP_TWITTER_OAUTH_CLIENT_ID ?? '',
      redirectUri: process.env.REACT_APP_TWITTER_OAUTH_REDIRECT_URI ?? '',
      authUrl: process.env.REACT_APP_TWITTER_OAUTH_AUTH_URL ?? '',
      tokenUrl: process.env.REACT_APP_TWITTER_OAUTH_TOKEN_URL ?? '',
      scopes: process.env.REACT_APP_TWITTER_OAUTH_SCOPES ?? '',
    },
    discord: {
      clientId: process.env.REACT_APP_DISCORD_OAUTH_CLIENT_ID ?? '',
      redirectUri: process.env.REACT_APP_DISCORD_OAUTH_REDIRECT_URI ?? '',
      authUrl: process.env.REACT_APP_DISCORD_OAUTH_AUTH_URL ?? '',
      tokenUrl: process.env.REACT_APP_DISCORD_OAUTH_TOKEN_URL ?? '',
      scopes: process.env.REACT_APP_DISCORD_OAUTH_SCOPES ?? '',
    },
  },
}

export default config
