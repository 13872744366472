import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import sparkle from '../../../../assets/icons/sparkle.svg'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack, VStack } from '../../../../components/elements/Stack'
import TokenIcon from '../../../../components/elements/TokenIcon'
import TokenIconMultiple from '../../../../components/elements/TokenIconMultiple'
import Tooltip from '../../../../components/elements/Tooltip'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { useConfig } from '../../../../providers/config'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'
import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'
import {
  formatFixedToken,
  getSortedCollateralSymbols,
} from '../../../../helpers/utils'
import { VaultData } from '../../../../models/vaults'

export default function VaultCard({
  vault,
  onViewVault,
  isMetaVaultView,
}: {
  vault: VaultData
  onViewVault: (vaultAddress: string, chainId: string) => void
  isMetaVaultView?: boolean
}) {
  const config = useConfig()
  const chainConfig = config.chains[vault.chainId]

  let formattedYieldPercentage: string | undefined
  if (vault?.currentYield && !vault.currentYield.isZero()) {
    formattedYieldPercentage = fixedToFormattedPercentage(
      vault.currentYield,
      2,
      true
    ).formattedPercentage
  }

  const { formattedPercentage: targetValuePercent } =
    fixedToFormattedPercentage(vault.targetAllocation)
  const { formattedPercentage: actualValuePercent } =
    fixedToFormattedPercentage(vault.actualAllocation)

  const symbol = vault.purchaseCurrency.symbol

  return (
    <>
      <Flex
        key={vault.address}
        flexDir="column"
        minW={{
          base: '922px',
        }}
        position="relative"
        borderRadius="6px"
        overflow="visible"
        boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
      >
        <Flex
          justifyContent="space-between"
          flexDir={{
            base: 'row',
          }}
          p={3}
          borderRadius="6px"
          background={chainConfig.gradient}
          transition="all .25s ease-in-out"
        >
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            flexDirection={{
              base: 'row',
            }}
            py={2.5}
            mr="auto"
          >
            {/* Current Yield */}
            <Box
              h="100%"
              w="130px"
              borderRight={{
                base: '1px solid',
              }}
              borderRightColor={{
                base: 'gray.3',
              }}
              pr={2}
            >
              <RepoCardHeading title="Current Yield" mb={2} />
              {!formattedYieldPercentage ? (
                <HStack>
                  <Text variant="body-2xl/bold" color="black">
                    N/A
                  </Text>
                  <Tooltip
                    noDelay
                    placement="bottom"
                    label={'More data needed to display yield'}
                  >
                    <Image boxSize={6} src={sparkle} />
                  </Tooltip>
                </HStack>
              ) : (
                <HStack w="100%">
                  <Text variant="body-2xl/bold" color="black">
                    {formattedYieldPercentage}%
                  </Text>
                  <Tooltip
                    noDelay
                    placement="bottom"
                    label={
                      <VStack
                        alignItems="flex-start"
                        w="full"
                        color="white"
                        gap={2}
                      >
                        <Text
                          as="span"
                          variant="body-sm/normal"
                          color="white"
                          textAlign="left"
                        >
                          {`Additional 1.5x Term Points / dollar deposited.`}
                        </Text>
                        <Text
                          as="span"
                          variant="body-sm/normal"
                          color="white"
                          textAlign="left"
                        >
                          {`Estimated yield based on Aave yield for ${symbol} plus yield on Term repo tokens held by vault.`}
                        </Text>
                      </VStack>
                    }
                  >
                    <Image boxSize="24px" src={sparkle} alt={'Fixed APY'} />
                  </Tooltip>
                </HStack>
              )}
            </Box>
            <Grid
              gridTemplateColumns={{
                base: isMetaVaultView
                  ? '150px 75px 145px 135px'
                  : '200px 75px 135px',
                lg: isMetaVaultView
                  ? '180px 75px 145px 135px'
                  : '200px 75px 135px',
                '1xl': isMetaVaultView
                  ? '230px 75px 145px 135px'
                  : '200px 75px 135px',
              }}
              gap={{
                base: isMetaVaultView ? '12px' : '38px',
                '1xl': isMetaVaultView ? '66px' : '89px',
                '3xl': isMetaVaultView ? '66px' : 36,
              }}
            >
              {/* Vault */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Vault" mb={2} />
                <HStack
                  w={{
                    base: '100%',
                    '1xl': 'auto',
                  }}
                >
                  <TokenIcon boxSize="24px" token={symbol} />
                  <Tooltip
                    noDelay
                    leftAlign
                    label={vault.vaultName}
                    placement="top"
                  >
                    <Text
                      variant="body-l/normal"
                      color="blue.9"
                      whiteSpace="nowrap"
                      textOverflow={{
                        base: 'ellipsis',
                        '1xl': 'initial',
                      }}
                      overflow={{
                        base: 'hidden',
                        '1xl': 'visible',
                      }}
                    >
                      {vault.vaultName}
                    </Text>
                  </Tooltip>
                </HStack>
              </Flex>
              {/* Collateral */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Collateral" mb={2} />
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={getSortedCollateralSymbols(
                    vault.collateralCurrencies
                  ).join(', ')}
                >
                  <Box>
                    <TokenIconMultiple
                      symbols={getSortedCollateralSymbols(
                        vault.collateralCurrencies
                      )}
                    />
                  </Box>
                </Tooltip>
              </Flex>

              {/* Target/Actual */}
              {isMetaVaultView && (
                <Flex
                  flexDirection="column"
                  align="start"
                  verticalAlign="top"
                  px={0}
                >
                  <RepoCardHeading title="Target / Actual" mb={2} />
                  <Text
                    variant="body-l/normal"
                    color="blue.9"
                    whiteSpace="nowrap"
                  >
                    {targetValuePercent
                      ? `${targetValuePercent} / ${actualValuePercent}`
                      : 'N/A'}
                  </Text>
                </Flex>
              )}

              {/* Curator */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Curator" mb={2} />
                <HStack>
                  <VaultCuratorIcon
                    boxSize="24px"
                    vaultName={vault.curatorIcon ?? 'unknown'}
                  />
                  <Text
                    variant="body-l/normal"
                    color="blue.9"
                    whiteSpace="nowrap"
                  >
                    {vault.curator}
                  </Text>
                </HStack>
              </Flex>
            </Grid>
          </Flex>
          {/* Total Asset Value */}
          <Flex
            flexDirection="column"
            align="end"
            verticalAlign="top"
            mr={{
              base: '60px',
              lg: isMetaVaultView ? '30px' : '60px',
              '1xl': '60px',
            }}
            py={2.5}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <Text variant="body-l/normal" color="blue.9" whiteSpace="nowrap">
              {formatFixedToken(
                vault.totalAssetValue,
                symbol,
                false,
                false,
                undefined,
                false,
                symbol.toLowerCase() === 'usdc' ? 0 : undefined
              )}
            </Text>
          </Flex>
          {/* Button */}
          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
          >
            <HStack spacing={0.5} justifyContent="right" h="16px">
              <Text
                variant="body-xs/normal"
                color="gray.5.5"
                textAlign={{
                  base: 'right',
                }}
                whiteSpace="nowrap"
                ml="auto"
              >
                {chainConfig?.chainName}
              </Text>
              <NetworkIcon
                key={vault.chainId}
                chainId={vault.chainId}
                boxSize={'16px'}
                variant="faded"
                withTooltip
              />
            </HStack>
            <RepoCardButton
              handleClick={() => {
                onViewVault(vault.address, vault.chainId)
              }}
            >
              View
            </RepoCardButton>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
