import {
  Box,
  Table as CTable,
  Tbody as CTableBody,
  Tfoot as CTableFoot,
  Thead as CTableHead,
  Td as CTd,
  Th as CTh,
  Tr as CTr,
  StackProps,
  TableBodyProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableFooterProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Stack } from '../Stack'

export function Table({
  hasTopBorderRadius,
  isFixedLayout,
  noPadding,
  tableRef,
  minW,
  ...props
}: {
  hasTopBorderRadius?: boolean
  isFixedLayout?: boolean
  noPadding?: boolean
  minW?: string
  tableRef?: React.RefObject<HTMLTableElement>
} & TableProps) {
  return (
    <Box position="relative" minW={minW || 'auto'}>
      <Box
        paddingRight={noPadding ? 0 : '20px'}
        borderTopWidth={hasTopBorderRadius ? '1px' : 0}
        borderBottomLeftRadius="md"
        borderBottomRightRadius="md"
        borderTopLeftRadius={hasTopBorderRadius ? 'md' : 0}
        borderTopRightRadius={hasTopBorderRadius ? 'md' : 0}
        overflowX="auto"
        overflowY="hidden"
      >
        <CTable
          ref={tableRef}
          color="blue.9"
          fontSize="xs"
          lineHeight="4"
          layout={isFixedLayout ? 'fixed' : ''}
          {...props}
        />
      </Box>
    </Box>
  )
}

export function TableHead(props: TableHeadProps) {
  return <CTableHead {...props} />
}

export function TableFoot({
  noTopBorder,
  ...props
}: { noTopBorder?: boolean } & TableFooterProps) {
  return (
    <CTableFoot
      borderTopWidth={noTopBorder ? 'none' : 1}
      borderColor="gray.2"
      backgroundColor="gray.2"
      {...props}
    />
  )
}

export function Th({
  compact,
  sub,
  children,
  leftBorder,
  rightBorder,
  justifyChildren = 'flex-start',
  addSubTextMargin,
  spacing = '8px',
  ...props
}: {
  compact?: boolean
  sub?: ReactNode
  leftBorder?: boolean
  rightBorder?: boolean
  justifyChildren?: TableColumnHeaderProps['justifyContent']
  addSubTextMargin?: boolean
  spacing?: StackProps['spacing']
} & TableColumnHeaderProps) {
  return (
    <CTh
      color="blue.9"
      fontWeight="500"
      fontFamily="Inter"
      textTransform="none"
      lineHeight="4"
      letterSpacing="normal"
      borderLeft={leftBorder ? '1px solid' : 'none'}
      borderRight={rightBorder ? '1px solid' : 'none'}
      borderLeftColor="gray.2"
      borderRightColor="gray.2"
      verticalAlign="top"
      borderBottom="none"
      p={2}
      {...props}
    >
      {/* 32px - 1rem is because of the p={2} above */}
      <Stack
        justifyContent="stretch"
        h={compact ? 'calc(32px - 1rem)' : 'full'}
        spacing={spacing}
      >
        {!compact && (
          <Box
            flexGrow={1}
            h="full"
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            justifyContent={justifyChildren}
          >
            {children ?? <>&nbsp;</>}
          </Box>
        )}
        {sub && (
          <Text
            as="span"
            variant="body-xs/medium"
            color="blue.9"
            whiteSpace="nowrap"
            marginTop={addSubTextMargin ? '4px !important' : '0px'}
          >
            {sub}
          </Text>
        )}
      </Stack>
    </CTh>
  )
}

export function TableBody(props: TableBodyProps) {
  return <CTableBody {...props} backgroundColor="white" />
}

export function Tr({
  noBorder,
  ...props
}: { noBorder?: boolean } & TableRowProps) {
  return (
    <CTr
      borderBottomWidth={noBorder ? 0 : 1}
      borderColor="gray.2"
      bg="white"
      _hover={{ backgroundColor: 'gray.0' }}
      {...props}
    />
  )
}

export function Td({
  compact,
  large,
  leftBorder,
  rightBorder,
  ...props
}: {
  compact?: boolean
  large?: boolean
  leftBorder?: boolean
  rightBorder?: boolean
} & TableCellProps) {
  return (
    <CTd
      px={2}
      pt={large ? 3 : 0}
      pb={large ? 1 : 1.5}
      lineHeight={large ? 3 : 4}
      height={compact ? 9 : 12}
      borderLeft={leftBorder ? '1px solid' : 'none'}
      borderRight={rightBorder ? '1px solid' : 'none'}
      borderLeftColor="gray.2"
      borderRightColor="gray.2"
      whiteSpace="nowrap"
      borderBottom="none"
      {...props}
    />
  )
}
