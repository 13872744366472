import { Box, Flex, Heading } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useState } from 'react'
import AssetDropdown from '../../components/elements/AssetDropdown'
import { HStack } from '../../components/elements/Stack'
import { VaultsPageParams } from '../../models/vaults'
import MetaVaultBannerHasPositions from '../MetaVault/elements/MetaVaultBannerHasPositions'
import MetaVaultBannerNoPosition from '../MetaVault/elements/MetaVaultBannerNoPosition'
import MetaVaultDepositWithdrawWrapper from '../MetaVault/elements/MetaVaultDepositWithdrawWrapper'
import MetaVaultsTable from './elements/MetaVaultsTable'
import TotalDeposited from './elements/TotalDeposited'
import VaultsPageLoading from './elements/VaultsPageLoading'
import VaultsTable from './elements/VaultsTable'

export default function VaultsPage({
  isLoading,
  account,
  vaultsData,
  metaVaultsData,
  currencies,
  assetPrices,
  convertToAssetsRatio,
  totalVaultsDepositedUSD,
  totalMetaVaultsDepositedUSD,
  connectedAccountPositions,
  connectedAccountAssetTokenAllowances,
  connectedAccountVaultAssetTokenBalances,
  connectedAccountReceiptTokenBalancesInAssetTerms,
  onViewVault,
  onViewMetaVault,
  onViewPortfolio,
  onConnect,
  onApprove,
  onDeposit,
  onWithdraw,
  onWithdrawMax,
  onKytCheck,
}: VaultsPageParams) {
  const [depositToken, setDepositToken] = useState('all')
  const [depositMetaVaultToken, setDepositMetaVaultToken] = useState('all')
  const onChangePurchaseToken = (token: string) => {
    setDepositToken(token)
  }

  const onChangePurchaseMetaVaultToken = (token: string) => {
    setDepositMetaVaultToken(token)
  }

  if (
    // isLoading || // TODO: fix this on view model
    vaultsData === undefined ||
    metaVaultsData === undefined ||
    (vaultsData &&
      vaultsData.length === 0 &&
      metaVaultsData &&
      metaVaultsData.length === 0) ||
    currencies === undefined ||
    assetPrices === undefined ||
    convertToAssetsRatio === undefined ||
    totalVaultsDepositedUSD === undefined ||
    totalMetaVaultsDepositedUSD === undefined ||
    (connectedAccountAssetTokenAllowances &&
      connectedAccountPositions === undefined) ||
    onViewVault === undefined ||
    onViewMetaVault === undefined ||
    onViewPortfolio === undefined ||
    onApprove === undefined ||
    onConnect === undefined ||
    onDeposit === undefined ||
    onWithdraw === undefined ||
    onWithdrawMax === undefined ||
    onKytCheck === undefined
  ) {
    return <VaultsPageLoading />
  }

  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto" pt={4}>
      {connectedAccountPositions?.numberOfPositions &&
      connectedAccountPositions.numberOfPositions > 0 ? (
        <MetaVaultBannerHasPositions
          totalDeposited={totalVaultsDepositedUSD}
          yourDepositsValue={connectedAccountPositions.deposits}
          averagingValue={connectedAccountPositions.averageYield}
          acrossValue={connectedAccountPositions.numberOfPositions}
          onViewPortfolio={onViewPortfolio}
        />
      ) : (
        <MetaVaultBannerNoPosition totalDeposited={totalVaultsDepositedUSD} />
      )}

      <MetaVaultDepositWithdrawWrapper
        account={account}
        metaVaults={metaVaultsData ?? []}
        vaults={vaultsData ?? []}
        assetPrices={assetPrices}
        convertToAssetsRatio={convertToAssetsRatio}
        vaultAssetTokenBalance={connectedAccountVaultAssetTokenBalances}
        vaultAssetTokenAllowances={connectedAccountAssetTokenAllowances}
        receiptTokenBalancesInAssetTerms={
          connectedAccountReceiptTokenBalancesInAssetTerms
        }
        onConnect={onConnect}
        onApprove={onApprove}
        onDeposit={onDeposit}
        onWithdraw={onWithdraw}
        onWithdrawMax={onWithdrawMax}
        onKytCheck={onKytCheck}
      />
      {/* Meta Vaults */}
      <Heading
        variant="heading/h1"
        pt={6}
        mt={6}
        borderTop="1px solid #0000001A"
      >
        <Trans>Meta Vaults</Trans>
      </Heading>
      <Flex w="100%" pt={6} justify="space-between" mb={6}>
        <HStack spacing={10}>
          <Heading fontSize="20px" variant="heading/h2">
            <Trans>Deposit</Trans>
          </Heading>
          <AssetDropdown
            currencies={currencies}
            onChangePurchaseToken={onChangePurchaseMetaVaultToken}
            showAllOption
          />
        </HStack>
        <TotalDeposited
          total={totalMetaVaultsDepositedUSD}
          label="Total Deposited"
        />
      </Flex>

      <Flex w="100%" flexDir="column" gap={4}>
        <MetaVaultsTable
          key={depositMetaVaultToken}
          selectedDepositToken={depositMetaVaultToken}
          metaVaults={metaVaultsData}
          onViewMetaVault={onViewMetaVault}
        />
      </Flex>

      {/* Individual Strategy Vaults */}
      <Heading variant="heading/h1" mt={12}>
        <Trans>Individual Strategy Vaults</Trans>
      </Heading>
      <Flex w="100%" pt={6} justify="space-between" mb={6}>
        <HStack spacing={10}>
          <Heading fontSize="20px" variant="heading/h2">
            Deposit
          </Heading>
          <AssetDropdown
            currencies={currencies}
            onChangePurchaseToken={onChangePurchaseToken}
            showAllOption
          />
        </HStack>
        <TotalDeposited
          total={totalVaultsDepositedUSD}
          label="Total Deposited"
        />
      </Flex>
      <Flex w="100%" flexDir="column" gap={4}>
        <VaultsTable
          key={depositToken}
          selectedDepositToken={depositToken}
          vaultsData={vaultsData}
          onViewVault={onViewVault}
        />
      </Flex>
    </Box>
  )
}
