import { Box, Image, Link, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { Td, Tr } from '../../../../components/elements/Table'
import Chip from '../../../../components/elements/Chip'
import { ArrowUpRight } from '../../../../components/elements/Icons'
import dayjs from 'dayjs'
import Tooltip from '../../../../components/elements/Tooltip'
import {
  formatFixedPercentage,
  formatFixedUsd,
} from '../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import { columnWidths } from '.'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import { toReceiptToken } from '../../../../helpers/receiptToken'
import ProtocolIcon from '../../../../components/elements/ProtocolIcon'
import TokenLabelIcon from '../../../../components/elements/TokenLabelIcon'
import arrow_up_right from '../../../../assets/icons/arrow-up-right.svg'

export default function VaultHoldingsRow({
  isLiquid,
  isOpenAuctionOffer,
  tokenSymbol,
  tokenLabel,
  collateralOrLiquidSymbol,
  maturityTimestamp,
  redemptionTimestamp,
  faceValue,
  presentValue,
  interest,
  incentives,
  distributionRatio,
  idleVaultRate,
  idleVaultUrl,
  holdingUrl,
}: {
  isLiquid?: boolean
  isOpenAuctionOffer?: boolean
  tokenSymbol: string
  tokenLabel: string
  collateralOrLiquidSymbol: string
  maturityTimestamp?: number
  redemptionTimestamp?: number
  faceValue: FixedNumber
  presentValue: FixedNumber
  interest: FixedNumber
  incentives?: any // TODO: finalise this type later
  distributionRatio: FixedNumber
  idleVaultRate?: string
  idleVaultUrl?: string
  holdingUrl?: string
}) {
  const currentTime = useCurrentTime()
  return (
    <Tr
      color={isOpenAuctionOffer ? 'gray.4' : 'blue.9'}
      fontStyle={isOpenAuctionOffer ? 'italic' : 'normal'}
      height="80px"
    >
      {/* Token name + icon */}
      <Td w={`${columnWidths.asset}px`}>
        <HStack spacing="8px">
          <TokenIconLabel
            isSmall
            token={isLiquid ? toReceiptToken(tokenSymbol) : tokenSymbol}
            displayTokenLabel={tokenLabel}
            boxSize="24px"
            isSkipCapitalization
            columnGap="8px"
          />
          {isLiquid && (
            <Box as="span" ml="8px">
              <Chip>Liquid</Chip>
            </Box>
          )}
          {holdingUrl && (
            <Link isExternal href={holdingUrl}>
              <Image boxSize={'19px'} src={arrow_up_right} alt="arrow" />
            </Link>
          )}
        </HStack>
      </Td>
      {/* Maturity */}
      <Td w={`${columnWidths.maturity}px`}>
        <Text variant="body-sm/normal">
          {isLiquid
            ? '-'
            : dayjs.unix(maturityTimestamp ?? 0).format(`MMM D 'YY`)}
        </Text>
      </Td>
      {/* Collateral */}
      <Td w={`${columnWidths.collateral}px`}>
        <HStack spacing="8px">
          {isLiquid && idleVaultUrl ? (
            <>
              <ProtocolIcon
                protocol={collateralOrLiquidSymbol}
                boxSize="24px"
              />
              <Link href={idleVaultUrl} isExternal>
                <HStack spacing="0">
                  <Text
                    variant="body-sm/normal"
                    color="gray.6"
                    textDecoration="none"
                    lineHeight="20px"
                  >
                    View
                  </Text>
                  <ArrowUpRight h="19px" w="19px" color="gray.3" />
                </HStack>
              </Link>
            </>
          ) : (
            <>
              <TokenLabelIcon
                boxSize="24px"
                isSmall
                token={collateralOrLiquidSymbol}
                columnGap="8px"
              />
            </>
          )}
        </HStack>
      </Td>
      {/* Redeem In */}
      <Td w={`${columnWidths.redeemIn}px`}>
        {isLiquid ? (
          '-'
        ) : (
          <Tooltip
            noDelay
            shouldWrapChildren
            label={dayjs.unix(redemptionTimestamp ?? 0).format('MMM D, hh:mma')}
          >
            <Text variant="body-sm/normal">
              {dayjs
                .unix(redemptionTimestamp ?? 0)
                .from(currentTime)
                .charAt(0)
                .toUpperCase() +
                dayjs
                  .unix(redemptionTimestamp ?? 0)
                  .from(currentTime)
                  .slice(1)}
            </Text>
          </Tooltip>
        )}
      </Td>
      {/* Face Value */}
      <Td textAlign="end" w={`${columnWidths.faceValue}px`}>
        <Text variant="body-sm/semibold" w="calc(100% - 20px)">
          {formatFixedUsd(faceValue, true)}
        </Text>
      </Td>
      {/* Present Value */}
      <Td textAlign="end" w={`${columnWidths.presentValue}px`}>
        <Text variant="body-sm/normal" w="calc(100% - 20px)">
          {formatFixedUsd(presentValue)}
        </Text>
      </Td>
      {/* Interest */}
      <Td textAlign="end" w={`${columnWidths.interest}px`}>
        <Text variant="body-sm/normal" w="calc(100% - 20px)">
          {isOpenAuctionOffer
            ? '-'
            : isLiquid
              ? idleVaultRate
                ? `${idleVaultRate}%`
                : '-'
              : formatFixedPercentage(interest, 3)}
        </Text>
      </Td>
      {/* Incentives */}
      {/* TODO: complete this later when incentives finalised */}
      <Td textAlign="end" w={`${columnWidths.incentives}px`}>
        <HStack spacing="0" justifyContent="end" alignItems="end">
          <Text variant="body-sm/normal" w="calc(100% - 20px)">
            {/* {formatFixedPercentage(holding.incentives)} */}
            {'-'}
          </Text>
          {/* <SparkleIcon h="24px" w="24px" /> */}
        </HStack>
      </Td>
      {/* Distribution */}
      <Td textAlign="end" w={`${columnWidths.distribution}px`}>
        <Text variant="body-sm/semibold" w="calc(100% - 20px)">
          {formatFixedPercentage(distributionRatio)}
        </Text>
      </Td>
    </Tr>
  )
}
