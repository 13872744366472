import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import {
  Mainnet,
  Sepolia,
  TransactionStatus,
  useSendTransaction,
} from '@usedapp/core'
import { FixedNumber, Contract, BigNumber } from 'ethers'
import { Address } from '../model'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TermAirdropSeason1 } from '../../abi-generated/abi/airdrop/TermAirdropSeason1'
import TermAirdropSeason1ABI from '../../abi/airdrop/TermAirdropSeason1.json'
import { waitForStatus } from '../../helpers/wait'
import { useConfig } from '../../providers/config'
import { bigToFixedNumber } from '../../helpers/conversions'
// import { MultichainCalls, useMultichainCalls } from './helper-hooks'
// import { captureException } from '@sentry/react'
import { multiply } from '../../helpers/math'
import { watchAsset } from '../../helpers/eip747'
import { TokenClaim } from '../../models/rewards'
import { useNavigate } from 'react-router-dom'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { paths as protocolPaths } from '../../models/protocol-api'
import { useSeasonsInfo } from './points/use-seasons-info'
import dayjs from 'dayjs'
import { useMerkleRootClaim } from './use-merkle-root'
import { useTermTokenPrice } from './use-term-token-price'

export function useTokenClaim(
  seasonToClaim: number,
  account: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  onCheckActiveNetwork: (
    chainId?: number,
    chainName?: string
  ) => Promise<boolean>
):
  | {
      [season: number]: TokenClaim
    }
  | undefined {
  const navigate = useNavigate()
  const config = useConfig()
  const chainId = useMemo(
    () => (config.isMainnet ? Mainnet.chainId : Sepolia.chainId),
    [config.isMainnet]
  )

  const seasons = useSeasonsInfo().seasonsInfo ?? undefined

  const season1AirdropCA = useMemo(() => {
    if (
      config.isMainnet &&
      config.chains[Mainnet.chainId]?.contracts?.airdrop
    ) {
      return config.chains[Mainnet.chainId].contracts.airdrop
    } else {
      return config.chains[Sepolia.chainId].contracts.airdrop
    }
  }, [config.isMainnet, config.chains])

  const termTokenAddress = useMemo(
    () => config.termTokenAddress,
    [config.termTokenAddress]
  )

  const [hasClaimedTokens, setHasClaimedTokens] = useState(false)
  const [totalAwardedAmount, setTotalAwardedAmount] = useState(
    FixedNumber.fromString('0', 'fixed128x18')
  )
  const [totalAwardedAmountUSD, setTotalAwardedAmountUSD] = useState(
    FixedNumber.fromString('0', 'fixed128x18')
  )
  const [claimableAmount, setClaimableAmount] = useState(
    FixedNumber.fromString('0', 'fixed128x18')
  )
  const [claimableAmountUSD, setClaimableAmountUSD] = useState(
    FixedNumber.fromString('0', 'fixed128x18')
  )

  const { currentPrice: termTokenPriceUSD } = useTermTokenPrice() || {}

  const seasonTokenRewardsAllWallets = useMerkleRootClaim(
    account,
    season1AirdropCA
  )

  const airdropClaimUrl = useMemo(
    () =>
      config.protocolServerUrl && season1AirdropCA && account && chainId
        ? `${config.protocolServerUrl}/protocol/airdrop/${season1AirdropCA}/claimed/${account.toLowerCase()}/${chainId}`
        : undefined,
    [account, chainId, config.protocolServerUrl, season1AirdropCA]
  )

  const { data: accountClaimedList } = useJsonRestCalls<
    { '#call': RequestInfo },
    protocolPaths['/protocol/airdrop/{airdropAddress}/claimed/{claimerAddress}/{chainId}']['get']['responses']['200']['content']['application/json']
  >({
    '#call': {
      url: airdropClaimUrl,
      method: 'GET',
    } as RequestInfo,
  })

  // setup transaction to claim
  // #region

  const transactionStates = useMemo(
    () =>
      ({}) as {
        claimTokens: TransactionStatus | undefined
      },
    []
  )

  const {
    sendTransaction: claimTokensTransaction,
    state: claimTokensTransactionState,
    resetState: resetClaimTokensState,
  } = useSendTransaction()

  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.claimTokens = claimTokensTransactionState
  }, [claimTokensTransactionState, transactionStates])

  // #endregion

  // claim contract call

  const claimContract = useMemo(
    () =>
      season1AirdropCA
        ? (new Contract(
            season1AirdropCA,
            TermAirdropSeason1ABI,
            provider
          ) as TermAirdropSeason1)
        : undefined,
    [season1AirdropCA, provider]
  )

  // const endTimeCall: MultichainCalls = useMemo(() => {
  //   if (!claimContract) {
  //     return {}
  //   }
  //   return {
  //     [chainId]: [
  //       {
  //         contract: claimContract,
  //         method: 'endTime',
  //         args: [],
  //       },
  //     ],
  //   }
  // }, [chainId, claimContract])

  // const blockchainResponse = useMultichainCalls(endTimeCall)

  // const blockchainResults = useMemo(() => {
  //   const result: { [chainId: string]: any[] } = {}
  //   Object.entries(blockchainResponse).forEach(
  //     ([chainId, resultsAndErrors]) => {
  //       result[chainId] = resultsAndErrors
  //         .filter((resultOrError) => !resultOrError?.error)
  //         .map((resultOrError) => resultOrError?.value)
  //     }
  //   )
  //   return result
  // }, [blockchainResponse])

  // const blockchainErrors = useMemo(() => {
  //   const errors: { [chainId: string]: any[] } = {}

  //   if (blockchainResponse) {
  //     Object.entries(blockchainResponse).forEach(
  //       ([chainId, resultsAndErrors]) => {
  //         const chainErrors = resultsAndErrors
  //           .filter((resultOrError) => !!resultOrError?.error)
  //           .map((resultOrError) => resultOrError?.error)

  //         if (chainErrors.length > 0) {
  //           errors[chainId] = chainErrors
  //         }
  //       }
  //     )
  //   }

  //   return errors
  // }, [blockchainResponse])

  // useEffect(() => {
  //   if (blockchainErrors) {
  //     Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
  //       console.error(`Error on ${chainId}: ${chainError}`)
  //       captureException(chainError)
  //     })
  //   }
  // }, [blockchainErrors])

  // const expirationDate = useMemo(() => {
  //   if (!blockchainResults) return null

  //   let value: number | undefined

  //   Object.entries(blockchainResults).forEach(([_, values]) => {
  //     const rawEndTime = values?.[0]
  //     if (rawEndTime) {
  //       value = BigNumber.from(rawEndTime?.[0]).toNumber() * 1000
  //     }
  //   })
  //   return value
  // }, [blockchainResults])

  const expirationDate = 1745640000000

  useEffect(() => {
    setHasClaimedTokens(false)
    const zeroFN = FixedNumber.fromString('0', 'fixed128x18')
    if (seasonTokenRewardsAllWallets && account && termTokenPriceUSD) {
      // fetch the claim for the account
      const userClaimDetails =
        seasonTokenRewardsAllWallets.claims[account.toLowerCase()]
      const totalAwardedFN = bigToFixedNumber(
        BigNumber.from(userClaimDetails?.amount ?? '0'),
        18
      )
      const totalAwardedUSDFN = multiply(totalAwardedFN, termTokenPriceUSD)

      setTotalAwardedAmount(totalAwardedFN)
      setTotalAwardedAmountUSD(totalAwardedUSDFN)

      // check if the account has already claimed and set to 0 if it has
      if (accountClaimedList && accountClaimedList.length > 0) {
        setHasClaimedTokens(true)
        setClaimableAmount(zeroFN)
        setClaimableAmountUSD(zeroFN)
      } else if (!!expirationDate && dayjs(expirationDate).isBefore(dayjs())) {
        setClaimableAmount(zeroFN)
        setClaimableAmountUSD(zeroFN)
      } else {
        setClaimableAmount(totalAwardedFN)
        setClaimableAmountUSD(totalAwardedUSDFN)
      }
    } else {
      setClaimableAmount(zeroFN)
      setClaimableAmountUSD(zeroFN)

      setTotalAwardedAmount(zeroFN)
      setTotalAwardedAmountUSD(zeroFN)
    }
  }, [
    seasonTokenRewardsAllWallets,
    account,
    accountClaimedList,
    termTokenPriceUSD,
    expirationDate,
  ])

  const claimTokens = useCallback(async () => {
    if (!claimContract) {
      throw new Error('Claim contract is not defined')
    }
    if (!account) {
      throw new Error('Account is not defined')
    }
    if (!seasonTokenRewardsAllWallets) {
      throw new Error('no claims found')
    }

    if (claimableAmount.isZero()) {
      throw new Error('No tokens to claim')
    }

    if (hasClaimedTokens) {
      throw new Error('Already claimed')
    }

    if (onCheckActiveNetwork && !(await onCheckActiveNetwork(chainId))) {
      throw new Error('Network not supported')
    }

    console.info(
      'claiming airdrop tokens for account: %o on chain: %o',
      account,
      chainId
    )
    const claimDetails =
      seasonTokenRewardsAllWallets.claims[account.toLowerCase()]

    console.info('%o to claim', claimDetails.amount.toString())

    const data = claimContract.interface.encodeFunctionData('claim', [
      claimDetails.index,
      account,
      BigNumber.from(claimDetails.amount),
      claimDetails.proof,
    ])

    console.info('submitting claim transaction')

    await claimTokensTransaction({
      chainId,
      to: season1AirdropCA,
      from: account,
      data,
    })

    console.info('waiting for claim transaction to complete')

    await waitForStatus(
      async () => transactionStates.claimTokens,
      resetClaimTokensState,
      ['Success', 'Exception', 'Fail'],
      100,
      1000,
      claimContract
    )

    const result = transactionStates?.claimTokens?.status ?? 'None'

    if (result === 'Fail') {
      throw new Error('Transaction failed')
    }
    return result
  }, [
    claimContract,
    account,
    seasonTokenRewardsAllWallets,
    claimableAmount,
    hasClaimedTokens,
    chainId,
    claimTokensTransaction,
    season1AirdropCA,
    resetClaimTokensState,
    transactionStates.claimTokens,
    onCheckActiveNetwork,
  ])

  const importTokens = useCallback(async () => {
    if (!provider) {
      return false
    }
    return await watchAsset(
      chainId.toString(),
      provider as JsonRpcProvider,
      termTokenAddress,
      'TERM',
      18
    )
  }, [chainId, provider, termTokenAddress])

  const returnVal = useMemo(() => {
    if (
      !seasons ||
      !expirationDate ||
      !season1AirdropCA ||
      !accountClaimedList
    ) {
      return undefined
    }
    const season = seasons.find((s) => s.id === seasonToClaim)
    return {
      [seasonToClaim]: {
        seasonId: seasonToClaim,
        seasonName: season?.name ?? 'Season',
        airdropCA: season1AirdropCA,
        expirationDate,
        claimableAmount,
        claimableAmountUSD,
        totalAwardedAmount,
        totalAwardedAmountUSD,
        hasClaimedTokens,
        hasStakedTokens: false, // TODO: implement staking lookup
        claimTokens,
        importTokens,
        stakeTokens: () => navigate('/term'),
      },
    }
  }, [
    accountClaimedList,
    seasons,
    season1AirdropCA,
    seasonToClaim,
    expirationDate,
    claimableAmount,
    claimableAmountUSD,
    totalAwardedAmount,
    totalAwardedAmountUSD,
    hasClaimedTokens,
    claimTokens,
    importTokens,
    navigate,
  ])

  return returnVal
}
