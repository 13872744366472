import { ChainId } from '@usedapp/core'

export type ChainConfigItem = {
  chainId: ChainId
  chainName?: string
  blockExplorerUrl?: string
  wrappedGasAddress: string
  wrappedGasSymbol: string
  wrapConversionFactor: number
  multicallAddress?: string
  multicall2Address?: string
  multisendAddress?: string
  subgraphUrl: string
  listingsSubgraphUrl: string
  vaultsSubgraphUrl: string
  subgraphVersion?: string
  revealServerUrl: string
  rpcUrl: string
  priceOracleAddress: string
  listingsContractAddress: string
  testnetTokens?: string[]
  auctionsToFilterOut?: string[]
  termsToFilterOut?: string[]
  vaultsToFilterOut?: string[]
  wrappedTokenMapping?: { [naked: string]: string }
  gradient: string
  verticalGradient: string
  opacity: number
  // TODO: refactor to make this not optional
  contractAddresses?: {
    airdrop: {
      1: string
    }
  }
}

export const OPACITIES: { [chainId: number]: number } = {
  [ChainId.Mainnet]: 0.5,
  [ChainId.Base]: 0.3,
  [ChainId.Polygon]: 0.4,
  [ChainId.BSC]: 0.5,
  [ChainId.Arbitrum]: 0.4,
  [ChainId.Avalanche]: 0.4,
}

export const GRADIENTS: { [chainId: number]: string } = {
  [ChainId.Mainnet]:
    'linear-gradient(270deg, #EEEFF3 0%, rgba(238, 239, 243, 0.00) 67.43%), #FCFEFF',
  [ChainId.Base]:
    'linear-gradient(270deg, #E9EEFE 0%, rgba(233, 238, 254, 0.00) 67.58%), #FCFEFF',
  [ChainId.Polygon]:
    'linear-gradient(270deg, #F2EDFC 0%, rgba(245, 240, 253, 0.00) 67.18%, rgba(242, 237, 252, 0.00) 67.18%), #FCFEFF',
  [ChainId.BSC]:
    'linear-gradient(270deg, #FEFBF0 0%, rgba(254, 251, 240, 0.00) 67.98%), #FCFEFF',
  [ChainId.Arbitrum]:
    'linear-gradient(270deg, #EAF4FB 0%, rgba(234, 244, 251, 0.00) 67.6%), #FCFEFF',
  [ChainId.Avalanche]:
    'linear-gradient(270deg, rgba(232, 65, 66, 0.07) 0%, rgba(232, 65, 66, 0.00) 67.43%), #FCFEFF',
}

export const VERTICAL_GRADIENTS: { [chainId: number]: string } = {
  [ChainId.Mainnet]:
    'linear-gradient(180deg, #F2F2F5 0%, rgba(242, 242, 245, 0) 100%)',
  [ChainId.Base]:
    'linear-gradient(180deg, #EDF1FE 0%, rgba(237, 241, 254, 0) 100%)',
  [ChainId.Polygon]:
    'linear-gradient(180deg, #F2EDFC 0%, rgba(242, 237, 252, 0) 100%)',
  [ChainId.Avalanche]:
    'linear-gradient(180deg, rgba(232, 65, 66, 0.07) 0%, rgba(232, 65, 66, 0.00) 100%)',
  // [ChainId.BSC]: 'linear-gradient(270deg, #FEFBF0 0%, rgba(254, 251, 240, 0.00) 67.98%), #FCFEFF',
  // [ChainId.Arbitrum]: 'linear-gradient(270deg, #EAF4FB 0%, rgba(234, 244, 251, 0.00) 67.6%), #FCFEFF',
}

export const getChains = (environment: string): ChainConfigItem[] => {
  switch (environment) {
    case 'mainnet':
      return mainnetChains
    case 'testnet':
      return testnetChains
    case 'development':
    case 'staging':
      return stagingChains
    default:
      throw new Error(`Unsupported environment value provided: ${environment}`)
  }
}

const mainnetChains = [
  // Mainnet
  {
    chainId: ChainId.Mainnet,
    chainName: 'Ethereum',
    wrappedGasAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    wrappedGasSymbol: 'WETH',
    wrapConversionFactor: 1,
    multicallAddress: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    subgraphUrl:
      process.env.REACT_APP_SUBGRAPH_MAINNET_MAINNET_URL ??
      'https://graphql-gateway-term.mainnet.mainnet.termfinance.io/graphql',
    listingsSubgraphUrl:
      process.env.REACT_APP_LISTINGS_SUBGRAPH_MAINNET_MAINNET_URL ??
      'https://graphql-gateway-listings.mainnet.mainnet.termfinance.io/graphql',
    vaultsSubgraphUrl:
      process.env.REACT_APP_VAULTS_SUBGRAPH_MAINNET_MAINNET_URL ??
      'https://graphql-gateway-vaults.mainnet.mainnet.termfinance.io/graphql',
    subgraphVersion: 'latest',
    revealServerUrl: 'https://api.mainnet.termfinance.io/mainnet/revealer',
    rpcUrl:
      process.env.REACT_APP_RPC_MAINNET_MAINNET_URL ??
      'https://evm-json-rpc-cache.mainnet.mainnet.termfinance.io',
    priceOracleAddress: '0x04fcd15c3582f0fa81a46533795196a5257f79c8',
    listingsContractAddress: '0x43ad4423d5e9569497e9f1f899e623409270d1e6',
    wrappedTokenMapping: {
      // wrapped : naked
      '0x288a8005c53632d920045b7c7c2e54a3f1bc4c83':
        '0x530824da86689c9c17cdc2871ff29b058345b44a', // STBT
    },
    gradient: GRADIENTS[ChainId.Mainnet],
    verticalGradient: VERTICAL_GRADIENTS[ChainId.Mainnet],
    opacity: OPACITIES[ChainId.Mainnet],
    vaultsToFilterOut: [
      '0x4718da79cf9eaecfd3f7319c74dc2ecfbcbb9f78',
      '0x6e053c62888f54ce39a9f405fa2743b0df2ca3f8',
      '0x8be87743bfde3b8607dcb7bfc2728f41da2fc730',
      '0xd50af9206e6b27056bc80463f8ce7e064a60b0ce',
      '0xddb8f495ab27457f91fa7aae4d986ecd78195495',
    ],
    contractAddresses: {
      airdrop: {
        1: '0x71c79f6f345df0ea7045d9259c4bc131deaea0bb',
      },
    },
  },
  // Avalanche
  {
    chainId: ChainId.Avalanche,
    chainName: 'Avalanche',
    wrappedGasAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    wrappedGasSymbol: 'WAVAX',
    wrapConversionFactor: 1,
    multicallAddress: '0xca11bde05977b3631167028862be2a173976ca11',
    subgraphUrl:
      process.env.REACT_APP_SUBGRAPH_AVALANCHE_MAINNET_URL ??
      'https://graphql-gateway-term.avalanche.mainnet.termfinance.io/graphql',
    listingsSubgraphUrl:
      process.env.REACT_APP_LISTINGS_SUBGRAPH_AVALANCHE_MAINNET_URL ??
      'https://graphql-gateway-listings.avalanche.mainnet.termfinance.io/graphql',
    vaultsSubgraphUrl:
      process.env.REACT_APP_VAULTS_SUBGRAPH_AVALANCHE_MAINNET_URL ??
      'https://graphql-gateway-vaults.avalanche.mainnet.termfinance.io/graphql',
    subgraphVersion: 'latest',
    revealServerUrl: 'https://api.mainnet.termfinance.io/avalanche/revealer',
    rpcUrl:
      process.env.REACT_APP_RPC_AVALANCHE_MAINNET_URL ??
      'https://evm-json-rpc-cache.avalanche.mainnet.termfinance.io',
    priceOracleAddress: '0xce580b2da6e002e43095ad7797255e49a20f6b06',
    listingsContractAddress: '0xe97c3bf02cb866f611f072f14820eb5c96c2081d',
    gradient: GRADIENTS[ChainId.Avalanche],
    verticalGradient: VERTICAL_GRADIENTS[ChainId.Avalanche],
    opacity: OPACITIES[ChainId.Avalanche],
  },
]

const testnetChains = [
  // Sepolia
  {
    chainId: ChainId.Sepolia,
    // AAVE WETH sepolia contract
    wrappedGasAddress: '0xd0df82de051244f04bff3a8bb1f62e1cd39eed92',
    wrappedGasSymbol: 'WETH',
    wrapConversionFactor: 1,
    multicallAddress: '0xca11bde05977b3631167028862be2a173976ca11',
    multicall2Address: '0xca11bde05977b3631167028862be2a173976ca11',
    subgraphUrl:
      'https://graphql-gateway-term.sepolia.testnet.termfinance.io/graphql',
    listingsSubgraphUrl:
      'https://graphql-gateway-listings.sepolia.testnet.termfinance.io/graphql',
    vaultsSubgraphUrl:
      'https://graphql-gateway-vaults.sepolia.testnet.termfinance.io/graphql',
    revealServerUrl: 'https://api.testnet.termfinance.io/sepolia/revealer',
    rpcUrl: 'https://evm-json-rpc-cache.sepolia.testnet.termfinance.io',
    priceOracleAddress: '0xcaF60908cC2fB7fa513Ee34Ca4dd182e37b17cA4',
    listingsContractAddress: '0x5dc482791088307f7963064606dc422476c98159',
    testnetTokens: [
      '0x27af15ed04dfb358ea86f22ff93625d6b7cef494', // USDC (original)
      '0x2b299f73061514ae5d6c654f0b4f4c802a40f38e', // USDC (permittable)
      '0xf83f3701c246ea60149880039395e8a781f34939', // USDT
      '0xf9b8784d7b595aed9f36f823bb7138aac293cb0e', // WBTC
      '0xf243b1edff0d00b0c3eb170511c3cae4de38cd55', // WETH
      '0x91e481a99d0d87d7a9c4865933c0f8817dec00a6', // DAI
    ],
    gradient: GRADIENTS[ChainId.Mainnet],
    verticalGradient: VERTICAL_GRADIENTS[ChainId.Mainnet],
    opacity: OPACITIES[ChainId.Mainnet],
    vaultsToFilterOut: [
      '0x4308022935a721b52b71a0d6ed2e5dac3b348b65',
      '0xb7328a2c65a501089f57229cd8c9b7d4e295dc42',
      '0x7008cd3fd679736559bddafc538223ac33a09004',
      '0x78213c57d433d0de153b0952619c66a8faf283b0',
      '0x9dfc266880e2d0268ecc51da86cb45839a716690',
      '0xa1d8693a8a8ebbbc02aaea715862d43136ee066a',
      '0xa526a102ca2a213a7ec0faa801fd1e7d8d35ebdb',
      '0x8ee47dba22c4454f22d71d0c0eb8ea4000863dc4',
      '0x581473ffa78e23e12b249b94cf4356ddcd602b17',
      '0xcfc4a641d5c0388dc92150b3505ca2acec308211',
      '0xddb8f495ab27457f91fa7aae4d986ecd78195495',
      '0x440b687a15466089d2bf504fd1ac8d94f6feff1a',
      '0xf79c16e510034a266d5f3651a6b80abd291fc329',
      '0x96fea6468c84feac06f553c3ee48bd889c449cde',
    ],
    contractAddresses: {
      airdrop: {
        1: '0xc678f7d76b7317de5ccadfa603e205833b88b5c4',
      },
    },
  },
]

const stagingChains = [
  // Sepolia
  {
    chainId: ChainId.Sepolia,
    // AAVE WETH sepolia contract
    wrappedGasAddress: '0xd0df82de051244f04bff3a8bb1f62e1cd39eed92',
    wrappedGasSymbol: 'WETH',
    wrapConversionFactor: 1,
    multicallAddress: '0xca11bde05977b3631167028862be2a173976ca11',
    multicall2Address: '0xca11bde05977b3631167028862be2a173976ca11',
    subgraphUrl:
      'https://graphql-gateway-term.sepolia.testnet.termfinance.io/graphql',
    listingsSubgraphUrl:
      'https://graphql-gateway-listings.sepolia.testnet.termfinance.io/graphql',
    vaultsSubgraphUrl:
      'https://graphql-gateway-vaults.sepolia.testnet.termfinance.io/graphql',
    revealServerUrl:
      'https://api-staging.testnet.termfinance.io/sepolia-staging/revealer',
    rpcUrl: 'https://evm-json-rpc-cache.sepolia.testnet.termfinance.io',
    priceOracleAddress: '0xcaF60908cC2fB7fa513Ee34Ca4dd182e37b17cA4',
    listingsContractAddress: '0x5dc482791088307f7963064606dc422476c98159',
    testnetTokens: [
      '0x27af15ed04dfb358ea86f22ff93625d6b7cef494', // USDC (original)
      '0x2b299f73061514ae5d6c654f0b4f4c802a40f38e', // USDC (permittable)
      '0xf83f3701c246ea60149880039395e8a781f34939', // USDT
      '0xf9b8784d7b595aed9f36f823bb7138aac293cb0e', // WBTC
      '0xf243b1edff0d00b0c3eb170511c3cae4de38cd55', // WETH
      '0x91e481a99d0d87d7a9c4865933c0f8817dec00a6', // DAI
    ],
    gradient: GRADIENTS[ChainId.Mainnet],
    verticalGradient: VERTICAL_GRADIENTS[ChainId.Mainnet],
    opacity: OPACITIES[ChainId.Mainnet],
    contractAddresses: {
      airdrop: {
        1: '0xc678f7d76b7317de5ccadfa603e205833b88b5c4',
      },
    },
  },
]
