import {
  Box,
  Text,
  Button,
  Heading,
  Image,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import { Socials } from '../../../../models/rewards'
import twitterIcon from '../../../../assets/icons/twitter-x.svg'
import discordIcon from '../../../../assets/icons/discord.svg'
import { useTermToast } from '../../../../hooks/toasts'

export default function SignIn({
  subText,
  account,
  connectedSocials,
  onLinkWallet,
  onLinkTwitter,
  onLinkDiscord,
}: {
  subText: string
  account?: string
  connectedSocials: Record<Socials, boolean>
  onLinkWallet?: () => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
}) {
  const termToast = useTermToast()

  const onSignIn = async (type: 'twitter' | 'sign' | 'discord') => {
    switch (type) {
      case 'sign':
        if (onLinkWallet) {
          await onLinkWallet()
        } else {
          termToast.failure({
            title: 'Error signing in with wallet',
            children: 'Please try again.',
          })
        }
        break
      case 'twitter':
        if (onLinkTwitter) {
          await onLinkTwitter()
        } else {
          termToast.failure({
            title: 'Error signing in via X',
            children: 'Please try again.',
          })
        }
        break
      case 'discord':
        if (onLinkDiscord) {
          await onLinkDiscord()
        } else {
          termToast.failure({
            title: 'Error signing in via Discord',
            children: 'Please try again.',
          })
        }
        break
    }
  }

  return (
    <Flex align="center" justify="center">
      <Box
        w="540px"
        p={7}
        borderRadius="16px"
        bg="white"
        boxShadow="0px 4px 4px 0px rgba(49, 130, 206, 0.03)"
      >
        <Box mb={10}>
          <Heading variant="heading/h1" as="h1" color="blue.9" mb={2}>
            Sign in
          </Heading>
          <Text variant="body-md/normal" color="gray.5">
            {subText}
          </Text>
        </Box>
        <SignInBody
          account={account}
          onSignIn={onSignIn}
          connectedSocials={connectedSocials ?? {}}
        />
      </Box>
    </Flex>
  )
}

export function SignInBody({
  account,
  isOnConnectScreen,
  isSwitchProfileViewOnly,
  onSignIn,
  onViewOnly,
  connectedSocials: { twitter: connectedTwitter, discord: connectedDiscord },
}: {
  account?: string
  isOnConnectScreen?: boolean
  isSwitchProfileViewOnly?: boolean
  onSignIn: (type: 'twitter' | 'discord' | 'sign') => void
  onViewOnly?: () => void
  connectedSocials: Record<Socials, boolean>
}) {
  const shortenedAddress = account
    ? `${account?.slice(0, 4)}..${account?.slice(account.length - 2, account.length)}`
    : ''

  const hasSocials = connectedTwitter || connectedDiscord

  const onSign = () => onSignIn('sign')

  return (
    <>
      <VStack gap={3} align="left">
        {(isOnConnectScreen || connectedTwitter) && (
          <Button
            w="full"
            variant="secondary"
            leftIcon={<Image src={twitterIcon} w="16px" />}
            onClick={() => onSignIn('twitter')}
          >
            Sign in with X
          </Button>
        )}
        {(isOnConnectScreen || connectedDiscord) && (
          <Button
            w="full"
            variant="secondary"
            leftIcon={<Image src={discordIcon} w="16px" />}
            onClick={() => onSignIn('discord')}
          >
            Sign in with Discord
          </Button>
        )}
      </VStack>
      {!isOnConnectScreen ? (
        <>
          {hasSocials && <OrDivider />}
          <VStack>
            {!hasSocials && (
              <Button w="full" variant="secondary" onClick={onSign}>
                Sign message
              </Button>
            )}
            <Text
              variant="body-sm/normal"
              color="blue.9"
              fontWeight={400}
              opacity={0.6}
            >
              Sign a message (free!) for the connected wallet:{' '}
              <Text as="span" fontWeight={500}>
                {shortenedAddress}
              </Text>
            </Text>
            {hasSocials && (
              <Button w="full" variant="tertiary" onClick={onSign}>
                Sign message
              </Button>
            )}
            {isSwitchProfileViewOnly && (
              <Button
                w="full"
                variant="tertiary"
                border="0px"
                color="blue.9"
                opacity={0.75}
                onClick={() => {
                  if (onViewOnly) {
                    onViewOnly()
                  }
                }}
              >
                <Text variant="body-sm/normal" color="blue.9">
                  Or,{' '}
                  <Text as="span" variant="body-sm/medium">
                    continue with restricted view
                  </Text>
                </Text>
              </Button>
            )}
          </VStack>
        </>
      ) : null}
    </>
  )
}

const OrDivider = () => (
  <Flex align="center" my={5} style={{ color: '#667085' }}>
    <Divider style={{ borderColor: '#BCBCBC' }} />
    <Text variant="body-sm/normal" fontWeight={400} padding="2">
      OR
    </Text>
    <Divider style={{ borderColor: '#BCBCBC' }} />
  </Flex>
)
