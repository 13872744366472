import { Box, Image } from '@chakra-ui/react'
import { useCallback } from 'react'

import vaultUSDC from '../../../../assets/icons/tokens/USDC_VAULT.svg'
import vaultWETH from '../../../../assets/icons/tokens/WETH_VAULT.svg'
import vaultUSDS from '../../../../assets/icons/tokens/USDS_VAULT.svg'

export default function VaultTokenIcon({ symbol }: { symbol: string }) {
  const vaultTokenImage = useCallback(() => {
    switch (symbol?.toUpperCase()) {
      case 'WETH':
        return vaultWETH
      case 'USDS':
        return vaultUSDS
      case 'USDC':
      default:
        return vaultUSDC
    }
  }, [symbol])

  return (
    <Box bg="#3E73C4" w="122px" h="125px" p={2.5}>
      <Image w="full" h="full" src={vaultTokenImage()} />
    </Box>
  )
}
