import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Input,
  Link,
  Text,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEthers } from '@usedapp/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import arrow_up_right from '../../../../assets/icons/arrow-up-right.svg'
import gnosis from '../../../../assets/icons/gnosis.svg'
import info from '../../../../assets/icons/info.svg'
import wallet_with_green_dot_icon from '../../../../assets/icons/wallet-with-green-dot.svg'
import wallet_icon from '../../../../assets/icons/wallet.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import { Address, ProfileWallet } from '../../../../data/model'
import { shortenAddress } from '../../../../helpers/utils'
import { useChainConfig } from '../../../../providers/config'
import SectionHeader from './SectionHeader'
import { LinkWalletButton } from '../../../../components/elements/LinkWalletButton'
import { isAddress } from 'ethers/lib/utils'

type WalletActionProps = {
  onRemoveWallet: (walletId: string, isContractAccount: boolean) => void
  onBuildBlockExplorerUrl?: (address: Address) => string
  onUpdatePrimaryWallet?: (address: Address) => void
}

type WalletListProps = WalletActionProps & {
  isContractAccountList: boolean
  sortedWallets: ProfileWallet[]
  connectedWallet?: Address
}

type RenderWalletProps = WalletActionProps & {
  wallet: ProfileWallet
  connectedWallet?: Address
  sortedWallets: ProfileWallet[]
}

type WalletDisplayProps = WalletActionProps & {
  wallet: ProfileWallet
  isConnectedWallet: boolean
  isPrimaryWallet: boolean
  isRemoveDisabled: boolean
  explorerLink: string
  isContractAccount?: boolean
}

type LinkSafeAccountProps = {
  showLinkAccount: boolean
  onLinkMultiSigWallet: (address: Address) => Promise<void>
  onCheckIsContractAccount: (address: Address) => Promise<boolean>
  toggleInputField: () => void
}

const sortWallets = (
  wallets: ProfileWallet[],
  connectedWallet: string
): ProfileWallet[] => {
  const compare = (a: ProfileWallet, b: ProfileWallet): number => {
    const isAConnected =
      a?.address?.toLowerCase() === connectedWallet?.toLowerCase()
    const isBConnected =
      b?.address?.toLowerCase() === connectedWallet?.toLowerCase()

    return (
      (a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1) ||
      (isAConnected === isBConnected ? 0 : isAConnected ? -1 : 1) ||
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )
  }
  return [...wallets].sort(compare)
}

const WalletList = ({
  isContractAccountList,
  sortedWallets,
  connectedWallet,
  onRemoveWallet,
  onBuildBlockExplorerUrl,
  onUpdatePrimaryWallet,
}: WalletListProps) => {
  const wallets = sortedWallets.filter((wallet) =>
    isContractAccountList
      ? wallet.meta?.isContractAccount
      : !wallet.meta?.isContractAccount
  )

  return wallets.length === 0 ? (
    <NoAccountsLinked />
  ) : (
    wallets.map((wallet) => (
      <RenderWallet
        key={wallet.address}
        wallet={wallet}
        connectedWallet={connectedWallet}
        sortedWallets={sortedWallets}
        onRemoveWallet={onRemoveWallet}
        onUpdatePrimaryWallet={onUpdatePrimaryWallet}
        onBuildBlockExplorerUrl={onBuildBlockExplorerUrl}
      />
    ))
  )
}

const RenderWallet = ({
  wallet,
  connectedWallet,
  sortedWallets,
  onRemoveWallet,
  onBuildBlockExplorerUrl,
  onUpdatePrimaryWallet,
}: RenderWalletProps) => {
  const isConnectedWallet = wallet.address === connectedWallet?.toLowerCase()
  const isPrimaryWallet = wallet.isPrimary && sortedWallets.length >= 1
  const isRemoveDisabled =
    isConnectedWallet || sortedWallets.length <= 1 || isPrimaryWallet
  const isContractAccount = wallet.meta.isContractAccount

  return (
    <WalletDisplay
      key={wallet.address}
      wallet={wallet}
      isConnectedWallet={isConnectedWallet}
      isPrimaryWallet={isPrimaryWallet}
      isRemoveDisabled={isRemoveDisabled}
      explorerLink={
        onBuildBlockExplorerUrl ? onBuildBlockExplorerUrl(wallet?.address) : '#'
      }
      isContractAccount={isContractAccount}
      onRemoveWallet={onRemoveWallet}
      onUpdatePrimaryWallet={onUpdatePrimaryWallet}
    />
  )
}

export const WalletDisplay = ({
  wallet,
  isConnectedWallet,
  isPrimaryWallet,
  isRemoveDisabled,
  explorerLink,
  isContractAccount,
  onRemoveWallet,
  onUpdatePrimaryWallet,
}: WalletDisplayProps) => (
  <Flex
    alignSelf="stretch"
    p="12px"
    bg="gray.0"
    borderRadius="4px"
    justifyContent="space-between"
    alignItems="center"
  >
    <HStack gap="8px">
      <Flex
        px="10px"
        py="8px"
        bg="blue.1"
        borderRadius="6px"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          width="24px"
          height="24px"
          src={
            isContractAccount
              ? gnosis
              : isConnectedWallet
                ? wallet_with_green_dot_icon
                : wallet_icon
          }
        />
      </Flex>
      <HStack gap="4px">
        <Text color="gray.6" variant="body-sm/medium" wordBreak="break-word">
          {shortenAddress(wallet.address, {
            charsFront: 2,
            charsBack: 2,
            charsMiddle: 2,
          })}
        </Text>
        <Link href={explorerLink} target="_blank" isExternal>
          <Image src={arrow_up_right} w="20px" h="20px" />
        </Link>
      </HStack>
    </HStack>

    <HStack>
      {!isPrimaryWallet && (
        <Button
          variant="link"
          textColor="red.5"
          isDisabled={isRemoveDisabled}
          onClick={() =>
            onRemoveWallet(wallet.address, isContractAccount ?? false)
          }
        >
          {isRemoveDisabled ? (
            <Tooltip
              noDelay
              placement="bottom-end"
              label={
                isConnectedWallet
                  ? "You can't remove your connected wallet. Please switch to another linked wallet first."
                  : undefined
              }
            >
              Remove
            </Tooltip>
          ) : (
            'Remove'
          )}
        </Button>
      )}

      {onUpdatePrimaryWallet && !isPrimaryWallet && (
        <Button
          variant="tertiary"
          size="sm"
          textColor="blue.5"
          h={6}
          onClick={() => onUpdatePrimaryWallet(wallet.address)}
        >
          Make Primary
        </Button>
      )}

      {isPrimaryWallet && (
        <Tooltip
          noDelay
          placement="bottom-end"
          label="Your primary wallet is used to claim rewards at the end of the season."
        >
          <Badge bg="blue.5" px={1.5} py={0.5} ml={4} cursor="default">
            <Text color="blue.0" variant="body-xs/medium">
              Primary
            </Text>
          </Badge>
        </Tooltip>
      )}
    </HStack>
  </Flex>
)

const NoAccountsLinked = () => (
  <Flex
    alignSelf="stretch"
    p="12px"
    bg="gray.0"
    borderRadius="4px"
    justifyContent="space-between"
    alignItems="center"
    opacity={0.5}
  >
    <HStack gap="8px">
      <Flex
        px="10px"
        py="8px"
        bg="blue.1"
        borderRadius="6px"
        justifyContent="center"
        alignItems="center"
      >
        <Image width="24px" height="24px" src={gnosis} />
      </Flex>
      <Text color="gray.3" variant="body-sm/medium" wordBreak="break-word">
        No accounts linked
      </Text>
    </HStack>
  </Flex>
)

const LinkSafeAccount = ({
  showLinkAccount,
  toggleInputField,
  onLinkMultiSigWallet,
  onCheckIsContractAccount,
}: LinkSafeAccountProps) => {
  const [multiSigAddr, setMultiSigAddr] = useState('')
  const handleInputChange = (event: any) => {
    setMultiSigAddr(event.target.value)
  }

  const { isError, isMultisig } = useCheckMultiSig(
    multiSigAddr,
    onCheckIsContractAccount
  )

  return (
    <VStack width="full">
      <Button
        variant="link"
        onClick={toggleInputField}
        alignSelf="stretch"
        textAlign="center"
        color="blue.5"
        mx="auto"
        my={4}
      >
        <Box as="span" pr={1}>
          <FontAwesomeIcon
            icon={['far', 'chevron-down']}
            role="button"
            width="10px"
            style={{
              transform: showLinkAccount ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
              transformOrigin: 'center',
            }}
          />
        </Box>{' '}
        Link an account
      </Button>
      <HStack width="full" display={showLinkAccount ? 'flex' : 'none'} h="40px">
        <VStack flex="1" align="start" spacing={0}>
          <Input
            value={multiSigAddr}
            onChange={handleInputChange}
            variant="flushed"
            type="text"
            h="40px"
            placeholder="Enter Gnosis Safe account address"
            borderRadius={0}
            borderBottom={isError ? '1px solid' : 'blue.5'}
            borderColor={isError ? 'red.5' : 'blue.5'}
            _focus={{
              borderBottom: 'inherit',
            }}
          />
          <Box minHeight="20px">
            {isError && !isMultisig && (
              <Text color="red.5" variant="body-xs/normal">
                Please input a valid Gnosis Safe account address
              </Text>
            )}
          </Box>
        </VStack>
        <Button
          variant="link"
          isDisabled={isError || !isMultisig}
          onClick={() => {
            if (isMultisig) {
              onLinkMultiSigWallet(multiSigAddr)
            }
          }}
          textAlign="center"
          color="blue.500"
          h="40px"
        >
          Link
        </Button>
      </HStack>
    </VStack>
  )
}

export default function WalletAddresses({
  connectedWallet,
  wallets,
  onLinkExtraWallet,
  onLinkMultiSigWallet,
  onRemoveWallet,
  onCheckIsContractAccount,
  onUpdatePrimaryWallet,
}: {
  connectedWallet?: Address
  wallets?: ProfileWallet[]
  onLinkExtraWallet: (isSkipWalletAnalyzerAnimation?: boolean) => Promise<void>
  onLinkMultiSigWallet: (address: Address) => Promise<void>
  onRemoveWallet: (address: string, isContractAccount: boolean) => void
  onCheckIsContractAccount: (address: Address) => Promise<boolean>
  onUpdatePrimaryWallet: (address: Address) => void
}) {
  const { chainId } = useEthers()
  const chainConfig = useChainConfig(chainId)
  const buildBlockExplorerUrl = useCallback(
    (address: Address) => {
      if (chainConfig?.getExplorerAddressLink) {
        return chainConfig?.getExplorerAddressLink?.(address)
      } else {
        return '#'
      }
    },
    [chainConfig]
  )

  const [showLinkAccount, setShowLinkAccoun] = useState(false)

  const sortedWallets = wallets
    ? sortWallets(wallets as ProfileWallet[], connectedWallet as Address)
    : []

  const toggleInputField = () => {
    setShowLinkAccoun(!showLinkAccount)
  }

  return (
    <VStack gap="20px" alignItems="start">
      <VStack gap="8px">
        <SectionHeader
          heading={'Ethereum Wallet Addresses'}
          description={
            'You may log in with any of the wallets connected below. If you’re adding a new wallet, you’ll be prompted to sign a transaction.'
          }
        />
      </VStack>
      {/* External */}
      <VStack align="start" w="full">
        <Text variant="body-sm/medium" color="blue.9" wordBreak="break-word">
          Externally Owned Accounts
        </Text>
        <VStack gap="16px" w="full">
          <WalletList
            isContractAccountList={false}
            sortedWallets={sortedWallets}
            connectedWallet={connectedWallet}
            onRemoveWallet={onRemoveWallet}
            onBuildBlockExplorerUrl={buildBlockExplorerUrl}
            onUpdatePrimaryWallet={onUpdatePrimaryWallet}
          />
        </VStack>
        <LinkWalletButton
          variant="link"
          onClick={() => onLinkExtraWallet(true)}
          isDisabled={
            wallets &&
            wallets.some(
              (w) => w.address.toLowerCase() === connectedWallet?.toLowerCase()
            )
          }
          alignSelf="stretch"
          textAlign="center"
          color="blue.5"
          mx="auto"
          my={4}
        >
          + Link a wallet
        </LinkWalletButton>
      </VStack>
      {/* Gnosis */}
      <VStack align="start" w="full">
        <HStack>
          <Text variant="body-sm/medium" color="blue.9" wordBreak="break-word">
            Gnosis Safe Accounts
          </Text>
          <Tooltip
            noDelay
            iconColor="gray.3"
            aria-label="link-gnosis-tooltip"
            label="You can link your Gnosis Safe account here if any of your wallets above are linked to it."
          >
            <Image src={info} boxSize="16px" opacity="0.5" />
          </Tooltip>
        </HStack>
        <VStack gap="16px" w="full">
          <WalletList
            isContractAccountList={true}
            sortedWallets={sortedWallets}
            connectedWallet={connectedWallet}
            onRemoveWallet={onRemoveWallet}
            onBuildBlockExplorerUrl={buildBlockExplorerUrl}
          />
        </VStack>
        <LinkSafeAccount
          toggleInputField={toggleInputField}
          showLinkAccount={showLinkAccount}
          onLinkMultiSigWallet={onLinkMultiSigWallet}
          onCheckIsContractAccount={onCheckIsContractAccount}
        />
      </VStack>
    </VStack>
  )
}

const useCheckMultiSig = (
  multiSigAddr: string,
  onCheckIsContractAccount: (address: Address) => Promise<boolean>
) => {
  const [isError, setIsError] = useState(false)
  const [isMultisig, setIsMultisig] = useState(false)

  useEffect(() => {
    const checkMultiSig = async () => {
      if (multiSigAddr === '') {
        setIsError(false)
        setIsMultisig(false)
        return
      }

      const validAddress = isAddress(multiSigAddr)

      if (!validAddress) {
        setIsError(true)
        setIsMultisig(false)
        return
      }

      const isContract = await onCheckIsContractAccount(multiSigAddr)
      setIsMultisig(isContract)
      setIsError(!isContract)
    }

    checkMultiSig()
  }, [multiSigAddr, onCheckIsContractAccount])

  return useMemo(() => ({ isError, isMultisig }), [isError, isMultisig])
}
