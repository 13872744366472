import { HStack } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { Address } from '../../../../data/model'
import { MetaVaultData, VaultData } from '../../../../models/vaults'
import MetaVaultDepositWithdraw from '../MetaVaultDepositWithdraw'
import { DescriptionDepositWithdraw } from './DescriptionDepositWithdraw'

export default function MetaVaultDepositWithdrawWrapper({
  onConnect,
  account,
  metaVaults,
  vaults,
  convertToAssetsRatio,
  vaultAssetTokenBalance,
  vaultAssetTokenAllowances,
  receiptTokenBalancesInAssetTerms,
  assetPrices,
  onApprove,
  onDeposit,
  onWithdraw,
  onWithdrawMax,
  onKytCheck,
}: {
  account?: Address | undefined
  metaVaults: MetaVaultData[]
  vaults: VaultData[]
  convertToAssetsRatio: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | undefined
    }
  }
  vaultAssetTokenBalance?: {
    [chainId: string]: {
      [tokenAddress: string]: FixedNumber
    }
  }
  vaultAssetTokenAllowances?: {
    [chainId: string]: {
      [tokenAddress: string]: FixedNumber
    }
  }
  receiptTokenBalancesInAssetTerms?: {
    [chainId: string]: {
      [tokenAddress: string]: FixedNumber
    }
  }
  assetPrices: {
    [chainId: string]: {
      [token: string]: FixedNumber
    }
  }
  onConnect: () => void
  onApprove: (
    chainId: string,
    token: Address,
    spender: Address,
    amount: FixedNumber
  ) => Promise<void>
  onDeposit: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber
  ) => Promise<void>
  onWithdraw: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber
  ) => Promise<void>
  onWithdrawMax: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address
  ) => Promise<void>
  onKytCheck: () => Promise<boolean>
}) {
  return (
    <HStack
      bg="white"
      h="330px"
      borderRadius="8px"
      p={6}
      spacing={10}
      alignItems="flex-start"
      justifyContent="space-between"
      mt={6}
    >
      <DescriptionDepositWithdraw />
      <MetaVaultDepositWithdraw
        vaultAssetTokenBalance={vaultAssetTokenBalance}
        vaultAssetTokenAllowances={vaultAssetTokenAllowances}
        receiptTokenBalancesInAssetTerms={receiptTokenBalancesInAssetTerms}
        convertToAssetsRatio={convertToAssetsRatio}
        assetPrices={assetPrices}
        account={account}
        metaVaults={metaVaults}
        vaults={vaults}
        onConnect={onConnect}
        onApprove={onApprove}
        onDeposit={onDeposit}
        onWithdraw={onWithdraw}
        onWithdrawMax={onWithdrawMax}
        onKytCheck={onKytCheck}
      />
    </HStack>
  )
}
