import { Button, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AssetDropdown from '../../components/elements/AssetDropdown'
import { HStack } from '../../components/elements/Stack'
import { useSafary } from '../../data/analytics/use-safary'
import { formatFixedToken } from '../../helpers/utils'
import { LendPageParams } from '../../models/lend'
import { useChainConfigs, useConfig } from '../../providers/config'
import LendBox from './elements/LendBox'
import LendPageLoading from './elements/LendPageLoading'
import LendSuccessModal from './elements/LendSuccessModal'
import LendTable from './elements/LendTable'
import { NetworkButton } from './elements/NetworkButton'
import Subheading from './elements/Subheading'
import WhatIsEarnModal from './elements/WhatIsEarnModal'

export default function LendPage({
  account,
  availableCurrencies = {},
  purchaseTokenAllowance,
  selectedPurchaseToken,
  purchaseTokenBalance,
  listingRepos,
  selectedTermRepo,
  isPurchasingMaxAvailable,
  onChangeLendAmount,
  onChangePurchaseToken,
  onSelectActiveListingRepo,
  onLend,
  onMax,
  onConnect,
  onKytCheck,
  onPurchaseTokenApprove,
  onAddTermToken,
}: LendPageParams) {
  const config = useConfig()
  const chainConfigs = useChainConfigs()

  const { trackSafaryEvent } = useSafary()

  const [selectedChainId, setSelectedChainId] = useState<string>('')

  const [isTriggerHoverEffect, setIsTriggerHoverEffect] =
    useState<boolean>(false)

  const handleNetworkFilter = (chainId: string) => {
    setSelectedChainId((prevActiveNetwork) =>
      prevActiveNetwork === chainId ? '' : chainId
    )
  }

  const navigate = useNavigate()
  const {
    isOpen: isLendSuccessModalOpen,
    onOpen: onLendSuccessModalOpen,
    onClose: onCloseLendSuccessModal,
  } = useDisclosure()

  const {
    isOpen: isWhatIsEarnModalOpen,
    onOpen: onWhatIsEarnModalOpen,
    onClose: onCloseWhatIsEarnModal,
  } = useDisclosure()

  if (
    listingRepos === undefined ||
    availableCurrencies === undefined ||
    purchaseTokenAllowance === undefined ||
    purchaseTokenBalance === undefined ||
    onAddTermToken === undefined
  ) {
    return (
      <LendPageLoading
        selectedPurchaseToken={selectedPurchaseToken}
        onConnect={onConnect}
      />
    )
  }

  return (
    <>
      <Flex
        flexDir="row"
        pt="30px"
        width={{
          base: '996px',
          '1xl': '1293px',
          '3xl': '1520px',
        }}
        justify="space-between"
        mx="auto"
      >
        <Flex
          flexDir="column"
          width={{
            base: '654px',
            '1xl': '816px',
            '3xl': '1023px',
          }}
        >
          <Flex flexDir="column" px={3}>
            <HStack spacing={3}>
              <Text
                fontSize="40px"
                variant="body-3xl/bold"
                mb={3}
                ml="-2px"
                display="flex"
              >
                Earn
              </Text>
              <AssetDropdown
                currencies={availableCurrencies}
                selectedPurchaseToken={selectedPurchaseToken}
                onChangePurchaseToken={(token: string) => {
                  onChangePurchaseToken(token)
                  onSelectActiveListingRepo(undefined)
                }}
              />
              <Spacer />
              <Button
                size="md"
                variant="primaryFlat"
                onClick={() => {
                  onWhatIsEarnModalOpen()
                  trackSafaryEvent(
                    config?.safary?.earn?.whatIsEarn?.type ?? 'click',
                    'what is earn',
                    {}
                  )
                }}
              >
                What is Earn?
              </Button>
            </HStack>
            <HStack mt={1} mb={6} h="44px">
              <Subheading />
              <HStack ml="auto" spacing={3}>
                {chainConfigs?.length > 1 &&
                  chainConfigs.map(({ chainName, chainId }) => (
                    <NetworkButton
                      key={chainName}
                      chainName={chainName}
                      chainId={chainId.toString()}
                      selectedChainId={selectedChainId}
                      handleNetworkFilter={handleNetworkFilter}
                    />
                  ))}
              </HStack>
            </HStack>
          </Flex>
          <LendTable
            selectedPurchaseToken={selectedPurchaseToken ?? ''}
            lendData={listingRepos ?? []}
            selectedChainId={selectedChainId}
            setActiveLendItem={onSelectActiveListingRepo}
            isTriggerHoverEffect={isTriggerHoverEffect}
          />
        </Flex>
        <Flex
          flexDir="column"
          pt={12}
          width={{
            base: '330px',
            '1xl': '397px',
          }}
        >
          <LendBox
            key={selectedTermRepo?.purchaseTokenMeta?.symbol}
            tokenSymbol={selectedPurchaseToken ?? ''}
            tokenDecimals={selectedTermRepo?.purchaseTokenMeta?.decimals ?? 6}
            externalProjects={selectedTermRepo?.externalProjects}
            connected={!!account}
            selectedApy={selectedTermRepo?.termRepoToken ? true : false}
            discountRateMarkup={
              selectedTermRepo?.discountRateMarkup ??
              FixedNumber.fromString('0')
            }
            auctionClearingRate={
              selectedTermRepo?.lastAuctionClearingPrice ??
              FixedNumber.fromString('0')
            }
            termPointsMultiplier={selectedTermRepo?.termPointsMultiplier}
            apy={selectedTermRepo?.lendApy ?? FixedNumber.fromString('0')}
            lendAmountUsd={
              selectedTermRepo?.lendAmountUsd ?? FixedNumber.fromString('0')
            }
            amountToReceive={
              selectedTermRepo?.repoTokensReceived ??
              FixedNumber.fromString('0')
            }
            availableLiquidity={
              selectedTermRepo?.remainingAmount || FixedNumber.fromString('0')
            }
            walletBalance={purchaseTokenBalance}
            redemptionTimestamp={selectedTermRepo?.redemptionTimestamp || 0}
            value={selectedTermRepo?.lendAmountRaw ?? ''}
            onChange={function (value: string): void {
              onChangeLendAmount(value)
            }}
            onConnectModalOpen={onConnect}
            onLend={async (purchaseAmount: string) => {
              await onLend(purchaseAmount)
              onLendSuccessModalOpen()
            }}
            onTriggerHoverEffect={() => {
              setIsTriggerHoverEffect(true)
              // Reset the state after a short delay
              setTimeout(() => setIsTriggerHoverEffect(false), 100)
            }}
            isPurchasingMax={isPurchasingMaxAvailable}
            onMax={onMax}
            onKytCheck={onKytCheck}
            onApprovePurchaseToken={onPurchaseTokenApprove}
            purchaseTokenAllowance={purchaseTokenAllowance}
          />
        </Flex>
      </Flex>
      <LendSuccessModal
        isOpen={isLendSuccessModalOpen}
        onClose={() => {
          onCloseLendSuccessModal()
          onSelectActiveListingRepo(undefined)
        }}
        lendAmount={
          selectedTermRepo?.purchaseTokenMeta?.symbol &&
          selectedTermRepo?.repoTokensReceived
            ? formatFixedToken(
                selectedTermRepo.repoTokensReceived,
                selectedTermRepo.purchaseTokenMeta.symbol,
                true
              )
            : ''
        }
        redemptionTimestamp={selectedTermRepo?.redemptionTimestamp || 0}
        onAddRepoTokenToWallet={async () => {
          if (selectedTermRepo) {
            onAddTermToken(
              selectedTermRepo?.chainId,
              selectedTermRepo?.termRepoToken,
              selectedTermRepo?.purchaseTokenMeta?.symbol,
              selectedTermRepo?.purchaseTokenMeta?.decimals
            )
          }
        }}
        onNavigateToPortfolio={() => navigate('/portfolio/positions')}
      />
      <WhatIsEarnModal
        isWalletConnected={!!account}
        isOpen={isWhatIsEarnModalOpen}
        onClose={onCloseWhatIsEarnModal}
        onConnect={onConnect}
      />
    </>
  )
}
