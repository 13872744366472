import { BigNumber, FixedNumber } from 'ethers'
// TODO: Remove this import once we have the interface below mapped correctly
import { TermRepoRolloverElectionSubmissionStruct } from '../abi-generated/abi/v0.2.4/TermRepoRolloverManager'

/**
 * Contract or account address.
 */
export type Address = string

export type Wallet = Connected | NotConnected
export interface Connected {
  status: 'connected'
  accountShort: string
}
export interface NotConnected {
  status: 'not-connected'
  onConnectMetamask: () => void
  onConnectWalletconnect: () => void
  onConnectCoinbase: () => void
}

export type ExternalProject =
  | 'renzo'
  | 'etherfi'
  | 'ethena'
  | 'elixir'
  | 'treehouse'
  | 'lombard'
  | 'superform'

export type AuctionStatus = 'live' | 'upcoming' | 'clearing' | 'closed'
export type LoanStatus =
  | 'open'
  | 'matured'
  | 'partLiquidatedAndMatured'
  | 'liquidatedAndClosed'
  | 'liquidatedAndOpen'
export type TermBucket =
  | 'one'
  | 'two'
  | 'four'
  | 'six'
  | 'eight'
  | 'thirteen'
  | 'twenty-six'
  | 'fifty-two'

export enum LoanStatusEnum {
  Open = 'open',
  Matured = 'matured',
  LiquidatedAndClosed = 'liquidatedAndClosed',
  LiquidatedAndOpen = 'liquidatedAndOpen',
  PartLiquidatedAndMatured = 'partLiquidatedAndMatured',
}

/**
 * Balance (token)
 */
export interface Balance {
  // Address of the wallet.
  address: Address

  // E.g. USDC or ETH.
  symbol: string

  // Number of decimals the BigNumber must be shifted to the left to get the true value.
  decimals: number

  // Balance of the wallet
  balance: FixedNumber
}

/**
 * Native Token Currency Info
 */

export interface NativeCurrency {
  // Native Chain Token Symbol
  symbol: string

  // Decimals for this chain gas token
  decimals: number

  // Wrapped Symbol For Native Token
  wrappedGasSymbol: string

  // Wrapping Conversion Factor
  wrapConversionFactor: number
}

/**
 * Currency (token).
 */
export type Currency = ERC20Currency | TermRepoCurrency

/**
 * Standard ERC20 Currency
 */
export interface ERC20Currency {
  // Address of the token.
  address: Address

  // E.g. USDC or ETH.
  symbol: string

  // Number of decimals the BigNumber must be shifted to the left to get the true value.
  decimals: number

  // Is the currency a term repo token?
  isRepoToken: boolean
}

/**
 * Extended ER20 Currency to store additional data for Term Repo Tokens
 */
export interface TermRepoCurrency extends ERC20Currency {
  // Redemption Timestamp
  redemptionTimestamp: number

  // Purchase Token
  purchaseToken: Address

  // Collateral Token(s)
  // TODO (matt): allow array here later
  // collateralToken: Address[]
  collateralToken?: Address

  // Maintenance Collateral Ratio(s)
  // maintenanceCollateralRatios: FixedNumber[]
  maintenanceCollateralRatio?: FixedNumber
}

/**
 * Price of a token.
 */
export interface Price {
  // Address of the token.
  token: Address

  // Price of the token.
  price: BigNumber

  // Number of decimals the BigNumber must be shifted to the left to get the true value.
  decimals: number
}

/**
 * An amount of value in some currency.
 */
export interface Amount {
  // Address of the token this amount is measured in.
  currency: Address

  // Value x 10^{Currency.decimals}.
  shiftedValue: BigNumber
}

/**
 * Data about an auction.
 *
 * Subsets of this data can be passed down to components to be used as necessary.
 */
export interface Auction {
  // Chain this auction exists on
  chainId: string
  // Version of the auction contract.
  version: string

  // Address of the TermAuction contract.
  address: Address

  // Address of the CollateralManager contract.
  collateralManagerAddress: Address

  // Address of the BidLocker contract.
  bidLockerAddress: Address

  // Address of the OfferLocker contract.
  offerLockerAddress: Address

  // Address of the LoanManager contract.
  loanManagerAddress: Address

  // Address of the TermRepoLocker contract.
  repoLockerAddress: Address

  // Unix Timestamp
  auctionStartTimestamp: number

  // Unix Timestamp
  auctionRevealTimestamp: number

  // Unix Timestamp
  auctionEndTimestamp: number

  // Unix Timestamp
  maturityTimestamp: number

  // Address of the currency for the loan.
  purchaseCurrency: Address

  // Decimal places for the loan currency.
  purchaseCurrencyDecimals: number

  // Latest price of the loan token in USDC
  purchaseCurrencyOraclePriceUSDC: BigNumber

  // Price decimals of purchase currency
  purchaseCurrencyOraclePriceDecimals: number

  // Address for the currency of the collateral.
  collateralCurrency: Address

  // Decimal places for the collateral currency.
  collateralCurrencyDecimals: number

  // Liquidated Damages for Colalteral Token
  collateralLiquidatedDamages: BigNumber

  // Latest price of the collateral token
  collateralCurrencyOraclePriceUSDC: BigNumber

  // Price decimals of the collateral token
  collateralCurrencyOraclePriceDecimals: number

  // Plain number (e.g. 1.5 = 150%).
  initialMarginRatio: FixedNumber

  // Plain number (e.g. 1.5 = 150%).
  maintenanceMarginRatio: FixedNumber

  termId: string
  termRepoTokenAddress: Address
  auctionId: string

  closed: boolean
  cancelled: boolean
  cancelledForWithdrawal: boolean

  auctionClearingRate: BigNumber

  maxBidPrice: BigNumber
  minBidAmount: BigNumber
  maxOfferPrice: BigNumber
  minOfferAmount: BigNumber

  dayCountFractionMantissa?: BigNumber
  servicingFee: BigNumber

  bombPotAuction?: boolean
  bombPotAmount?: number
  bombPotRewardTokenSymbol?: string

  // external points
  project?: ExternalProject
}

export interface AuctionActivityData {
  bidsCount: number
  offersCount: number
  bidsAmount: BigNumber
  offersAmount: BigNumber

  accountBidsCount?: number
  accountOffersCount?: number
  accountBidsAmount?: BigNumber
  accountOffersAmount?: BigNumber
}

export type RolloverAuctionInfo = {
  chainId: string
  id: string
  version: string
  newTermId: string
  newTermRepoServicer: string
  oldTermId: string
  auction: Address
  auctionBidLocker: Address
  auctionOfferLocker: Address
  rolloverManagerAddress: Address
  maturityTimestamp: number
  endOfRepurchaseWindowTimestamp: number
  auctionEndTimestamp: number
  auctionComplete: boolean

  marginRequirement: FixedNumber
  servicingFee: BigNumber

  purchaseCurrencySymbol: string
  collateralCurrencySymbol: string
}

export type ParsedValueError =
  | 'invalid'
  | 'too-small'
  | 'too-large'
  | 'empty'
  | 'too-many-decimals'
  | 'over-max'
export interface ParsedValue<T> {
  isValid: boolean
  value: T
  error?: ParsedValueError
}

export type TenderEditObject = {
  id: string
  prevSupply: number
  newSupply: number
}

export type TenderId = string

export interface DraftLoanTender {
  type: 'loan'
  amount: string
  amountError?: string
  interestRate: string
  interestRateError?: string
}
export interface ParsedLoanTender {
  isValid: boolean
  type: 'loan'
  amount: ParsedValue<BigNumber>
  interestRate: ParsedValue<FixedNumber>
}
export interface ValidatedLoanTender {
  type: 'loan'
  id?: TenderId
  amount: Amount
  interestRate?: FixedNumber
}

export type SubmittedTender = SubmittedLoanTender | SubmittedBorrowTender

export interface SubmittedLoanTender extends ValidatedLoanTender {
  submittedDate: number
  chainId: string
  auction: Address
  lender: Address
  id: TenderId
  transaction: Address
  nonce: BigNumber
}

export interface DraftBorrowTender {
  type: 'borrow'
  amount: string
  interestRate: string
  collateral: string
}
export interface ParsedBorrowTender {
  isValid: boolean
  type: 'borrow'
  amount: ParsedValue<BigNumber>
  interestRate: ParsedValue<FixedNumber>
  collateral: ParsedValue<BigNumber>
}
export interface ValidatedBorrowTender {
  type: 'borrow'
  id?: TenderId
  amount: Amount
  interestRate?: FixedNumber
  collateral: Amount
}
export interface SubmittedBorrowTender extends ValidatedBorrowTender {
  submittedDate: number
  chainId: string
  auction: Address
  id: TenderId
  borrower: Address
  transaction: Address
  nonce: BigNumber
}

export interface SubmittedRollover {
  chainId: string
  id: string
  borrower: Address
  interestRate: FixedNumber
}

export interface TermLoan {
  id: string
  chainId: string
  version: string
  auctionId: string
  termId: string
  auction: Address
  auctionBidLocker: Address
  auctionOfferLocker: Address
  auctionEndTimestamp: number
  auctionClearingRate: BigNumber
  maturityTimestamp: number
  redemptionTimestamp: number

  repoServicerAddress: Address

  redemptionAmount: BigNumber
  // TODO: legacy loan side rollover, speak with Dion before removing this
  rolloverAmount: BigNumber
  purchasePrice: BigNumber
  repurchasePrice: BigNumber

  collateralCurrency: Address
  purchaseCurrency: Address
  termTokenCurrency: Address

  termTokenBalance: FixedNumber
}

export interface TermBorrowRollover {
  auction: Address

  rolloverAmount: BigNumber
}

export interface TermBorrow {
  id: string
  chainId: string
  termId: string
  version: string

  maturityTimestamp: number
  repaymentTimestamp: number
  redemptionTimestamp: number

  repoCollateralManager: Address
  repoRolloverManager: Address
  repoServicer: Address
  repoLocker: Address

  purchasePrice: FixedNumber
  repurchasePrice: FixedNumber
  borrowBalance: FixedNumber
  amountCollected: FixedNumber
  amountLiquidated: FixedNumber
  collateralBalances: { [collateralTokenAddress: string]: FixedNumber }

  rolloverAmount?: FixedNumber
  rolloverFulfilledAmount?: FixedNumber
  rolloverFulfilled?: boolean
  rolloverLocked?: boolean
  rolloverCancelled?: boolean
  rolloverAuctionBidLocker?: Address
  rolloverInterestRate?: FixedNumber

  maintenanceMarginRatio: FixedNumber
  lastLiquidationTxHash?: string
  liquidations?: LiquidationForDisplay[]

  collateralCurrency: Address
  purchaseCurrency: Address
  termTokenCurrency: Address
}

export interface TermPeriod {
  chainId: string
  id: string
  version: string

  repoServicerAddress: Address
  rolloverManagerAddress: Address
  collateralManagerAddress: string
  termRepoLockerAddress: string
  termRepoTokenAddress: string
  maturityTimestamp: number
  redemptionTimestamp: number
  endOfRepaymentWindowTimestamp: number
  purchaseCurrency: Currency
  collateralCurrency: Currency
  termRepoTokenCurrency: Currency

  completedAuctions?: Partial<Auction>[]
}

export interface ContractAddressesGrouping {
  auction: Address
  auctionBidLocker: Address
  auctionOfferLocker: Address
  termRepoLocker: Address
  termRepoServicer: Address
  termCollateralManager: Address
  termRolloverManager: Address
  termRepoToken: Address
}

// TODO: temporary alias, map this correctly in hook
export type ElectRollover = TermRepoRolloverElectionSubmissionStruct & {
  chainId: string
  nonce: BigNumber
  termId: string
  auctionId: string
  revealedPrice: FixedNumber
}

export type RevealTenderRequest = RevealTenderStruct

export type RevealTenderStruct = {
  id: string
  termId: string
  auctionId: string
  price: string
  nonce: string
  state: string
}

export type LoadMissingPricesRequest = {
  signature: string
  account: Address
  bids: string[]
  offers: string[]
}

export type LoadMissingPricesResponse = {
  status: string
  bids: RevealTenderStruct[]
  offers: RevealTenderStruct[]
}

export interface DraftLoanEdit {
  amount: string
  interestRate: string
  amountError?: string
  globalAmountError?: string
  interestRateError?: string
}

export interface DraftBorrowEdit {
  amount: string
  interestRate: string
  collateral: string
  amountError?: string
  interestRateError?: string
  collateralError?: string
  globalCollateralError?: string
}

export interface AppraiseWalletResponse {
  address: string
  allowed: boolean
  reason: string
}

export type LiquidationForDisplay = {
  timestamp: number
  transactionHash: string
  collateralTokensLiquidated: FixedNumber
  borrowTokenSymbol: string
  collateralTokenSymbol: string
  loanCovered: FixedNumber
  loanRemaining: FixedNumber
  defaultLiquidation: boolean
}

export interface BlockNumberMetadata {
  _meta: {
    block: {
      number: number
      hash: string
      timestamp: number
    }
    hasIndexingErrors: boolean
  }
}

export interface Lend {
  chainId: string
  lendId: string
  fixedApy: FixedNumber
  maturityDate: number
  availableAmount: FixedNumber
  risk: string
  collateralCurrency: string
  collateralRequirement: FixedNumber
  collateralHealth: number
  totalDebt: number
  totalCollateral: number
  listingAddress: string
  repoLocker: string
  auctionClearingRate: FixedNumber
  lendRewards: number
  pointsRewards: string
}

export interface BombPotReward {
  totalAssignedAmount: number
  totalAmountCleared: number
  termInDays: number
  purchaseToken: string
  termRepoToken: string
  rewardToken: string
  purchaseTokenPrice: number
  rewardTokenPrice: number
  impliedApy: number
  totalReward: number
  userReward: number
  userRewardUsd: number
}

interface OAuthCallbackBase {
  status?: 'success' | 'failure'
  action?: string
  // keep track of the inviter's name and image
  refName?: string
  refImage?: string
}

export interface TwitterOAuthCallback extends OAuthCallbackBase {
  code?: string
  verifier: string
}

export interface DiscordOAuthCallback extends OAuthCallbackBase {
  token?: string
  hasSkippedTwitter?: boolean
}

export interface ProfileSocials {
  twitter?: string
  discord?: string
}

export interface ProfileWallet {
  address: string
  meta: {
    isContractAccount?: boolean
  }
  isPrimary: boolean
  createdAt: string
}

export interface SuggestedRates {
  url?: string
  lastAuctionRate?: string
  supplyRate?: string
  borrowRate?: string
  averageRate?: string
  supplyRate7d?: string
  borrowRate7d?: string
  averageRate7d?: string
  supplyRate30d?: string
  borrowRate30d?: string
  averageRate30d?: string
}

export interface SuggestedRatesByPlatform {
  [platform: string]: SuggestedRates
}

export interface ListingsData {
  id: string
  chainId: string
  remainingAmount: FixedNumber
  termRepoServicer: Address
  termRepoLocker: Address
  termRepoToken: Address
  purchaseToken: Address
  purchaseTokenMeta: {
    address: Address
    symbol: string
    decimals: number
  }
  repurchaseTimestamp: number
  redemptionTimestamp: number
  collateralTokens: Address[]
  collateralTokensMeta: {
    address: Address
    symbol: string
    decimals: number
  }[]
  termPointsMultiplier: number
  risk: string
  collateralRatios: {
    collateralToken: Address
    maintenanceRatio: FixedNumber
  }[]
  lastAuctionEndTime: number
  lastAuctionClearingPrice: FixedNumber
  lendRewards?: number
  pointsRewards?: string
  totalDebt?: FixedNumber
  totalDebtUsd?: FixedNumber
  totalCollateral?: FixedNumber
  totalCollateralUsd?: FixedNumber
  lendApy?: FixedNumber
  discountRateMarkup?: FixedNumber
  externalProjects?: {
    project: ExternalProject
    label: string
    pointsMultiplier: number
  }[]
  isEarlyRepay?: boolean
}

export interface ActiveListingRepo extends ListingsData {
  lendAmountRaw: string
  lendAmountUsd: FixedNumber
  repoTokensReceived: FixedNumber
}

export interface MappedLendCurrencies {
  [symbol: string]: {
    [chainId: number]: Address
  }
}

export interface ListingsTransaction {
  id: string
  listingAmount: FixedNumber
  remainingAmount: FixedNumber
  soldAmount: FixedNumber
  soldCostPurchaseCurrency: FixedNumber
  cancelled: boolean
  timestamp: number
  transactionHash: string
}

export interface MappedListings {
  totalAmountSold: FixedNumber
  totalRemainingAmount: FixedNumber
  purchaseToken: Address
  totalCost: FixedNumber
  transactions: ListingsTransaction[]
}

export interface MappedPurchases {
  totalAmountPurchased: FixedNumber
  totalCost: FixedNumber
  purchaseToken: Address
  transactions: {
    id: string
    seller: Address
    amount: FixedNumber
    cost: FixedNumber
    timestamp: number
    transactionHash: string
  }[]
}

export interface TransformedListingsData {
  listings?: MappedListings
  purchases?: MappedPurchases
}

export interface MappedYieldResult {
  [chainId: string]: {
    [strategyAddress: Address]: FixedNumber | undefined | null
  }
}

export interface VaultPricePerShareMap {
  [strategyAddress: string]: FixedNumber | null | undefined
}

export interface MetaVaultStrategyHoldings {
  // Holding in asset currency
  [strategyAddress: Address]: FixedNumber
}

export interface MetaVaultStrategyAllocations {
  // strategy allocation in percentage
  [strategyAddress: Address]: {
    vaultName: string
    ratio: number
    balance: FixedNumber
  }
}

export interface VaultSummaryItem {
  text: [string, string]
  start: string
  end: string
  warning?: boolean
}
