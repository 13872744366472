import {
  Box,
  Text,
  Th,
  HStack,
  Button,
  useDisclosure,
  Input,
  Link,
} from '@chakra-ui/react'
import { externalLinks } from '../../../../links'
import {
  Table,
  TableBody,
  TableHead,
  Tr,
  Td,
} from '../../../../components/elements/Table'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { FixedNumber } from 'ethers'
import {
  formatFixedPercentage,
  parseUserInput,
} from '../../../../helpers/utils'
import { useState } from 'react'
import EditCollateralModal from '../VaultModal/EditCollateralModal'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import RemoveCollateralModal from '../VaultModal/RemoveCollateralModal'
import { EditCollateral } from './EditCollateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Address } from '../../../../data/model'
import { isAddress } from 'ethers/lib/utils'
import { CollateralTokenPairing } from '../../../../models/vault'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import VaultCollateralPopout from './VaultCollateralPopout'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { multiply } from '../../../../helpers/math'

type Props = {
  isGovernor?: boolean
  collateralTokens: CollateralTokenPairing[]
  supportedCollateralTokens: Address[]
  onSetCollateralTokenParams: (
    address: Address,
    minMaintenanceMargin: FixedNumber
  ) => Promise<void>
}

export default function VaultCollateral({
  collateralTokens,
  isGovernor,
  supportedCollateralTokens,
  onSetCollateralTokenParams,
}: Props) {
  const {
    isOpen: isEditCollateralModalOpen,
    onOpen: onEditCollateralModalOpen,
    onClose: onEditCollateralModalClose,
  } = useDisclosure()

  const {
    isOpen: isRemoveCollateralModalOpen,
    onOpen: onRemoveCollateralModalOpen,
    onClose: onRemoveCollateralModalClose,
  } = useDisclosure()

  const [isUpdateRatio, setUpdateRatio] = useState(false)
  const [isAddCollateral, setIsAddCollateral] = useState(false)
  const [collateralTokenSelected, setCollateralTokenSelected] =
    useState<CollateralTokenPairing | null>()
  const [valueRatio, setValueRatio] = useState('')
  const [valueContractAddress, setValueContractAddress] = useState('')
  const [isContractAddressError, setIsContractAddressError] = useState(false)
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)

  const handleOnChange = (value: string) => {
    setValueRatio(parseUserInput(value, true, INTEREST_RATE_DECIMALS))
  }

  const handleCancel = () => {
    setCollateralTokenSelected(null)
    setUpdateRatio(false)
  }

  const handleSave = () => {
    const oldValueRatio =
      Number(collateralTokenSelected?.minMaintenanceMargin) * 100
    if (oldValueRatio === Number(valueRatio)) {
      handleCancel()
      return
    }
    onEditCollateralModalOpen()
  }

  const handleConfirmEdit = async () => {
    if (collateralTokenSelected) {
      await onSetCollateralTokenParams(
        collateralTokenSelected.address,
        FixedNumber.fromString(valueRatio, 18)
      )
    }
    onEditCollateralModalClose()
    handleCancel()
  }

  const handleRemove = async () => {
    if (collateralTokenSelected) {
      await onSetCollateralTokenParams(
        collateralTokenSelected.address,
        FixedNumber.fromString('0', 18)
      )
    }
    handleCancel()
    onRemoveCollateralModalClose()
  }

  const handleAddCollateral = async () => {
    if (valueContractAddress !== '' && !isContractAddressError && !submitting) {
      setSubmitting(true)
      termToast.pending(termToastMessages.onSetCollateralTokenParams.pending())
      try {
        await onSetCollateralTokenParams(
          valueContractAddress,
          FixedNumber.fromString(valueRatio, 18)
        )
        termToast.success(
          termToastMessages.onSetCollateralTokenParams.success()
        )
      } catch (error) {
        if ((error as Error).message.includes('user rejected transaction')) {
          termToast.dismissed()
        } else {
          termToast.failure(
            termToastMessages.onSetCollateralTokenParams.failure()
          )
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <HStack alignItems="start" justify="space-between" gap="40px">
      <Box w="calc(100% / 3)">
        <Text variant="body-l/semibold" color="blue.9">
          Eligible Collateral
        </Text>
        <Text variant="body-md/normal" color="gray.6" mt="4px">
          In addition to the collateral assets currently backing your vault,
          there are additional tokens which could be used in the future. The
          vault curator is responsible for determining which collateral assets
          are accepted in this vault. You can read about oracle details in{' '}
          <Link href={externalLinks.priceFeedDocs} isExternal>
            <Text as="span" textDecoration="underline" color="gray.6">
              our docs
            </Text>
          </Link>
          .
        </Text>
      </Box>
      <Box w="calc(200% / 3)" mt={1}>
        <Table noPadding variant="striped-even">
          <TableHead>
            <Tr>
              <Th
                w="193px"
                borderBottom="1px solid rgba(0, 16, 39, 0.10)"
                padding="4px 8px"
              >
                <Box
                  flexGrow={1}
                  h="full"
                  display="flex"
                  alignItems="center"
                  whiteSpace="nowrap"
                >
                  <Text textTransform="none" variant="body-xs/medium">
                    Collateral
                  </Text>
                  <ChevronDownIcon w="16px" h="16px" color="gray.6" />
                </Box>
              </Th>
              <Th
                w="full"
                borderBottom="1px solid rgba(0, 16, 39, 0.10)"
                padding="4px 8px"
              >
                <Box
                  flexGrow={1}
                  h="full"
                  display="flex"
                  alignItems="center"
                  whiteSpace="nowrap"
                  justifyContent="end"
                >
                  <Text textTransform="none" variant="body-xs/medium">
                    Min. Maint. Ratio
                  </Text>
                  <ChevronDownIcon w="16px" h="16px" color="gray.6" />
                </Box>
              </Th>
            </Tr>
          </TableHead>
          <TableBody>
            {collateralTokens.map(
              ({ address, symbol, minMaintenanceMargin }) => (
                <Tr key={address} h="80px">
                  <Td>
                    <TokenIconLabel token={symbol} isSmall columnGap="8px" />
                  </Td>
                  <Td textAlign="end">
                    {!isGovernor && (
                      <HStack gap={1} justifyContent="flex-end">
                        <Text variant="body-sm/normal" color="blue.9">
                          {formatFixedPercentage(minMaintenanceMargin)}
                        </Text>
                      </HStack>
                    )}
                    {isGovernor &&
                      (isUpdateRatio
                        ? collateralTokenSelected?.symbol !== symbol
                        : true) && (
                        <HStack gap={1} justifyContent="end">
                          <Text variant="body-sm/normal" color="blue.9">
                            {formatFixedPercentage(minMaintenanceMargin)}
                          </Text>
                          <VaultCollateralPopout
                            onEditCollateralRatio={() => {
                              setIsAddCollateral(false)
                              setUpdateRatio(true)
                              setCollateralTokenSelected({
                                address,
                                symbol,
                                minMaintenanceMargin,
                              })
                              setValueRatio(
                                multiply(
                                  minMaintenanceMargin,
                                  FixedNumber.fromString('100')
                                ).toString()
                              )
                            }}
                            onRemoveCollateralFromVault={() => {
                              setCollateralTokenSelected({
                                address,
                                symbol,
                                minMaintenanceMargin,
                              })
                              onRemoveCollateralModalOpen()
                            }}
                          />
                        </HStack>
                      )}
                    {isGovernor &&
                      isUpdateRatio &&
                      collateralTokenSelected?.symbol === symbol && (
                        <EditCollateral
                          isDisabled={false}
                          valueRatio={valueRatio}
                          handleOnChange={handleOnChange}
                          handleSave={handleSave}
                          handleCancel={handleCancel}
                        />
                      )}
                  </Td>
                </Tr>
              )
            )}

            {/* Add Collateral */}
            {isGovernor && isAddCollateral && (
              <Tr bg="white !important">
                <Td w="50%" pt={3}>
                  <Input
                    variant="body-sm/normal !important"
                    placeholder="Enter the contract address"
                    border="none"
                    borderBottom="1px solid"
                    borderBottomColor={
                      isContractAddressError ? 'red.5' : 'blue.500'
                    }
                    cursor="text"
                    _focus={{
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    h="auto"
                    color="blue.9"
                    borderRadius={0}
                    padding={1.5}
                    value={valueContractAddress}
                    onChange={(e) => {
                      const address = e.target.value
                      setIsContractAddressError(false)
                      if (address !== '' && !isAddress(address)) {
                        setIsContractAddressError(true)
                      }
                      // if (
                      //   address !== '' &&
                      //   isAddress(address) &&
                      //   !supportedCollateralTokens.includes(address)
                      // ) {
                      //   setIsContractAddressError(true)
                      // }
                      setValueContractAddress(address)
                    }}
                  />
                </Td>
                <Td textAlign="end" pt={3}>
                  <EditCollateral
                    isDisabled={
                      valueContractAddress === '' ||
                      isContractAddressError ||
                      Number(valueRatio) === 0
                    }
                    valueRatio={valueRatio}
                    handleOnChange={handleOnChange}
                    handleSave={handleAddCollateral}
                    handleCancel={() => {
                      setValueRatio('')
                      setValueContractAddress('')
                      setIsAddCollateral(false)
                    }}
                  />
                </Td>
              </Tr>
            )}

            {/* Button Add Collateral */}
            {isGovernor && (
              <Tr bg="white !important">
                <Td colSpan={2} w="100%">
                  <Button
                    isDisabled={isAddCollateral}
                    variant="body-md/semibold"
                    color="gray.6"
                    bg="white"
                    border="none"
                    _hover={{ bg: 'white' }}
                    _disabled={{
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    }}
                    leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
                    onClick={() => {
                      setUpdateRatio(false)
                      setValueRatio('')
                      setIsAddCollateral(true)
                    }}
                  >
                    New
                  </Button>
                </Td>
              </Tr>
            )}
          </TableBody>
        </Table>
      </Box>
      {collateralTokenSelected && (
        <EditCollateralModal
          isOpen={isEditCollateralModalOpen}
          onClose={onEditCollateralModalClose}
          collateralTokenSelected={collateralTokenSelected}
          valueRatio={FixedNumber.from(valueRatio || 0)}
          onEditCollateral={handleConfirmEdit}
        />
      )}
      {collateralTokenSelected && (
        <RemoveCollateralModal
          isOpen={isRemoveCollateralModalOpen}
          onClose={onRemoveCollateralModalClose}
          token={collateralTokenSelected.symbol}
          onRemoveCollateral={handleRemove}
        />
      )}
    </HStack>
  )
}
