import { useState, useEffect } from 'react'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import {
  AssetVaultMapping,
  SingleStrategyExternalMapping,
  VaultsMapping,
} from '../../models/vaults'
import { Address } from '../model'

const VAULT_MAPPINGS_URL: string =
  'https://term-finance-vault-mappings.s3.us-west-1.amazonaws.com/vaultMappings.json'

export function useVaultsMappings(): VaultsMapping | undefined {
  const { data } = useJsonRestCalls<{ '#call': RequestInfo }, VaultsMapping>({
    '#call': {
      url: VAULT_MAPPINGS_URL,
      method: 'GET',
    } as RequestInfo,
  })

  const [vaultsMapping, setVaultsMapping] = useState<
    VaultsMapping | undefined
  >()

  useEffect(() => {
    if (data) {
      const trimmedData: VaultsMapping = {
        vaultDetails: {},
        metaVaultTargets: {},
        assetVaults: {},
      }

      Object.entries(data.vaultDetails).forEach(([chainId, vaultDetails]) => {
        const trimmedVaultDetails: SingleStrategyExternalMapping = {}

        for (const [vaultAddress, details] of Object.entries(vaultDetails)) {
          trimmedVaultDetails[vaultAddress.toLowerCase()] = {
            ...details,
            vaultName: details?.vaultName.trim(),
            curatorName: details?.curatorName.trim(),
            icon: details?.icon?.trim(),
            links: {
              twitter: details?.links?.twitter?.trim(),
              website: details?.links?.website?.trim(),
              linkedIn: details?.links?.linkedIn?.trim(),
            },
            about: {
              curator: details?.about?.curator?.trim(),
              strategy: details?.about?.strategy?.trim(),
            },
          }
        }

        trimmedData.vaultDetails[chainId] = trimmedVaultDetails
      })

      Object.entries(data.metaVaultTargets).forEach(([chainId, metaVaults]) => {
        const normalizedMetaVaults: {
          [metaVaultAddress: Address]: {
            [vaultAddress: Address]: number
          }
        } = {}

        for (const [metaVaultAddress, vaultMappings] of Object.entries(
          metaVaults
        )) {
          const normalizedVaultMappings: {
            [vaultAddress: Address]: number
          } = {}

          for (const [vaultAddress, value] of Object.entries(vaultMappings)) {
            normalizedVaultMappings[vaultAddress.toLowerCase()] = value
          }

          normalizedMetaVaults[metaVaultAddress.toLowerCase()] =
            normalizedVaultMappings
        }

        trimmedData.metaVaultTargets[chainId] = normalizedMetaVaults
      })

      Object.entries(data.assetVaults).forEach(([chainId, assetVault]) => {
        const trimmedAssetVault: {
          [assetVaultAddress: Address]: AssetVaultMapping
        } = {}

        for (const [assetVaultAddress, details] of Object.entries(assetVault)) {
          trimmedAssetVault[assetVaultAddress.toLowerCase()] = {
            ...details,
            tokenSymbol: details?.tokenSymbol?.trim(),
            tokenIcon: details?.tokenIcon?.trim(),
            protocolIcon: details?.protocolIcon?.trim(),
            link: details?.link?.trim(),
          }
        }

        trimmedData.assetVaults[chainId] = trimmedAssetVault
      })

      setVaultsMapping(trimmedData)
    }
  }, [data])

  return vaultsMapping
}
