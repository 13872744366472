import { useConfig } from '../../providers/config'
import { Address } from '../model'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { paths } from '../../models/protocol-api'
import { useMemo } from 'react'
import { multiply } from '../../helpers/math'
import { FixedNumber } from 'ethers'

const useDefaultHeaders = () =>
  useMemo(() => {
    const headers = new Headers()
    headers.set('Content-Type', 'application/json')
    return headers
  }, [])

const useStrategyYieldRequest = <T>(endpoint: string, enabled: boolean) => {
  const config = useConfig()
  const headers = useDefaultHeaders()

  const requestConfig = useMemo(() => {
    if (!enabled) return {} as Record<string, { '#call': RequestInfo }>
    return {
      '#call': {
        url: `${config.protocolServerUrl}${endpoint}`,
        method: 'GET',
        headers,
      } as RequestInfo,
    }
  }, [enabled, config.protocolServerUrl, endpoint, headers])

  return useJsonRestCalls<{ '#call': RequestInfo } | {}, T>(requestConfig)
}

export const useSingleStrategyYield = (
  strategyAddress: Address,
  chainId: string
): FixedNumber | undefined => {
  const enabled = Boolean(strategyAddress && chainId)
  const response = useStrategyYieldRequest<
    paths['/protocol/estimated-vault-yields/{address}/{chainId}']['get']['responses']['200']['content']['application/json']
  >(`/protocol/estimated-vault-yields/${strategyAddress}/${chainId}`, enabled)

  if (!response.data?.apy) {
    return undefined
  }
  return multiply(
    FixedNumber.fromString(response.data.apy.toString(), 18),
    FixedNumber.fromString('100', 18)
  )
}

export const useMetaVaultYield = (
  metaVaultAddress: Address,
  chainId: string
): FixedNumber | undefined => {
  const enabled = Boolean(metaVaultAddress && chainId)
  const response = useStrategyYieldRequest<
    paths['/protocol/estimated-meta-vault-yields/{address}/{chainId}']['get']['responses']['200']['content']['application/json']
  >(
    `/protocol/estimated-meta-vault-yields/${metaVaultAddress}/${chainId}`,
    enabled
  )

  if (!response.data?.apy) {
    return undefined
  }
  return multiply(
    FixedNumber.fromString(response.data.apy.toString(), 18),
    FixedNumber.fromString('100', 18)
  )
}

export const useAllSingleStrategyYields = (
  chainIds: string[]
):
  | { [chainId: string]: { [strategy: Address]: FixedNumber | undefined } }
  | undefined => {
  const enabled = Boolean(chainIds && chainIds.length > 0)
  const config = useConfig()
  const headers = useDefaultHeaders()

  const requestConfig = useMemo(() => {
    if (!enabled) return {} as Record<string, { '#call': RequestInfo }>
    return chainIds.reduce(
      (acc, chainId) => {
        acc[chainId] = {
          '#call': {
            url: `${config.protocolServerUrl}/protocol/estimated-vault-yields/${chainId}`,
            method: 'GET',
            headers,
          } as RequestInfo,
        }
        return acc
      },
      {} as Record<string, { '#call': RequestInfo }>
    )
  }, [chainIds, enabled, config.protocolServerUrl, headers])

  const response = useJsonRestCalls<
    Record<string, { '#call': RequestInfo }>,
    Record<
      string,
      Record<
        string,
        paths['/protocol/estimated-vault-yields/{chainId}']['get']['responses']['200']['content']['application/json']
      >
    >
  >(requestConfig)

  const flattenedData = useMemo(() => {
    if (!response.data) return response.data
    return Object.fromEntries(
      Object.entries(response.data).map(([chainId, nested]) => [
        chainId,
        nested[chainId],
      ])
    )
  }, [response.data])

  const strategyYieldsByChain = flattenedData
    ? Object.fromEntries(
        Object.entries(flattenedData).map(([chainId, innerObj]) => [
          chainId,
          Object.fromEntries(
            Object.entries(innerObj).map(([address, value]) => [
              address,
              multiply(
                FixedNumber.fromString(value.toString(), 18),
                FixedNumber.fromString('100', 18)
              ),
            ])
          ),
        ])
      )
    : undefined

  return strategyYieldsByChain
}

// Modified hook: Accepts an array of chain IDs and builds a query document
// with a separate call for each chain ID.
export const useAllMetaVaultYields = (
  chainIds: string[]
):
  | { [chainId: string]: { [strategy: Address]: FixedNumber | undefined } }
  | undefined => {
  const enabled = Boolean(chainIds && chainIds.length > 0)
  const config = useConfig()
  const headers = useDefaultHeaders()

  const requestConfig = useMemo(() => {
    if (!enabled) return {} as Record<string, { '#call': RequestInfo }>
    return chainIds.reduce(
      (acc, chainId) => {
        acc[chainId] = {
          '#call': {
            url: `${config.protocolServerUrl}/protocol/estimated-meta-vault-yields/${chainId}`,
            method: 'GET',
            headers,
          } as RequestInfo,
        }
        return acc
      },
      {} as Record<string, { '#call': RequestInfo }>
    )
  }, [chainIds, enabled, config.protocolServerUrl, headers])

  const response = useJsonRestCalls<
    Record<string, { '#call': RequestInfo }>,
    Record<
      string,
      Record<
        string,
        paths['/protocol/estimated-meta-vault-yields/{chainId}']['get']['responses']['200']['content']['application/json']
      >
    >
  >(requestConfig)

  const flattenedData = useMemo(() => {
    if (!response.data) return response.data
    return Object.fromEntries(
      Object.entries(response.data).map(([chainId, nested]) => [
        chainId,
        nested[chainId],
      ])
    )
  }, [response.data])

  const strategyYieldsByChain = flattenedData
    ? Object.fromEntries(
        Object.entries(flattenedData).map(([chainId, innerObj]) => [
          chainId,
          Object.fromEntries(
            Object.entries(innerObj).map(([address, value]) => [
              address,
              multiply(
                FixedNumber.fromString(value.toString(), 18),
                FixedNumber.fromString('100', 18)
              ),
            ])
          ),
        ])
      )
    : undefined

  return strategyYieldsByChain
}
