import { FixedNumber } from 'ethers'
import { useConfig } from '../../providers/config'
import { useMemo } from 'react'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { paths as protocolPaths } from '../../models/protocol-api'

export function useTermTokenPrice(): {
  currentPrice: FixedNumber
  previousPrice: FixedNumber
  priceChange: FixedNumber
  priceChangePercent: FixedNumber
  lastPriceTimestamp: number | undefined
} | undefined {
  const config = useConfig()
  const termTokenPriceUrl = useMemo(
    () =>
      config.protocolServerUrl
        ? `${config.protocolServerUrl}/protocol/term-token-price`
        : undefined,
    [config.protocolServerUrl]
  )

  const { data } = useJsonRestCalls<
    { '#call': RequestInfo },
    protocolPaths['/protocol/term-token-price']['get']['responses']['200']['content']['application/json']
  >({
    '#call': {
      url: termTokenPriceUrl,
      method: 'GET',
    } as RequestInfo,
  })

  return useMemo(() => {
    if (data) {
      return {
        currentPrice: data.lastPrice ? FixedNumber.fromString(data.lastPrice, 'fixed128x18') : FixedNumber.fromString('0', 'fixed128x18'),
        previousPrice: data.prevClosePrice ? FixedNumber.fromString(data.prevClosePrice, 'fixed128x18') : FixedNumber.fromString('0', 'fixed128x18'),
        priceChange: data.priceChange ? FixedNumber.fromString(data.priceChange, 'fixed128x18') : FixedNumber.fromString('0', 'fixed128x18'),
        priceChangePercent: data.priceChangePercent ? FixedNumber.fromString(data.priceChangePercent, 'fixed128x18') : FixedNumber.fromString('0', 'fixed128x18'),
        lastPriceTimestamp: data.lastUpdated,
      }
    }
    return undefined
  }, [data])
}
