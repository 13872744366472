import { VStack, Text, HStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import EarningsStatistics from '../ClaimSection/EarningsStatistics'
import { FixedNumber } from 'ethers'
import ClaimableData from '../ClaimSection/ClaimableData'
import { emptyTokenClaim } from '../../../../test-helpers/data-rewards'

export default function ClaimSectionLoading() {
  return (
    <VStack spacing={6} alignItems="start">
      <Text color={'blue.9'} variant={'body-2xl/medium'}>
        <Trans>Claim your earnings</Trans>
      </Text>

      <HStack bg="white" py={7} px={4} spacing={12} w="full">
        <EarningsStatistics
          title="Total Claimable Earnings"
          isDataLoading={true}
          amountEarnings={FixedNumber.fromString('0')}
        />
        <EarningsStatistics
          title="Claimable in Term Tokens"
          isDataLoading={true}
          amountEarnings={FixedNumber.fromString('0')}
        />
      </HStack>

      <VStack w="full" spacing={6} alignItems="start">
        <ClaimableData
          claimEarnings={emptyTokenClaim}
          isLoading={true}
          termTokenPrice={FixedNumber.fromString('0', `fixed128x18`)}
        />
      </VStack>
    </VStack>
  )
}
