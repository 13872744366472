import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Box,
  Text,
  Image,
  Heading,
  Input,
} from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import closeIcon from '../../../../assets/icons/close.svg'
import { useRef } from 'react'

export default function DeleteProfileConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  actionText,
  variant,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  body: string
  actionText: string
  variant?: 'error' | 'secondary'
}) {
  const initialRef = useRef<HTMLInputElement>(null)
  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <Box bg="white" pt={7} borderRadius="4px" w="480px" position="relative">
          <Image
            position="absolute"
            cursor="pointer"
            top={7}
            right={7}
            w="24px"
            p={'4px'}
            src={closeIcon}
            onClick={() => onClose()}
          />
          <VStack align="left" gap={5}>
            <Heading
              mx={7}
              variant="heading/h1"
              fontWeight={600}
              lineHeight={'32px'}
              color="blue.9"
              as="h1"
            >
              {title}
            </Heading>
            <ModalBody>
              <Input
                ref={initialRef}
                position="absolute"
                opacity={0}
                height={0}
                width={0}
              />
              <VStack gap={5} align="left">
                <Text>{body}</Text>
                <Button
                  w="full"
                  variant={variant ?? 'error'}
                  onClick={onConfirm}
                >
                  {actionText}
                </Button>
              </VStack>
            </ModalBody>
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  )
}
