import { Box, Flex } from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom'
import { Default as DefaultPointsBreakdown } from '../PointsBreakdown/stories'
import EarnSectionLoading from '../EarnSectionLoading'
import TrackSectionLoading from '../TrackSectionLoading'
import InviteSectionLoading from '../InviteSectionLoading'
import NavBar from '../NavBar'
import PointsBreakdown from '../PointsBreakdown'
import RewardsSummary from '../RewardsSummary'
import { FixedNumber } from 'ethers'
import ClaimSectionLoading from '../ClaimSectionLoading'

export default function RewardsPageLoading() {
  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto">
      <Flex w="100%" flexDirection="column" pt={4}>
        {/* @ts-ignore */}
        <PointsBreakdown {...DefaultPointsBreakdown.args} isLoading={true} />
        <Flex justifyContent="space-between" alignItems="center" pt={5}>
          <NavBar hasProfile={true} onSignUp={() => {}} />
          <RewardsSummary
            walletPositions={[
              {
                wallet: '',
                position: FixedNumber.fromString('0'),
              },
            ]}
            hasProfile={true}
            isLoggedIn={true}
            isLoading={true}
            profileName={'-'}
            onSignUp={undefined}
            onSignIn={undefined}
          />
        </Flex>
        <Box
          overflow="hidden"
          ml="0 !important"
          pb="80px"
          mb="0 !important"
          py={5}
        >
          <Routes>
            <Route index element={<EarnSectionLoading />} />
            <Route path="track" element={<TrackSectionLoading />} />
            <Route path="invite" element={<InviteSectionLoading />} />
            <Route path="claim" element={<ClaimSectionLoading />} />
          </Routes>
        </Box>
      </Flex>
    </Box>
  )
}
