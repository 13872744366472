export type SafaryEvents = {
  bidToBorrow: {
    type: string
  }
  offerToLend: {
    type: string
  }
  earn: {
    selectAsset: {
      type: string
    }
    expandDetails: {
      type: string
    }
    selectRepo: {
      type: string
    }
    selectChain: {
      type: string
    }
    earnComplete: {
      type: string
    }
    createListing: {
      type: string
    }
    cancelListings: {
      type: string
    }
    whatIsEarn: {
      type: string
    }
    blueSheetsDocsLink: {
      type: string
    }
    addRepoTokenToWallet: {
      type: string
    }
    goToPortfolio: {
      type: string
    }
  }
  vault: {
    deposit: {
      type: string
    }
    withdraw: {
      type: string
    }
    redeem: {
      type: string
    }
  }
  vaultsList: {
    deposit: {
      type: string
    }
    withdraw: {
      type: string
    }
    redeem: {
      type: string
    }
  }
  metavault: {
    deposit: {
      type: string
    }
    withdraw: {
      type: string
    }
    redeem: {
      type: string
    }
  }
  metavaultsList: {
    deposit: {
      type: string
    }
    withdraw: {
      type: string
    }
    redeem: {
      type: string
    }
  }
  navLinks: {
    navigated: {
      type: string
    }
  }
}

export enum SourceTrack {
  reactGA = 'reactGA',
  safary = 'safary',
}
