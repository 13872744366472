import { useMemo } from 'react'
import tokenIcons from '../../helpers/tokenIcons'

export function useTokenIcon(symbol: string) {
  const tokenIconKeys = Object.keys(tokenIcons)
  const tokenIcon =
    tokenIconKeys.find((value) => symbol.toUpperCase().startsWith(value)) ||
    symbol.toUpperCase()
  return useMemo(() => tokenIcons[tokenIcon] || tokenIcons['unknown'], [symbol])
}
