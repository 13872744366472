import {
  Box,
  BoxProps,
  Button,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { HStack } from '../../components/elements/Stack'
import { ChainSpecificConfig } from '../../config'
import { useConfig } from '../../providers/config'
import Banner from './elements/Banner'
import VaultCharts from './elements/VaultCharts'
import VaultCollateral from './elements/VaultCollateral'
import VaultCuratorAdminPanel from './elements/VaultCuratorAdminPanel'
import VaultDepositWithdraw from './elements/VaultDepositWithdraw'
import VaultHoldings from './elements/VaultHoldings'
import VaultPageLoading from './elements/VaultPageLoading'
import VaultStats from './elements/VaultStats'
import {
  AssetVault,
  CollateralTokenPairing,
  MappedGovernanceProposal,
  VaultHolding,
  VaultPageParams,
  VaultStatistics,
} from '../../models/vault'
import { Address, Currency, NativeCurrency } from '../../data/model'
import { BigNumber, FixedNumber } from 'ethers'
import { useState } from 'react'
import VaultStatusBanner from './elements/VaultStatusBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import VaultGovernance from './elements/VaultGovernance'
import NavBarOption from '../../components/elements/NavBarOption'
import { TabVaultOption } from './types'
import CuratorInfo from './elements/CuratorInfo'
import AboutCuratorBox from './elements/AboutCuratorBox'

export default function VaultPage({
  account,
  isDepositPaused,
  isVaultPaused,
  isPendingManagement,
  isVaultShutdown,
  isVaultGovernor,
  isVaultManager,
  isGovernorMismatch,
  currentDiscountRateMarkup,
  currentDiscountRateAdapter,
  currentTermController,
  vaultStatistics,
  idleVaultRate,
  largestHolding,
  assetVault,
  vaultAssetCurrency,
  vaultAssetBalance,
  vaultAssetAllowance,
  vaultAssetPrice,
  gasTokenBalance,
  gasTokenCurrency,
  vaultReceiptCurrency,
  vaultReceiptBalance,
  vaultReceiptBalanceInAssetTerms,
  availableDepositLimit,
  availableWithdrawLimit,
  totalLiquidBalance,
  totalLiquidBalanceUsd,
  totalLiquidBalanceDistribution,
  openAuctionOffers,
  repoTokenHoldings,
  vaultGovernance,
  supportedCollateralTokens,
  onWrapGasToken,
  onConnect,
  onKytCheck,
  onVaultAssetApprove,
  onDeposit,
  onWithdraw,
  onWithdrawMax,
  onSetTermController,
  onSetCollateralTokenParams,
  onSetDiscountRateAdapter,
  onSetDiscountRateMarkup,
  onSetRepoTokenBlacklist,
  onSetRepoTokenConcentrationLimit,
  onSetRequiredReserveRatio,
  onSetTimeToMaturityThreshold,
  onPauseStrategy,
  onUnpauseStrategy,
  onPauseDeposit,
  onUnpauseDeposit,
  onAcceptManagement,
  onExecuteVaultParameterUpdate,
  onSkipExpiredTransactions,
  collateralTokens,
  onViewMetaVault,
  onViewVaults,
  metaVaultSelected,
}: VaultPageParams) {
  // Read the vault address and chain id from the URL
  const { vaultAddress, chainId } = useParams()

  if (vaultAddress === undefined) {
    throw new Error(
      'Vault component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Vault component may only be used within a route with chain param'
    )
  }

  const parsedChainId = parseInt(chainId, 10)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${chainId}`)
  }
  const config = useConfig()
  const chainData = config.chains[parsedChainId]

  if (
    vaultAssetCurrency === undefined ||
    vaultAssetPrice === undefined ||
    vaultReceiptCurrency === undefined ||
    assetVault === undefined ||
    currentTermController === undefined ||
    currentDiscountRateAdapter === undefined ||
    currentDiscountRateMarkup === undefined ||
    gasTokenCurrency === undefined ||
    vaultStatistics === undefined ||
    totalLiquidBalance === undefined ||
    totalLiquidBalanceUsd === undefined ||
    totalLiquidBalanceDistribution === undefined ||
    repoTokenHoldings === undefined ||
    largestHolding === undefined ||
    vaultGovernance === undefined ||
    onVaultAssetApprove === undefined ||
    onPauseDeposit === undefined ||
    onUnpauseDeposit === undefined ||
    onPauseStrategy === undefined ||
    onUnpauseStrategy === undefined ||
    onAcceptManagement === undefined ||
    onExecuteVaultParameterUpdate === undefined ||
    onSkipExpiredTransactions === undefined
  ) {
    return (
      <VaultPageLoading
        onViewMetaVault={onViewMetaVault}
        onViewVaults={onViewVaults}
        metaVaultSelected={metaVaultSelected}
      />
    )
  }

  return (
    <ParameterizedVaultPage
      account={account}
      chain={chainData}
      vaultAddress={vaultAddress}
      largestHolding={largestHolding}
      vaultStatistics={vaultStatistics}
      idleVaultRate={idleVaultRate}
      collateralTokens={collateralTokens}
      isDepositPaused={isDepositPaused}
      isPendingManagement={isPendingManagement}
      isVaultPaused={isVaultPaused}
      isVaultShutdown={isVaultShutdown}
      isGovernor={isVaultGovernor}
      isGovernorMismatch={isGovernorMismatch}
      isManager={isVaultManager}
      availableDepositLimit={availableDepositLimit}
      availableWithdrawLimit={availableWithdrawLimit}
      vaultAssetBalance={vaultAssetBalance}
      vaultAssetAllowance={vaultAssetAllowance}
      assetVault={assetVault}
      vaultAssetCurrency={vaultAssetCurrency}
      vaultAssetPrice={vaultAssetPrice}
      gasTokenBalance={gasTokenBalance}
      gasTokenCurrency={gasTokenCurrency}
      vaultReceiptCurrency={vaultReceiptCurrency}
      vaultReceiptBalance={vaultReceiptBalance}
      vaultReceiptBalanceInAssetTerms={vaultReceiptBalanceInAssetTerms}
      currentTermController={currentTermController}
      currentDiscountRateAdapter={currentDiscountRateAdapter}
      currentDiscountRateMarkup={currentDiscountRateMarkup}
      openAuctionOffers={openAuctionOffers ?? []} // TODO: no fallback here, should be done in hook
      repoTokenHoldings={repoTokenHoldings ?? []}
      totalLiquidBalance={totalLiquidBalance}
      totalLiquidBalanceUsd={totalLiquidBalanceUsd}
      totalLiquidBalanceDistribution={totalLiquidBalanceDistribution}
      vaultGovernance={vaultGovernance}
      supportedCollateralTokens={supportedCollateralTokens}
      onSetTermController={onSetTermController}
      onSetCollateralTokenParams={onSetCollateralTokenParams}
      onSetDiscountRateAdapter={onSetDiscountRateAdapter}
      onSetDiscountRateMarkup={onSetDiscountRateMarkup}
      onSetRepoTokenBlacklist={onSetRepoTokenBlacklist}
      onSetRepoTokenConcentrationLimit={onSetRepoTokenConcentrationLimit}
      onSetRequiredReserveRatio={onSetRequiredReserveRatio}
      onSetTimeToMaturityThreshold={onSetTimeToMaturityThreshold}
      onWrapGasToken={onWrapGasToken}
      onVaultAssetApprove={onVaultAssetApprove}
      onDeposit={onDeposit}
      onWithdraw={onWithdraw}
      onWithdrawMax={onWithdrawMax}
      onPauseStrategy={onPauseStrategy}
      onUnpauseStrategy={onUnpauseStrategy}
      onPauseDeposit={onPauseDeposit}
      onUnpauseDeposit={onUnpauseDeposit}
      onAcceptManagement={onAcceptManagement}
      onConnect={onConnect}
      onKytCheck={onKytCheck}
      onViewMetaVault={onViewMetaVault}
      onViewVaults={onViewVaults}
      onExecuteVaultParameterUpdate={onExecuteVaultParameterUpdate}
      onSkipExpiredTransactions={onSkipExpiredTransactions}
      metaVaultSelected={metaVaultSelected}
    />
  )
}

export function ParameterizedVaultPage({
  account,
  chain,
  vaultAssetCurrency,
  vaultAssetBalance,
  vaultAssetAllowance,
  vaultAssetPrice,
  assetVault,
  gasTokenCurrency,
  gasTokenBalance,
  vaultReceiptCurrency,
  vaultReceiptBalance,
  vaultReceiptBalanceInAssetTerms,
  availableDepositLimit,
  availableWithdrawLimit,
  largestHolding,
  totalLiquidBalance,
  totalLiquidBalanceUsd,
  totalLiquidBalanceDistribution,
  openAuctionOffers,
  repoTokenHoldings,
  vaultGovernance,
  supportedCollateralTokens,
  onConnect,
  onKytCheck,
  onWrapGasToken,
  onVaultAssetApprove,
  onDeposit,
  onWithdraw,
  onWithdrawMax,
  onSetTermController,
  onSetCollateralTokenParams,
  onSetDiscountRateAdapter,
  onSetDiscountRateMarkup,
  onSetRepoTokenBlacklist,
  onSetTimeToMaturityThreshold,
  onSetRequiredReserveRatio,
  onSetRepoTokenConcentrationLimit,
  onAcceptManagement,
  onPauseStrategy,
  onUnpauseStrategy,
  onPauseDeposit,
  onUnpauseDeposit,
  onExecuteVaultParameterUpdate,
  onSkipExpiredTransactions,
  currentTermController,
  currentDiscountRateAdapter,
  currentDiscountRateMarkup,
  isPendingManagement,
  isDepositPaused,
  isGovernor,
  isGovernorMismatch,
  isManager,
  isVaultPaused,
  isVaultShutdown,
  vaultAddress,
  vaultStatistics,
  idleVaultRate,
  collateralTokens,
  onViewMetaVault,
  onViewVaults,
  metaVaultSelected,
}: {
  account: Address | undefined
  chain: ChainSpecificConfig
  vaultAssetCurrency: Currency
  vaultAssetBalance: FixedNumber
  vaultAssetAllowance: BigNumber
  vaultAssetPrice: FixedNumber
  assetVault: AssetVault
  vaultReceiptCurrency: Currency
  vaultReceiptBalance: FixedNumber
  vaultReceiptBalanceInAssetTerms: FixedNumber
  gasTokenBalance: FixedNumber
  gasTokenCurrency: NativeCurrency
  availableDepositLimit: FixedNumber
  availableWithdrawLimit: FixedNumber
  openAuctionOffers: VaultHolding[]
  repoTokenHoldings: VaultHolding[]
  largestHolding: FixedNumber
  totalLiquidBalance: FixedNumber
  totalLiquidBalanceUsd: FixedNumber
  totalLiquidBalanceDistribution: FixedNumber
  supportedCollateralTokens: Address[]
  vaultGovernance: {
    url: string
    pendingGovernance: MappedGovernanceProposal[]
    pendingExecution: MappedGovernanceProposal[]
    hasExpiredTransactions: boolean
  }
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onSetTermController: (address: Address) => Promise<void>
  onSetCollateralTokenParams: (
    address: Address,
    minMaintenanceMargin: FixedNumber
  ) => Promise<void>
  onSetDiscountRateAdapter: (address: Address) => Promise<void>
  onSetDiscountRateMarkup: (markup: string) => Promise<void>
  onSetRepoTokenBlacklist: (address: Address) => Promise<void>
  onSetRepoTokenConcentrationLimit: (address: Address) => Promise<void>
  onSetTimeToMaturityThreshold: (threshold: string) => Promise<void>
  onSetRequiredReserveRatio: (ratio: string) => Promise<void>
  onAcceptManagement: () => Promise<void>
  onPauseStrategy: () => Promise<void>
  onUnpauseStrategy: () => Promise<void>
  onPauseDeposit: () => Promise<void>
  onUnpauseDeposit: () => Promise<void>
  onDeposit: (amount: string) => Promise<void>
  onWithdraw: (amount: string) => Promise<void>
  onWithdrawMax: () => Promise<void>
  onWrapGasToken: (value: BigNumber) => Promise<void>
  onVaultAssetApprove: (amount?: string) => Promise<void>
  onExecuteVaultParameterUpdate: (
    nonce: number,
    calldata: string
  ) => Promise<void>
  onSkipExpiredTransactions: () => Promise<void>
  currentTermController: Address
  currentDiscountRateAdapter: Address
  currentDiscountRateMarkup: string
  isPendingManagement: boolean
  isDepositPaused: boolean
  isGovernor: boolean
  isGovernorMismatch: boolean
  isManager: boolean
  isVaultPaused: boolean
  isVaultShutdown: boolean
  vaultAddress: string
  vaultStatistics: VaultStatistics
  idleVaultRate?: string
  collateralTokens: CollateralTokenPairing[]
  onViewMetaVault: () => void
  onViewVaults: () => void
  metaVaultSelected: string | undefined
}) {
  const {
    currentYield,
    totalAssetValue,
    pricePerShare,
    vaultCurator,
    vaultName,
    curatorIcon,
    WAL,
    WALCap,
    liquidityReserve,
    requiredReserve,
    concentrationCap,
    performanceFee,
    managementFee,
  } = vaultStatistics
  const [tabIndex, setTabIndex] = useState(0)
  const [isCloseBox, setIsCloseBox] = useState(false)

  const handleTabsChange = (idx: number) => {
    setTabIndex(idx) // 2 is the index of the Collateral tab
  }

  const numberOfPendingGovernance =
    (vaultGovernance?.pendingGovernance?.length ?? 0) +
    (vaultGovernance?.pendingExecution?.length ?? 0)

  const closeAboutCuratorBox = localStorage.getItem(
    `${vaultAddress}-closeAboutCuratorBox`
  )

  return (
    <>
      {(isDepositPaused ||
        isVaultPaused ||
        isVaultShutdown ||
        isPendingManagement ||
        (isGovernorMismatch && (isGovernor || isManager))) && (
        <VaultStatusBanner
          isVaultPaused={isVaultPaused}
          isVaultShutdown={isVaultShutdown}
          isDepositPaused={isDepositPaused}
          isGovernorMismatch={isGovernorMismatch}
          isPendingManagement={isPendingManagement}
        />
      )}
      <Box maxW="1440px" px={{ base: 16, xl: 20 }} mx="auto" pt={4}>
        <HStack mb={4}>
          <Button
            variant="body-xs/medium"
            height="auto"
            p={0}
            rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
            onClick={onViewVaults}
          >
            <Trans>Vaults</Trans>
          </Button>
          {metaVaultSelected && (
            <Button
              variant="body-xs/medium"
              height="auto"
              p={0}
              rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
              onClick={onViewMetaVault}
            >
              {metaVaultSelected}
            </Button>
          )}
          <Button variant="body-xs/medium" height="auto" p={0} color="gray.6">
            {vaultName}{' '}
          </Button>
        </HStack>
        <Box maxW="full">
          <Banner
            currentYield={currentYield}
            totalAssetValue={totalAssetValue}
            pricePerShare={pricePerShare}
            vaultReceiptBalance={vaultReceiptBalance}
            vaultAssetCurrency={vaultAssetCurrency}
            vaultReceiptCurrency={vaultReceiptCurrency}
            vaultCurator={vaultCurator}
            vaultName={vaultName}
            curatorIcon={curatorIcon}
            vaultAddress={vaultAddress}
            chainId={chain.chainId}
            vaultType={'Yearn V3'}
          />
        </Box>

        {!isCloseBox && closeAboutCuratorBox !== 'true' && (
          <AboutCuratorBox
            vaultStatistics={vaultStatistics}
            handleCloseBox={() => {
              localStorage.setItem(
                `${vaultAddress}-closeAboutCuratorBox`,
                'true'
              )
              setIsCloseBox(true)
            }}
          />
        )}

        <HStack
          mt={6}
          spacing={6}
          alignItems="start"
          maxW="full"
          overflow="hidden"
        >
          <VaultCard h="320px" w="50%" flexBasis="50%">
            <VaultStats
              curatorIcon={curatorIcon}
              vaultCurator={vaultCurator}
              WAL={WAL}
              WALCap={WALCap}
              liquidityReserve={liquidityReserve}
              largestHolding={largestHolding}
              requiredReserve={requiredReserve}
              concentrationCap={concentrationCap}
              performanceFee={performanceFee}
              managementFee={managementFee}
              isGovernor={isGovernor}
              onSetRepoTokenConcentrationLimit={
                onSetRepoTokenConcentrationLimit
              }
              onSetTimeToMaturityThreshold={onSetTimeToMaturityThreshold}
              onSetRequiredReserveRatio={onSetRequiredReserveRatio}
            />
          </VaultCard>
          <VaultCard h="320px" w="50%" flexBasis="50%">
            {isGovernor || isManager ? (
              <VaultCuratorAdminPanel
                isDepositPaused={isDepositPaused}
                isVaultPaused={isVaultPaused}
                isPendingManagement={isPendingManagement}
                isGovernor={isGovernor}
                isManager={isManager}
                vaultAddress={vaultAddress}
                currentTermController={currentTermController}
                currentDiscountRateMarkup={currentDiscountRateMarkup}
                currentDiscountRateAdapter={currentDiscountRateAdapter}
                onSetTermController={onSetTermController}
                onSetDiscountRateAdapter={onSetDiscountRateAdapter}
                onSetDiscountRateMarkup={onSetDiscountRateMarkup}
                onSetRepoTokenBlacklist={onSetRepoTokenBlacklist}
                onEditCollateralParameters={handleTabsChange}
                onPauseStrategy={onPauseStrategy}
                onUnpauseStrategy={onUnpauseStrategy}
                onPauseDeposit={onPauseDeposit}
                onUnpauseDeposit={onUnpauseDeposit}
                onAcceptManagement={onAcceptManagement}
              />
            ) : (
              <VaultDepositWithdraw
                isDepositPaused={isDepositPaused}
                account={account}
                convertToAssetsRatio={vaultStatistics.convertToAssetsRatio}
                availableDepositLimit={availableDepositLimit}
                availableWithdrawLimit={availableWithdrawLimit}
                vaultAssetCurrency={vaultAssetCurrency}
                vaultAssetBalance={vaultAssetBalance}
                vaultReceiptCurrency={vaultReceiptCurrency}
                vaultReceiptBalanceInAssetTerms={
                  vaultReceiptBalanceInAssetTerms
                }
                vaultAssetAllowance={vaultAssetAllowance}
                vaultAssetPrice={vaultAssetPrice}
                gasTokenBalance={gasTokenBalance}
                gasTokenCurrency={gasTokenCurrency}
                onConnect={onConnect}
                onWrapGasToken={onWrapGasToken}
                onVaultAssetApprove={onVaultAssetApprove}
                onKytCheck={onKytCheck}
                onDeposit={onDeposit}
                onWithdraw={onWithdraw}
                onWithdrawMax={onWithdrawMax}
              />
            )}
          </VaultCard>
        </HStack>
        <VaultCard w="full" mt="24px">
          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            variant="plain"
            colorScheme="green"
          >
            <TabList gap={3} mb={2}>
              <NavBarOption
                selected={tabIndex === TabVaultOption.Chart}
                onClick={() => handleTabsChange(TabVaultOption.Chart)}
                label="Charts"
              />
              <NavBarOption
                selected={tabIndex === TabVaultOption.Holdings}
                onClick={() => handleTabsChange(TabVaultOption.Holdings)}
                label={`Holdings (${
                  1 +
                  (openAuctionOffers?.length ?? 0) +
                  (repoTokenHoldings?.length ?? 0)
                })`}
              />
              <NavBarOption
                selected={tabIndex === TabVaultOption.Collateral}
                onClick={() => handleTabsChange(TabVaultOption.Collateral)}
                label={`Collateral (${collateralTokens?.length})`}
              />
              <NavBarOption
                selected={tabIndex === TabVaultOption.CuratorInfo}
                onClick={() => handleTabsChange(TabVaultOption.CuratorInfo)}
                label="Curator Info"
              />
              <NavBarOption
                selected={tabIndex === TabVaultOption.Governance}
                onClick={() => handleTabsChange(TabVaultOption.Governance)}
                label={`Governance (${numberOfPendingGovernance})`}
              />
            </TabList>
            <TabPanels>
              <TabPanel>
                <VaultCharts
                  isActiveTab={tabIndex === 0}
                  idleCapitalUsd={totalLiquidBalanceUsd}
                  holdings={[...repoTokenHoldings, ...openAuctionOffers]}
                />
              </TabPanel>
              <TabPanel pt="30px" px="16px">
                <VaultHoldings
                  assetVault={assetVault}
                  openAuctionOffers={openAuctionOffers}
                  holdings={repoTokenHoldings}
                  idleVaultRate={idleVaultRate}
                  totalLiquidBalance={totalLiquidBalance}
                  totalLiquidBalanceUSD={totalLiquidBalanceUsd}
                  totalLiquidBalanceDistribution={
                    totalLiquidBalanceDistribution
                  }
                  vaultAssetToken={vaultAssetCurrency.symbol}
                  vaultReceiptToken={vaultReceiptCurrency.symbol}
                />
              </TabPanel>
              <TabPanel pt="30px" px="0">
                <VaultCollateral
                  supportedCollateralTokens={supportedCollateralTokens}
                  onSetCollateralTokenParams={onSetCollateralTokenParams}
                  collateralTokens={collateralTokens}
                  isGovernor={isGovernor}
                />
              </TabPanel>
              <TabPanel pt="30px" px="0">
                <CuratorInfo vaultStatistics={vaultStatistics} />
              </TabPanel>
              <TabPanel pt="30px" px="0">
                <VaultGovernance
                  account={account}
                  url={vaultGovernance?.url}
                  hasExpiredTransactions={
                    !!vaultGovernance?.hasExpiredTransactions
                  }
                  pendingGovernance={vaultGovernance?.pendingGovernance || []}
                  pendingExecution={vaultGovernance?.pendingExecution || []}
                  onExecuteChanges={onExecuteVaultParameterUpdate}
                  onSkipExpiredTransactions={onSkipExpiredTransactions}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VaultCard>
      </Box>
    </>
  )
}

function VaultCard({
  children,
  ...props
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Box borderRadius={'8px'} p={6} bg="#fff" {...props}>
      {children}
    </Box>
  )
}
