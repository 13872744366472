import {
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { BigNumber, FixedNumber } from 'ethers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ButtonGroup from '../../../../components/elements/ButtonGroup'
import Chip from '../../../../components/elements/Chip'
import { VStack } from '../../../../components/elements/Stack'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import TokenIcon from '../../../../components/elements/TokenIcon'
import { fixedToBigNumber, formatFixed } from '../../../../helpers/conversions'
import {
  divide,
  fixedCompare,
  multiply,
  subtract,
} from '../../../../helpers/math'
import {
  formatFixedToken,
  formatFixedUsd,
  parseUserInputCurrencyAmountFN,
} from '../../../../helpers/utils'
import { MetaVaultData, VaultData } from '../../../../models/vaults'
import { useChainConfig } from '../../../../providers/config'
import VaultInputField from '../../../Vault/elements/VaultInputField'
import { TabOption } from '../../../Vault/types'
import { toReceiptToken } from '../../../../helpers/receiptToken'
import { Address } from '../../../../data/model'
import { termToastMessages } from '../../../../helpers/toasts'
import { useTermToast } from '../../../../hooks/toasts'
import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'
import { useStorage } from '../../../../providers/storage'
import VaultRiskAcknowledgementModal from '../../../Vault/elements/VaultRiskAcknowledgementModal'

type Props = {
  isDataLoaded?: boolean
  account?: string
  metaVaults: MetaVaultData[]
  vaults: VaultData[]
  assetPrices: {
    [chainId: string]: {
      [token: string]: FixedNumber
    }
  }
  convertToAssetsRatio: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | undefined
    }
  }
  vaultAssetTokenBalance?: {
    [chainId: string]: {
      [tokenAddress: string]: FixedNumber
    }
  }
  vaultAssetTokenAllowances?: {
    [chainId: string]: {
      [vaultAddress: string]: FixedNumber
    }
  }
  receiptTokenBalancesInAssetTerms?: {
    [chainId: string]: {
      [tokenAddress: string]: FixedNumber
    }
  }
  onConnect: () => void
  onApprove: (
    chainId: string,
    token: Address,
    spender: Address,
    amount: FixedNumber
  ) => Promise<void>
  onDeposit: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber
  ) => Promise<void>
  onWithdraw: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address,
    amount: FixedNumber
  ) => Promise<void>
  onWithdrawMax: (
    isMetaVault: boolean,
    chainId: string,
    strategyAddress: Address
  ) => Promise<void>
  onKytCheck: () => Promise<boolean>
}

export default function MetaVaultDepositWithdraw({
  isDataLoaded = true,
  account,
  metaVaults,
  vaults,
  assetPrices,
  convertToAssetsRatio,
  vaultAssetTokenBalance,
  vaultAssetTokenAllowances,
  receiptTokenBalancesInAssetTerms,
  onConnect,
  onApprove,
  onDeposit,
  onWithdraw,
  onWithdrawMax,
  onKytCheck,
}: Props) {
  const [openSelect, setOpenSelect] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const zeroFN = FixedNumber.fromString('0', `fixed128x18`)
  const termToast = useTermToast()

  const [tab, setTab] = useState(TabOption.Deposit)
  const [amount, setAmount] = useState('')
  const [isMaxWithdrawSelected, setIsMaxWithdrawSelected] = useState(false)
  const [selectedVault, setSelectedVault] = useState<
    VaultData | MetaVaultData | undefined
  >()

  const shareTokenAmount = useMemo(() => {
    const convertToAssetRatio = selectedVault
      ? convertToAssetsRatio?.[selectedVault.chainId]?.[selectedVault.address]
      : undefined
    if (
      !amount ||
      !convertToAssetRatio ||
      !selectedVault?.purchaseCurrency?.decimals
    ) {
      return undefined
    }
    const amountFN = parseUserInputCurrencyAmountFN(
      amount,
      selectedVault.purchaseCurrency.decimals,
      '0'
    )

    return divide(amountFN, convertToAssetRatio).round(0) // convertToAssets uses round down in contract
  }, [amount, convertToAssetsRatio, selectedVault])

  const inputToken = account
    ? (selectedVault?.purchaseCurrency?.symbol ?? 'USDC')
    : 'USDC'

  const [
    selectedVaultReceiptTokenInAssetTermsBalance,
    setSelectedVaultReceiptTokenInAssetTermsBalance,
  ] = useState<FixedNumber | undefined>()
  const [selectedVaultAssetTokenBalance, setSelectedVaultAssetTokenBalance] =
    useState<FixedNumber | undefined>()

  const [
    selectedVaultAssetTokenAllowance,
    setSelectedVaultAssetTokenAllowance,
  ] = useState<FixedNumber | undefined>()

  const {
    isOpen: isVaultRiskAcknowledgementModalOpen,
    onOpen: onOpenVaultRiskAcknowledgementModal,
    onClose: onCloseVaultRiskAcknowledgementModal,
  } = useDisclosure()
  const { storage } = useStorage()

  const submitButtonRef = useRef<null | (() => Promise<void>)>(null)

  const amountFN = useMemo(
    () =>
      selectedVault
        ? parseUserInputCurrencyAmountFN(
            amount,
            selectedVault.purchaseCurrency.decimals,
            '0'
          )
        : zeroFN,
    [amount, selectedVault, zeroFN]
  )

  useEffect(() => {
    if (selectedVault) {
      setSelectedVaultReceiptTokenInAssetTermsBalance(
        receiptTokenBalancesInAssetTerms?.[selectedVault.chainId]?.[
          selectedVault.address
        ]
      )
      setSelectedVaultAssetTokenBalance(
        vaultAssetTokenBalance?.[selectedVault.chainId]?.[
          selectedVault.purchaseCurrency.address
        ]
      )
      setSelectedVaultAssetTokenAllowance(
        vaultAssetTokenAllowances?.[selectedVault.chainId]?.[
          selectedVault.address
        ]
      )
    }
  }, [
    selectedVault,
    receiptTokenBalancesInAssetTerms,
    vaultAssetTokenBalance,
    vaultAssetTokenAllowances,
  ])

  const [helperText, isError] = useMemo((): [string | undefined, boolean] => {
    const balance =
      tab === TabOption.Deposit
        ? selectedVaultAssetTokenBalance
        : selectedVaultReceiptTokenInAssetTermsBalance

    if (selectedVault && balance && subtract(balance, amountFN).isNegative()) {
      return ['Insufficient wallet balance', true]
    }

    if (
      tab === TabOption.Deposit &&
      selectedVault &&
      assetPrices?.[selectedVault.chainId]?.[
        selectedVault.purchaseCurrency.address
      ]
    ) {
      const usdPrice = multiply(
        assetPrices[selectedVault.chainId][
          selectedVault.purchaseCurrency.address
        ],
        amountFN
      )
      return [`${formatFixedUsd(usdPrice, false, true).toString()}`, false]
    }

    return [undefined, false]
  }, [
    tab,
    assetPrices,
    selectedVaultReceiptTokenInAssetTermsBalance,
    selectedVaultAssetTokenBalance,
    selectedVault,
    amountFN,
  ])

  const onChangeSelectVault = (vault: VaultData | MetaVaultData) => {
    setSelectedVault(vault)
    setOpenSelect(false)
    setAmount('')
    setIsMaxWithdrawSelected(false)
  }

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => setOpenSelect(false),
  })

  const onSubmit = useCallback(async () => {
    if (!selectedVault || !shareTokenAmount) {
      return
    }

    console.log('selected vault: %o', selectedVault)

    if (tab === TabOption.Deposit) {
      await onDeposit(
        selectedVault.isMetaVault,
        selectedVault.chainId,
        selectedVault.address,
        amountFN
      )
    } else {
      if (isMaxWithdrawSelected) {
        console.log('withdraw max selected')
        await onWithdrawMax(
          selectedVault.isMetaVault,
          selectedVault.chainId,
          selectedVault.address
        )
      } else {
        await onWithdraw(
          selectedVault.isMetaVault,
          selectedVault.chainId,
          selectedVault.address,
          amountFN
        )
      }
    }
  }, [
    isMaxWithdrawSelected,
    selectedVault,
    amountFN,
    shareTokenAmount,
    tab,
    onDeposit,
    onWithdraw,
    onWithdrawMax,
  ])

  const onApproveDeposit = useCallback(
    async (amount: string) => {
      if (!selectedVault) {
        return
      }

      const amountFN = selectedVault
        ? parseUserInputCurrencyAmountFN(
            amount,
            selectedVault.purchaseCurrency.decimals,
            '0'
          )
        : zeroFN

      await onApprove(
        selectedVault.chainId,
        selectedVault.purchaseCurrency.address,
        selectedVault.address,
        amountFN
      )
    },
    [selectedVault, zeroFN, onApprove]
  )

  const onMax =
    account && selectedVault
      ? () => {
          if (tab === TabOption.Deposit) {
            setAmount(selectedVaultAssetTokenBalance?.toString() ?? '')
          } else if (tab === TabOption.Withdraw) {
            setIsMaxWithdrawSelected(true)
            if (
              selectedVaultReceiptTokenInAssetTermsBalance &&
              fixedCompare(
                selectedVaultReceiptTokenInAssetTermsBalance,
                'gt',
                selectedVault.availableWithdrawLimit
              )
            ) {
              setAmount(selectedVault.availableWithdrawLimit.toString() ?? '')
            } else {
              setAmount(
                selectedVaultReceiptTokenInAssetTermsBalance?.toString() ?? ''
              )
            }
          }
        }
      : undefined

  return (
    <Flex flexDir="column" flexBasis="50%" mr={8}>
      <Box w="full">
        <ButtonGroup
          left={{
            label: 'Deposit',
            value: TabOption.Deposit,
          }}
          right={{
            label: 'Withdraw',
            value: TabOption.Withdraw,
          }}
          value={tab}
          onChange={(tab) => {
            setTab(tab)
            setAmount('')
            setIsMaxWithdrawSelected(false)
            setOpenSelect(false)
          }}
          flexGrow={1}
          w="full"
        />
      </Box>
      <Box mt="20px" w="full" position="relative">
        <VaultInputField
          label={<Text color="gray.6">Amount</Text>}
          value={account ? amount : ''}
          onChange={(value) => {
            setIsMaxWithdrawSelected(false)
            setAmount(value)
          }}
          isDisabled={!selectedVault || !account}
          onMaxRightInput={onMax}
          leftElement={
            <Skeleton isLoaded={isDataLoaded}>
              <TokenIcon token={inputToken} boxSize="24px" />
            </Skeleton>
          }
          placeholder="0"
          w={'full'}
          tokenDecimals={
            account && selectedVault
              ? selectedVault.purchaseCurrency.decimals
              : 18
          }
          isError={isError}
          helperText={helperText}
          selectVault={
            <ButtonSelect
              openSelect={openSelect}
              setOpenSelect={() => setOpenSelect(true)}
              isDisabled={!account}
              textButton={
                selectedVault ? selectedVault.vaultName : 'Select Vault'
              }
            />
          }
        />

        {/* List Vaults Select */}
        <Box
          ref={ref}
          opacity={openSelect ? 1 : 0}
          visibility={openSelect ? 'visible' : 'hidden'}
          transform={openSelect ? 'translateY(6px)' : 'translateY(20px)'}
          transition="all 0.3s ease"
          w="full"
          bg="white"
          boxShadow="0px 0px 24px 0px #00000014"
          borderRadius="6px"
          p={2}
          position="absolute"
          zIndex={2}
          top="20px"
        >
          <HStack justifyContent="space-between" mb={2}>
            <Trans>
              <Text variant="body-sm/normal" color="gray.5">
                {tab === TabOption.Deposit ? 'Meta Vaults' : 'Your Meta Vaults'}
              </Text>
            </Trans>

            <ButtonSelect
              openSelect={openSelect}
              setOpenSelect={() => setOpenSelect(false)}
              textButton="Select Vault"
            />
          </HStack>
          {/* List Meta Vaults / Your Meta Vault */}
          <VStack spacing={0}>
            {metaVaults &&
              metaVaults.map((vault: MetaVaultData) => (
                <SelectVaultItem
                  key={`${vault.chainId}-${vault.address}`}
                  tab={tab}
                  vault={vault}
                  balance={
                    tab === TabOption.Deposit
                      ? vaultAssetTokenBalance?.[vault.chainId]?.[
                          vault.purchaseCurrency.address
                        ]
                      : receiptTokenBalancesInAssetTerms?.[vault.chainId]?.[
                          vault.address
                        ]
                  }
                  withdrawLimit={vault.availableWithdrawLimit}
                  onChangeSelectVault={() => onChangeSelectVault(vault)}
                />
              ))}
          </VStack>

          <HStack justifyContent="space-between" my={3}>
            <Trans>
              <Text variant="body-sm/normal" color="gray.5">
                {tab === TabOption.Deposit
                  ? 'Strategy Vaults'
                  : 'Your Strategy Vaults'}
              </Text>
            </Trans>
          </HStack>
          {/* List Strategy Vaults / Your Strategy Vault */}
          <VStack spacing={0}>
            {vaults &&
              vaults.map((vault: VaultData) => (
                <SelectVaultItem
                  key={`${vault.chainId}-${vault.address}`}
                  tab={tab}
                  vault={vault}
                  balance={
                    tab === TabOption.Deposit
                      ? vaultAssetTokenBalance?.[vault.chainId]?.[
                          vault.purchaseCurrency.address
                        ]
                      : receiptTokenBalancesInAssetTerms?.[vault.chainId]?.[
                          vault.address
                        ]
                  }
                  withdrawLimit={vault.availableWithdrawLimit}
                  onChangeSelectVault={() => onChangeSelectVault(vault)}
                />
              ))}
          </VStack>
        </Box>
      </Box>
      <Box mt="auto" w="full">
        {!isDataLoaded ? (
          <Button
            variant="tertiary"
            color="white"
            bg="blue.5"
            isDisabled
            leftIcon={<Spinner size="xs" />}
            w="100%"
          />
        ) : !account ? (
          <Button variant="primary" w="100%" onClick={onConnect}>
            <Trans>Connect wallet</Trans>
          </Button>
        ) : (
          <>
            {selectedVault && (
              <HelperText
                receiptAmount={shareTokenAmount}
                receiptTokenSymbol={selectedVault.receiptCurrency.symbol}
                assetTokenSymbol={selectedVault.purchaseCurrency.symbol}
                tab={tab}
              />
            )}
            {tab === TabOption.Deposit ? (
              <SubmitApproveButton
                size="md"
                variant="primary"
                w="100%"
                submitButtonRef={submitButtonRef}
                onSubmit={async () => {
                  if (!storage.getItem(`vaultRiskAccepted-${account}`)) {
                    onOpenVaultRiskAcknowledgementModal()
                    return
                  }
                  setSubmitting(true)
                  termToast.pending(termToastMessages.vaultDeposit.pending())
                  try {
                    if (selectedVault) {
                      await onSubmit()
                      termToast.success(
                        termToastMessages.vaultDeposit.success(
                          selectedVault.purchaseCurrency.symbol,
                          amount
                        )
                      )
                    }
                  } catch (error) {
                    if (
                      (error as Error).message.includes(
                        'user rejected transaction'
                      )
                    ) {
                      termToast.dismissed()
                    } else {
                      termToast.failure(
                        termToastMessages.vaultDeposit.failure()
                      )
                    }
                  } finally {
                    setSubmitting(false)
                  }
                }}
                onApprove={() => onApproveDeposit(amount)}
                isDisabled={
                  submitting ||
                  !amount ||
                  !selectedVault ||
                  vaultAssetTokenBalance?.[selectedVault.chainId]?.[
                    selectedVault.purchaseCurrency.address
                  ]?.isZero()
                }
                approvalAmount={fixedToBigNumber(amountFN)}
                approvedAmount={
                  selectedVaultAssetTokenAllowance
                    ? fixedToBigNumber(selectedVaultAssetTokenAllowance)
                    : BigNumber.from(0)
                }
                onKytCheck={onKytCheck}
                submitLabel="Deposit"
                approvingCurrencySymbol={
                  selectedVault?.purchaseCurrency.symbol ?? ''
                }
              />
            ) : (
              <Button
                variant="primary"
                w="100%"
                isDisabled={
                  submitting ||
                  !amount ||
                  !selectedVault ||
                  selectedVaultReceiptTokenInAssetTermsBalance?.isZero()
                }
                onClick={async () => {
                  setSubmitting(true)
                  termToast.pending(termToastMessages.vaultWithdraw.pending())
                  try {
                    if (selectedVault) {
                      await onSubmit()
                      termToast.success(
                        termToastMessages.vaultWithdraw.success(
                          selectedVault.purchaseCurrency.symbol,
                          amount
                        )
                      )
                    }
                  } catch (error) {
                    if (
                      (error as Error).message.includes(
                        'user rejected transaction'
                      )
                    ) {
                      termToast.dismissed()
                    } else {
                      termToast.failure(
                        termToastMessages.vaultWithdraw.failure()
                      )
                    }
                  } finally {
                    setSubmitting(false)
                  }
                }}
              >
                <Trans>Withdraw</Trans>
              </Button>
            )}
          </>
        )}
      </Box>
      <VaultRiskAcknowledgementModal
        isOpen={isVaultRiskAcknowledgementModalOpen}
        account={account}
        onClose={onCloseVaultRiskAcknowledgementModal}
        onAcceptance={() => {
          submitButtonRef.current && submitButtonRef.current()
        }}
      />
    </Flex>
  )
}

function HelperText({
  receiptAmount,
  receiptTokenSymbol,
  assetTokenSymbol,
  tab,
}: {
  receiptAmount?: FixedNumber
  receiptTokenSymbol: string
  assetTokenSymbol: string
  tab: TabOption
}) {
  return (
    <HStack
      justify="space-between"
      opacity={receiptAmount ? 1 : 0}
      transform={receiptAmount ? 'translate(0, 6px)' : 'translate(0, 42px)'}
      transition="0.3s"
      bg="blue.0"
      borderRadius="6px 6px 0px 0px"
      padding="8px 12px 14px 12px"
    >
      <Text color="gray.5.5" variant="body-sm/medium">
        {tab === TabOption.Deposit && <Trans>You'll receive</Trans>}
        {tab === TabOption.Withdraw && <Trans>You'll burn</Trans>}
      </Text>
      <Flex>
        <Text as="span" variant="body-sm/bold" color="blue.9">
          {receiptAmount && formatFixed(receiptAmount)}
        </Text>
        <Box ml={2}>
          <Chip>
            <HStack spacing={1}>
              <TokenIcon token={toReceiptToken(assetTokenSymbol)} />
              <Text as="span" color="gray.6" variant="body-xs/medium">
                {receiptTokenSymbol}
              </Text>
            </HStack>
          </Chip>
        </Box>
      </Flex>
    </HStack>
  )
}

function ButtonSelect({
  openSelect,
  setOpenSelect,
  isDisabled,
  textButton,
}: {
  openSelect: boolean
  setOpenSelect: () => void
  isDisabled?: boolean
  textButton: string
}) {
  return (
    <Button
      isDisabled={isDisabled}
      onClick={setOpenSelect}
      rightIcon={
        <FontAwesomeIcon
          icon={['far', openSelect ? 'chevron-up' : 'chevron-down']}
          role="button"
          width="12px"
        />
      }
      variant={openSelect ? 'tertiary' : 'primary'}
      borderRadius="20px"
      height="32px"
    >
      <Text variant="body-sm/medium" maxW="130px" isTruncated>
        <Trans>{textButton}</Trans>
      </Text>
    </Button>
  )
}

function SelectVaultItem({
  tab,
  vault,
  balance,
  withdrawLimit,
  onChangeSelectVault,
}: {
  tab: TabOption
  vault: VaultData | MetaVaultData
  balance?: FixedNumber
  withdrawLimit?: FixedNumber
  onChangeSelectVault: () => void
}) {
  const { purchaseCurrency, vaultName } = vault
  const chainConfig = useChainConfig(vault.chainId)

  return (
    <HStack
      w="full"
      justifyContent="space-between"
      alignItems="end"
      cursor="pointer"
      py={2}
      px={1}
      borderRadius="6px"
      _hover={{
        bg: 'blue.2',
      }}
      onClick={onChangeSelectVault}
    >
      <HStack>
        <VaultCuratorIcon
          vaultName={vault.curatorIcon ?? 'unknown'}
          boxSize="32px"
        />
        <VStack alignItems="start" spacing={1}>
          <Text variant="body-md/medium">{vaultName}</Text>
          {tab === TabOption.Deposit && balance && (
            <Text variant="body-sm/normal" color="gray.5">
              <Trans> Balance:</Trans>{' '}
              {`${formatFixedToken(
                balance,
                purchaseCurrency.symbol,
                true,
                true
              )} ${purchaseCurrency?.symbol}`}
            </Text>
          )}

          {tab === TabOption.Withdraw && balance && (
            <HStack w="full" justifyContent="space-between">
              <Text variant="body-sm/normal" color="gray.5">
                <Trans> Balance:</Trans>
              </Text>
              <Text variant="body-sm/normal" color="gray.5">
                {formatFixedToken(balance, purchaseCurrency.symbol, true, true)}{' '}
                {purchaseCurrency?.symbol}
              </Text>
            </HStack>
          )}

          {tab === TabOption.Withdraw && withdrawLimit && (
            <HStack w="full" justifyContent="space-between">
              <Text variant="body-sm/normal" color="gray.5">
                <Trans> Withdraw Limit:</Trans>
              </Text>
              <Text variant="body-sm/normal" color="gray.5">
                {formatFixedToken(
                  withdrawLimit,
                  purchaseCurrency.symbol,
                  true,
                  true
                )}{' '}
                {purchaseCurrency?.symbol}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>

      <Text variant="body-sm/normal" color="gray.5">
        {chainConfig?.chainName}
      </Text>
    </HStack>
  )
}
