import { Box, HStack, Skeleton, Text } from '@chakra-ui/react'
import TokenIcon from '../../../../../components/elements/TokenIcon'
import { Trans } from '@lingui/macro'
import { VStack } from '../../../../../components/elements/Stack'
import { formatFixedUsd } from '../../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import { TokenClaim } from '../../../../../models/rewards'

export default function ClaimableEarningsStatistic({
  claimEarnings,
  termTokenPrice,
  isLoading = false,
}: {
  claimEarnings: TokenClaim
  termTokenPrice: FixedNumber
  isLoading?: boolean
}) {
  return (
    <Box
      border="1px solid"
      borderColor="gray.4"
      borderRadius="8px"
      p={4}
      pr={10}
      w="fit-content"
    >
      <HStack>
        {isLoading ? (
          <Skeleton h="30px" w="30px" />
        ) : (
          <TokenIcon boxSize="30px" key={'TERM'} token={'TERM'} />
        )}

        <Text variant="body-md/medium" color="gray.6" opacity="0.5">
          <Trans>Claimable Earnings</Trans>
        </Text>
      </HStack>

      <VStack mt={4} spacing={2} alignItems="start">
        {isLoading ? (
          <>
            <Skeleton h="28px" w="125px" />
            <Skeleton h="24px" w="98px" />
            <Skeleton h="16px" w="130px" />
          </>
        ) : (
          <>
            <Text variant="body-xl/medium" color="blue.5">
              {formatFixedUsd(claimEarnings?.claimableAmountUSD)}
            </Text>

            <Text variant="body-md/medium" color="gray.6">
              {formatFixedUsd(termTokenPrice)} per token
            </Text>
            <Text variant="body-xs/medium" color="gray.6" opacity="0.5">
              Season 1 tokens
            </Text>
          </>
        )}
      </VStack>
    </Box>
  )
}
