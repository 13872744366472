import { graphql } from '../gql'

export const PageAuctionDocument = graphql(`
  query PageAuction($auction: Bytes!, $wallet: Bytes!) {
    termAuctions(where: { auction: $auction, delisted: false }) {
      id
      version
      term {
        id
        version
        repurchaseTimestamp
        redemptionTimestamp
        servicingFee

        purchaseToken
        purchaseTokenMeta {
          decimals
          symbol
          name
        }
        collateralTokens
        collateralTokensMeta {
          decimals
          symbol
          name
        }

        collateralRatios {
          maintenanceRatio
          initialRatio
        }

        liquidatedDamagesSchedule {
          collateralToken
          liquidatedDamages
        }

        termRepoServicer
        termRepoCollateralManager
        termRepoLocker
        termRepoRolloverManager
        termRepoToken

        completedAuctions {
          auction
        }
      }
      auction
      auctionBidLocker
      auctionOfferLocker
      auctionCancelled
      auctionCancelledForWithdrawal
      auctionComplete
      auctionClearingPrice
      auctionStartTime
      revealTime
      auctionEndTime
      dayCountFractionMantissa
      auctionMaxBidPrice
      auctionMinBidAmount
      auctionMaxOfferPrice
      auctionMinOfferAmount
    }
    auctionTermBids: termBids(
      where: {
        auction_: {
          auction: $auction
          delisted: false
          auctionCancelled: false
          auctionCancelledForWithdrawal: false
          auctionComplete: false
        }
        locked: true
      }
    ) {
      id
      amount
    }
    auctionTermOffers: termOffers(
      first: 1000
      where: {
        auction_: {
          auction: $auction
          delisted: false
          auctionCancelled: false
          auctionCancelledForWithdrawal: false
          auctionComplete: false
        }
        locked: true
      }
    ) {
      id
      amount
    }
    termBids(
      first: 1000
      where: {
        auction_: { auction: $auction, delisted: false }
        bidder: $wallet
        locked: true
      }
    ) {
      id
      bidder
      locked
      amount
      unrevealedBidPrice
      revealedBidPrice

      auction {
        version
        term {
          version
          purchaseToken
        }
      }

      bidCollateral {
        collateralToken
        amount
      }

      assignedAmount

      lastTransaction
    }
    termOffers(
      where: {
        auction_: { auction: $auction, delisted: false }
        offeror: $wallet
        locked: true
      }
    ) {
      id
      offeror
      locked
      amount
      unrevealedOfferPrice
      revealedOfferPrice

      auction {
        version
        term {
          version
          purchaseToken
        }
      }

      assignedAmount

      lastTransaction
    }
  }
`)

export const LastAuctionClearingPrices = graphql(`
  query LastAuctionClearingPrices(
    $purchaseToken: Bytes!
    $collateralToken: Bytes!
    $currentTimestamp: BigInt!
    $thirtyDaysAgoTimestamp: BigInt!
  ) {
    termAuctions(
      where: {
        term_: {
          delisted: false
          purchaseToken: $purchaseToken
          collateralTokens_contains: [$collateralToken]
        }
        revealTime_lte: $currentTimestamp
        revealTime_gte: $thirtyDaysAgoTimestamp
        delisted: false
      }
      orderBy: revealTime
      orderDirection: desc
    ) {
      id
      auctionClearingPrice
      revealTime
      term {
        collateralRatios {
          maintenanceRatio
        }
      }
    }
  }
`)
