import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import lendSuccessIcon from '../../../../../assets/icons/success.svg'
import lendSuccessGif from '../../../../../assets/icons/lend-success.gif'
import { HStack, VStack } from '../../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ConfirmationClaimModal({
  claimedAmount,
  isOpen,
  onAddRepoTokenToWallet,
  onStakeTokens,
  onClose,
}: {
  claimedAmount: React.ReactNode
  isOpen: boolean
  onAddRepoTokenToWallet: () => Promise<boolean>
  onStakeTokens: () => void
  onClose: () => void
}) {
  const onCloseModal = () => {
    onClose()
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <Box
          bg="white"
          borderRadius="16px"
          w="480px"
          position="relative"
          overflow="hidden"
        >
          <ModalHeader
            borderBottomRightRadius="230px"
            borderBottomLeftRadius="230px"
            bg="#E9FDF980"
            backgroundImage={`url(${lendSuccessGif})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            overflow="hidden"
          >
            <Flex height="217px" justifyContent="center" alignItems="center">
              <Image src={lendSuccessIcon} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton m="8px" />
          <ModalBody pt={9} pb={2}>
            <VStack gap={5}>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text variant="body-md/medium" color="gray.6">
                  Congratulations! You’ve claimed
                </Text>
                <Text variant="body-xl/bold">
                  <Text as="span" color="blue.5">
                    {claimedAmount}
                  </Text>{' '}
                  TERM Tokens
                </Text>
              </Flex>
              <Text variant="body-sm/normal" color="gray.5">
                TERM tokens can be used to stake new positions immediately.
              </Text>

              <Button variant="primary" onClick={onStakeTokens}>
                Stake your tokens today
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter pb={5}>
            <HStack alignItems="center" justifyContent="center" width="100%">
              <Button
                variant="link"
                rightIcon={<FontAwesomeIcon icon={['far', 'arrow-up-right']} />}
                onClick={onAddRepoTokenToWallet}
              >
                <Text variant="body-sm/medium" color="gray.6">
                  Import token
                </Text>
              </Button>
            </HStack>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  )
}
