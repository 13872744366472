import { Box, Flex, VStack, useDisclosure } from '@chakra-ui/react'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Address, ProfileSocials, ProfileWallet } from '../../../../data/model'
import { useStorage } from '../../../../providers/storage'
import DeleteProfileConfirmationModal from '../DeleteProfileConfirmationModal'
import DeleteProfile from './DeleteProfile'
import ManageProfileNav from './ManageProfileNav'
import PersonalInformation from './PersonalInformation'
import SocialSignIn from './SocialSignIn'
import WalletAddresses from './WalletAddresses'
import { isAddress } from 'ethers/lib/utils'

export default function ProfileManagement({
  username,
  profileImageUrl,
  wallets,
  socials,
  onLinkExtraWallet,
  onLinkMultiSigWallet,
  onLinkTwitter,
  onLinkDiscord,
  onRemoveWallet,
  onRemoveTwitter,
  onRemoveDiscord,
  onDeleteProfile,
  connectedWallet,
  redirectPath,
  provider,
  onUpdatePrimaryWallet,
}: {
  username?: string
  profileImageUrl?: string
  wallets?: ProfileWallet[]
  socials: ProfileSocials
  onLinkExtraWallet: () => Promise<void>
  onLinkMultiSigWallet: (address: Address) => Promise<void>
  onLinkTwitter: (redirPath?: string) => void
  onLinkDiscord: (redirPath?: string) => Promise<void>
  onRemoveWallet: (walletAddr: string, isContractAccount: boolean) => void
  onRemoveTwitter: () => void
  onRemoveDiscord: () => void
  onDeleteProfile: () => void
  connectedWallet?: string
  redirectPath: string
  provider: JsonRpcProvider | FallbackProvider | undefined
  onUpdatePrimaryWallet: (address: Address) => Promise<void>
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [confirmationDetails, setConfirmationDetails] = useState({
    title: '',
    body: '',
    actionText: '',
    onConfirm: () => {},
  })

  const handleOpenConfirmModal = (
    title: string,
    body: string,
    actionText: string,
    onConfirm: () => void
  ) => {
    setConfirmationDetails({ title, body, actionText, onConfirm })
    onOpen()
  }

  const { storage } = useStorage()
  const isProfileLoggedIn = storage.getItem('profile-token') !== null
  const navigate = useNavigate()

  useEffect(() => {
    if (!isProfileLoggedIn) {
      navigate('/')
    }
  }, [isProfileLoggedIn, navigate])

  const checkIsContractAccount = useCallback(
    async (address: Address) => {
      if (
        provider &&
        address &&
        isAddress(address) &&
        (await provider.getCode(address)) !== '0x'
      ) {
        return true
      } else {
        return false
      }
    },
    [provider]
  )

  return (
    <ManageProfileNav redirectPath={redirectPath}>
      <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto">
        <Flex w="100%" flexDirection="column" pt={4}>
          <VStack spacing={20} alignItems="start">
            <PersonalInformation
              username={username}
              profileImageUrl={profileImageUrl}
            />
            <SocialSignIn
              socials={socials}
              onLinkTwitter={onLinkTwitter}
              onLinkDiscord={onLinkDiscord}
              onRemoveTwitter={() =>
                handleOpenConfirmModal(
                  'Are you sure?',
                  `You will no longer be able to sign in with X using ${socials?.twitter}.`,
                  'Remove',
                  onRemoveTwitter
                )
              }
              onRemoveDiscord={() =>
                handleOpenConfirmModal(
                  'Are you sure?',
                  `You will no longer be able to sign in with Discord using ${socials?.discord}.`,
                  'Remove',
                  onRemoveDiscord
                )
              }
            />
            <WalletAddresses
              wallets={wallets}
              connectedWallet={connectedWallet}
              onLinkExtraWallet={onLinkExtraWallet}
              onLinkMultiSigWallet={onLinkMultiSigWallet}
              onCheckIsContractAccount={checkIsContractAccount}
              onRemoveWallet={(
                walletAddress: Address,
                isContractAccount: boolean
              ) =>
                handleOpenConfirmModal(
                  'Are you sure?',
                  isContractAccount === true
                    ? 'Are you sure you want to remove the Gnosis Safe account from your profile?'
                    : 'Are you sure you want to remove this wallet?',
                  'Remove wallet',
                  () => onRemoveWallet(walletAddress, isContractAccount)
                )
              }
              onUpdatePrimaryWallet={(walletAddress: Address) =>
                handleOpenConfirmModal(
                  'Update primary wallet?',
                  'Are you sure you want to update your primary wallet?',
                  'Update wallet',
                  () => onUpdatePrimaryWallet(walletAddress)
                )
              }
            />
            <DeleteProfile
              onDeleteProfile={() =>
                handleOpenConfirmModal(
                  'Delete Profile',
                  'Are you sure you would like to delete your profile? This action cannot be undone.',
                  'Delete profile',
                  onDeleteProfile
                )
              }
            />
          </VStack>
        </Flex>
      </Box>
      <DeleteProfileConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          confirmationDetails.onConfirm()
          onClose()
        }}
        title={confirmationDetails.title}
        body={confirmationDetails.body}
        actionText={confirmationDetails.actionText}
        variant={
          confirmationDetails.actionText === 'Update wallet'
            ? 'secondary'
            : undefined
        }
      />
    </ManageProfileNav>
  )
}
