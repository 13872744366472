import { Box, Link, Text } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import website_icon from '../../../../assets/icons/website.svg'
import twitter_icon from '../../../../assets/icons/twitter_black.svg'
import linkedin_icon from '../../../../assets/icons/linkedin_black.svg'
import { VaultStatistics } from '../../../../models/vault'

type Props = {
  vaultStatistics: VaultStatistics
}

export default function CuratorInfo({ vaultStatistics }: Props) {
  return (
    <Box w="full" bg="blue.0" p={8}>
      <Text variant="body-l/semibold" color="blue.9">
        {vaultStatistics?.vaultCurator}
      </Text>
      <HStack mt={1} alignItems="start" spacing={8}>
        <Box w="50%">
          <Text variant="body-md/normal" color="gray.6">
            {vaultStatistics?.about?.curator}
          </Text>

          <VStack mt={8} alignItems="start">
            {vaultStatistics?.links?.website && (
              <HStack>
                <img src={website_icon} alt="Website" />
                <Link
                  href={vaultStatistics?.links?.website}
                  isExternal
                  color="blue.5"
                  textDecoration="none"
                >
                  {vaultStatistics?.links?.website}
                </Link>
              </HStack>
            )}

            {vaultStatistics?.links?.twitter && (
              <HStack>
                <img src={twitter_icon} alt="Twitter" />
                <Link
                  href={vaultStatistics?.links?.twitter}
                  isExternal
                  color="blue.5"
                  textDecoration="none"
                >
                  {vaultStatistics?.links?.twitter}
                </Link>
              </HStack>
            )}

            {vaultStatistics?.links?.linkedIn && (
              <HStack>
                <img src={linkedin_icon} alt="Linkedin" />
                <Link
                  href={vaultStatistics?.links?.linkedIn}
                  isExternal
                  color="blue.5"
                  textDecoration="none"
                >
                  {vaultStatistics?.links?.linkedIn}
                </Link>
              </HStack>
            )}
          </VStack>
        </Box>
        <Box w="50%">
          <Text variant="body-md/normal" color="gray.6">
            <strong>Strategy:</strong> {vaultStatistics?.about?.strategy}
          </Text>
        </Box>
      </HStack>
    </Box>
  )
}
