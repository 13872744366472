import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Select,
  Skeleton,
  Spacer,
  StackProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'
import { ChainId, TransactionState, useEthers } from '@usedapp/core'
import dayjs from 'dayjs'
import { BigNumber, BigNumberish, FixedNumber, constants } from 'ethers'
import { solidityKeccak256 } from 'ethers/lib/utils'
import { Fragment, useMemo, useRef, useState } from 'react'
import multiChainIcon from '../../../../assets/icons/network/MultiChain.svg'
import sparkle from '../../../../assets/icons/sparkle.svg'
import Chip from '../../../../components/elements/Chip'
import ContractAddressModal from '../../../../components/elements/ContractAddressesModal'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack, VStack } from '../../../../components/elements/Stack'
import {
  Table,
  TableBody,
  TableFoot,
  TableHead,
  Td,
  Tr,
} from '../../../../components/elements/Table'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { useTokenIcon } from '../../../../data/hooks/use-token-icons'
import {
  Address,
  ElectRollover,
  LoanStatusEnum,
  RolloverAuctionInfo,
} from '../../../../data/model'
import { ERROR_STR } from '../../../../helpers/constants'
import {
  bigToFixedNumber,
  fixedToBigNumber,
  formatFixed,
} from '../../../../helpers/conversions'
import { add, fixedCompare, multiply, subtract } from '../../../../helpers/math'
import { NETWORK_COLORS } from '../../../../helpers/networkColors'
import { SortOrder, SortOrderEnum } from '../../../../helpers/sort'
import tokenIcons from '../../../../helpers/tokenIcons'
import {
  formatFixedPercentage,
  formatFixedToken,
  formatFixedUsd,
  getAuctionDisplayId,
  getDisplayToken,
} from '../../../../helpers/utils'
import { useColumnWidths } from '../../../../hooks/portfolio'
import { useChainConfig, useConfig } from '../../../../providers/config'
import LoadInterestRateModal from '../../../Auction/elements/OpenTenders/LoadInterestRateModal'
import { OpenBorrow, OpenBorrows, OpenLoan, OpenLoans } from '../../utils'
import BorrowerCancelRolloverModal from '../BorrowerCancelRolloverModal'
import BorrowerRolloverModal from '../BorrowerRolloverModal'
import CancelListingModal from '../CancelListingModal'
import CollapseBorrowModal from '../CollapseBorrowModal'
import ElectedRolloverTooltip from '../ElectedRolloverTooltip'
import ListTokensModal from '../ListTokensModal'
import LoanLiquidationModal from '../LoanLiquidationModal'
import LoanMorePopout from '../LoanMorePopout'
import LoanStatus from '../LoanStatus'
import ManageMarginModal from '../ManageMarginModal'
import MultipleAuctionsTooltip from '../MultipleAuctionsTooltip'
import { PortfolioTableHead as Th } from '../PortfolioTableHead'
import RedeemModal from '../RedeemModal'
import RepayLoanModal from '../RepayLoanModal'
import ViewListingsModal from '../ViewListingsModal'
import BorrowMorePopout from './BorrowMorePopout'
// import {
//   OpenListingProceedsFooter,
//   OpenListingProceedsFooterHeader,
//   OpenListingProceedsHeader,
//   OpenListingProceedsRow,
// } from './OpenListingProceedsSection'

const one = FixedNumber.fromString('1')

export const invisibleStyles = {
  opacity: 0,
  pointerEvents: 'none',
} as const

export interface ColumnWidths {
  [key: string]: number
}

// Define column widths object
export const COLUMN_WIDTHS: ColumnWidths = {
  network: 122,
  status: 54,
  maturity: 65,
  repayRedeem: 77,
  outstanding: 126,
  initial: 91,
  interest: 61,
  assetSelection: 50,
  liquidationPrice: 69,
  required: 85,
  depositedAndExcess: 85,
  emptyColumn: 132,
}

export function MaturityDate({
  maturityTimestamp,
  isLarge,
}: {
  maturityTimestamp: number
  isLarge?: boolean
}) {
  const currentTime = useCurrentTime()
  const maturesSameYear =
    dayjs.unix(maturityTimestamp).format('YYYY') === currentTime.format('YYYY')
      ? true
      : false
  const formattedMaturityTimestamp = maturesSameYear
    ? dayjs.unix(maturityTimestamp).format('MMM D')
    : dayjs.unix(maturityTimestamp).format("MMM D 'YY")

  return (
    <VStack alignItems="flex-start" spacing={0}>
      <Text
        as="span"
        variant="body-xs/medium"
        lineHeight={3}
        marginBottom="2px !important"
      >
        {formattedMaturityTimestamp}
      </Text>
      <Text
        as="span"
        variant="body-xs/medium"
        fontSize={isLarge ? '11px' : 'inherit'}
        color="gray.4"
        marginTop="0 !important"
      >
        {dayjs.unix(maturityTimestamp).format('hh:mma')}
      </Text>
    </VStack>
  )
}

export function OpenLoanHeader({
  sortDirection,
  hasAssetColumn,
  isFirst,
  handleToggleSortOrder,
  chainId,
  numTenders,
  columnWidths,
}: {
  sortDirection: SortOrder
  hasAssetColumn: boolean
  isFirst?: boolean
  handleToggleSortOrder: () => void
  chainId?: string
  numTenders?: number
  columnWidths?: ColumnWidths
}) {
  const chainConfig = useChainConfig(chainId)

  return (
    <>
      <Tr
        bg={chainConfig ? NETWORK_COLORS[chainConfig.chainId] : 'gray.1'}
        noBorder
      >
        {/* Network */}
        <Th
          data-column={'network'}
          sub={
            numTenders && numTenders > 0 ? (
              <NetworkHeader
                chainId={chainConfig?.chainId}
                chainName={chainConfig?.chainName}
                numTenders={numTenders}
              />
            ) : (
              'Loan'
            )
          }
          width={columnWidths ? `${columnWidths.network}px` : ''}
          compact={!isFirst}
        />
        {/* Status */}
        <Th
          data-column={'status'}
          width={columnWidths ? `${columnWidths.status}px` : ''}
          sub="Status"
          compact={!isFirst}
        />
        {/* Maturity */}
        <Th
          data-column={'maturity'}
          width={columnWidths ? `${columnWidths.maturity}px` : ''}
          sub={
            <HStack
              alignItems="center"
              justifyContent="flex-start"
              onClick={handleToggleSortOrder}
              cursor="pointer"
            >
              <Text as="span" variant="body-xs/medium" color="blue.9">
                Maturity
              </Text>
              <Box
                color="blue.9"
                as="span"
                transform={
                  sortDirection === SortOrderEnum.ASC
                    ? 'rotate(0deg)'
                    : 'rotate(180deg)'
                }
                transition="transform 0.2s"
              >
                <FontAwesomeIcon
                  icon={['far', 'chevron-down']}
                  role="button"
                  width="10px"
                />
              </Box>
            </HStack>
          }
          compact={!isFirst}
        />
        {/* Redeem In */}
        <Th
          data-column={'repayRedeem'}
          sub="Redeem In"
          width={columnWidths ? `${columnWidths.repayRedeem}px` : ''}
          compact={!isFirst}
        />
        {/* Initial Loan */}
        <Th
          data-column={'initial'}
          width={columnWidths ? `${columnWidths.initial}px` : ''}
          sub="Initial Loan"
          textAlign="right"
          compact={!isFirst}
        />
        {/* Interest */}
        <Th
          data-column={'interest'}
          width={columnWidths ? `${columnWidths.interest}px` : ''}
          sub={
            <>
              <AprInterestRateTooltip />
              <Text as="span" pl={1}>
                Interest
              </Text>
            </>
          }
          textAlign="right"
          compact={!isFirst}
        />
        {/* Outstanding Credit */}
        <Th
          data-column={'outstanding'}
          width={columnWidths ? `${columnWidths.outstanding}px` : ''}
          sub="Outstanding Credit"
          textAlign="right"
          compact={!isFirst}
        />
        {/* Bomb Pot */}
        <Th
          sub="Bomb Pot"
          data-column={'bomb-pot'}
          width={columnWidths ? `${columnWidths.assetSelection}px` : ''}
          // textAlign="right"
          compact={!isFirst}
          leftBorder
        >
          {isFirst ? (
            <Text
              as="span"
              variant="body-xs/bold"
              color="blue.9"
              textAlign="center"
              w="full"
              pos="relative"
              left="50%"
            >
              Extra Incentives
            </Text>
          ) : null}
        </Th>
        {/* Implied apy */}
        <Th
          sub="Implied APY"
          data-column={'implied-apy'}
          textAlign="right"
          rightBorder
          compact={!isFirst}
          width={columnWidths ? `${columnWidths.liquidationPrice}px` : ''}
        />
        {/* Open Listings */}
        <Th
          sub="Open"
          data-column={'open'}
          textAlign="right"
          compact={!isFirst}
          justifyChildren="flex-end"
          width={columnWidths ? `${columnWidths.required}px` : ''}
          addSubTextMargin={isFirst}
          spacing={0}
        >
          {isFirst ? (
            <Text
              as="span"
              variant="body-xs/bold"
              color="blue.9"
              textAlign="center"
              w="full"
              pos="relative"
              left="50%"
            >
              Listings
            </Text>
          ) : null}
        </Th>
        {/* Sold Listings */}
        <Th
          sub="Sold"
          data-column={'sold'}
          textAlign="right"
          compact={!isFirst}
          width={columnWidths ? `${columnWidths.depositedAndExcess}px` : ''}
        />
        {/* Empty Column */}
        <Th
          {...invisibleStyles}
          data-coloumn={'emptyColumn'}
          width={columnWidths ? `${columnWidths.emptyColumn}px` : ''}
          compact={!isFirst}
        />
      </Tr>
    </>
  )
}

function OpenLoanFooterHeader({ numTenders }: { numTenders?: number }) {
  return (
    <Tr bg="gray.1" noBorder verticalAlign="center">
      <Th
        colSpan={4}
        compact
        sub={<NetworkHeader allNetworks={true} numTenders={numTenders} />}
      />
      {/* Total Initial Loans USD */}
      <Th textAlign="right" compact sub={t`Initial Loan`} />
      {/* Total Interest USD */}
      <Th textAlign="right" compact sub={t`Interest`} />
      {/* Total Outstanding Credit USD */}
      <Th textAlign="right" compact sub={t`Outstanding Credit`} />
      {/* Empty Column */}
      <Th compact spacing={0} />
      {/* Empty Column */}
      <Th compact spacing={0} />
      {/* Empty Column */}
      <Th compact spacing={0} />
      {/* Empty Column */}
      <Th compact spacing={0} />
      {/* Empty Column */}
      <Th compact spacing={0} />
    </Tr>
  )
}

function OpenLoanFooter({
  outstandingCreditUsd,
  initialLoanUsd,
  interestUsd,
  openListings,
  openListingsUsd,
  soldListings,
  soldListingsUsd,
  isSubtotal,
}: {
  outstandingCreditUsd: FixedNumber
  initialLoanUsd: FixedNumber
  interestUsd: FixedNumber
  openListings: FixedNumber
  openListingsUsd: FixedNumber
  soldListings: FixedNumber
  soldListingsUsd: FixedNumber
  isSubtotal?: boolean
}) {
  const tdProps = isSubtotal
    ? {
        pl: '29px',
      }
    : {
        h: '56px',
      }

  const _variant = isSubtotal ? 'body-xs/semibold' : 'body-xs/bold'
  const _title = isSubtotal ? t`Subtotal` : t`Total`

  const hideListings = openListings.isZero() && openListings.isZero()

  return (
    <Tr noBorder>
      <Td colSpan={4} {...tdProps}>
        <Text as="span" variant="body-sm/semibold" color="blue.9" mr="4px">
          {_title}
        </Text>
        <Chip size="sm">
          <Trans>USD</Trans>
        </Chip>
      </Td>
      {/* Total Initial Loans USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(initialLoanUsd)}</Text>
      </Td>
      {/* Total Interest USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(interestUsd)}</Text>
      </Td>
      {/* Total Outstanding Credit USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(outstandingCreditUsd)}</Text>{' '}
      </Td>
      {/* Empty Column */}
      <Td leftBorder />
      {/* Empty Column */}
      <Td />
      {/* Total Listings Open */}
      <Td leftBorder textAlign="right">
        {!hideListings && (
          <Text variant={_variant}>{formatFixedUsd(openListingsUsd)}</Text>
        )}
      </Td>
      {/* Total Listings Sold */}
      <Td textAlign="right">
        {!hideListings && (
          <Text variant={_variant}>{formatFixedUsd(soldListingsUsd)}</Text>
        )}
      </Td>
      {/* Empty Column */}
      <Td leftBorder />
    </Tr>
  )
}

function OpenLoanRow({
  chainId,
  loan,
  listingDiscountRateMarkup,
  listingMinimumListingAmount,
  onApproveTermRepoTokens,
  onRedeem,
  onAddTermToken,
  onKytCheck,
  onCreateListing,
  onCancelListings,
}: {
  chainId: string
  loan: OpenLoan
  listingDiscountRateMarkup: BigNumber
  listingMinimumListingAmount: FixedNumber
  onApproveTermRepoTokens: (
    spender: string,
    amount: BigNumberish
  ) => Promise<void>
  onRedeem: (
    termTokensToBurn: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  onAddTermToken: (
    chainId: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number
  ) => Promise<boolean>
  onKytCheck: () => Promise<boolean>
  onCreateListing: (
    chainId: string,
    termRepoToken: Address,
    listingAmount: FixedNumber
  ) => Promise<void>
  onCancelListings: (
    chainId: string,
    listingIds: number[],
    skipRedeem?: boolean
  ) => Promise<void>
}) {
  const {
    isOpen: isRedeemModalOpen,
    onOpen: onRedeemModalOpen,
    onClose: onRedeemModalClose,
  } = useDisclosure()
  const {
    isOpen: isContractAddressOpen,
    onOpen: onContractAddressOpen,
    onClose: onContractAddressClose,
  } = useDisclosure()
  const {
    isOpen: isCancelListingOpen,
    onOpen: onCancelListingOpen,
    onClose: onCancelListingClose,
  } = useDisclosure()
  const {
    isOpen: isListTokensOpen,
    onOpen: onListTokensOpen,
    onClose: onListTokensClose,
  } = useDisclosure()
  const {
    isOpen: isViewListingsOpen,
    onOpen: onViewListingsOpen,
    onClose: onViewListingsClose,
  } = useDisclosure()

  const auctionLabel = getAuctionDisplayId({
    termSymbol: loan.purchaseTokenSymbol,
    collateralSymbol: loan.collateralCurrency.symbol,
    maturityTimestamp: loan.maturityTimestamp,
    auctionEndTimestamp: loan.auctionInfo?.[0]?.auctionEndTimestamp ?? 0,
  })

  const currentTime = useCurrentTime()
  const config = useConfig()
  const chain = config.chains[chainId]

  const isMatured = dayjs.unix(loan.maturityTimestamp).isBefore(currentTime)
  const pastRedemption = dayjs
    .unix(loan.redemptionTimestamp)
    .isBefore(currentTime)

  const hasMultipleAuctionsWithSameTerm = loan.auctionInfo.length > 1

  let initialLoanValue: FixedNumber
  let interestValue: FixedNumber

  if (!hasMultipleAuctionsWithSameTerm) {
    initialLoanValue = loan.auctionInfo[0].loanPrincipal
    interestValue = loan.auctionInfo[0].loanInterest
  } else {
    initialLoanValue = FixedNumber.fromString(
      '0',
      `fixed128x${loan.purchaseCurrency.decimals}`
    )
    interestValue = FixedNumber.fromString(
      '0',
      `fixed128x${loan.purchaseCurrency.decimals}`
    )

    loan.auctionInfo.forEach((auction) => {
      initialLoanValue = add(initialLoanValue, auction.loanPrincipal)
      interestValue = add(interestValue, auction.loanInterest)
    })
  }

  const BOMB_POT_TOKEN_SYMBOL = 'WSTETH'
  const hasBombPot = loan?.bombPotApy && loan?.bombPotReward

  const auctionClearingRate = loan.auctionInfo?.[0]?.auctionClearingRate
  const bombPotApy = loan?.bombPotApy
  const ZERO = FixedNumber.from('0')
  const impliedApy = add(auctionClearingRate ?? ZERO, bombPotApy ?? ZERO)

  const onApproveTermRepoToken = async (amount: BigNumberish) => {
    if (chain.listingsContractAddress) {
      await onApproveTermRepoTokens(chain.listingsContractAddress, amount)
    }
  }

  const hasListings =
    fixedCompare(loan.openListings, 'gt', FixedNumber.fromString('0')) ||
    fixedCompare(loan.soldListings, 'gt', FixedNumber.fromString('0'))

  const hasOpenListings = fixedCompare(
    loan.openListings,
    'gt',
    FixedNumber.fromString('0')
  )

  const isFullyListed =
    hasListings && subtract(loan.openListings, loan.soldListings).isZero()

  return (
    <>
      <Tr noBorder>
        {/* Network */}
        <Td large verticalAlign="bottom" pl="34px">
          <LoanCurrenciesAndTerm
            purchaseTokenSymbol={loan.purchaseCurrency.symbol}
            collateralTokenSymbol={loan.collateralCurrency.symbol}
            loanTerm={loan.termPeriodLength}
          />
        </Td>
        {/* Status */}
        <Td large verticalAlign="bottom">
          <VStack alignItems="left" spacing={0}>
            <LoanStatus
              status={isMatured ? 'matured' : 'open'}
              addPaddingBottom
            />
            <br />
          </VStack>
        </Td>
        {/* Maturity */}
        <Td textAlign="left" large verticalAlign="bottom">
          <MaturityDate maturityTimestamp={loan.maturityTimestamp} isLarge />
        </Td>
        {/* Redeem in */}
        <Td large verticalAlign="bottom">
          <Tooltip
            noDelay
            shouldWrapChildren
            label={dayjs.unix(loan.redemptionTimestamp).format('MMM D, hh:mma')}
          >
            <VStack alignItems="flex-start" spacing={0}>
              <Text variant="body-xs/medium" marginBottom="4px">
                {dayjs
                  .unix(loan.redemptionTimestamp)
                  .from(currentTime)
                  .charAt(0)
                  .toUpperCase() +
                  dayjs
                    .unix(loan.redemptionTimestamp)
                    .from(currentTime)
                    .slice(1)}
              </Text>
              <br />
            </VStack>
          </Tooltip>
        </Td>
        {/* Initial Loan */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text variant="body-xs/medium">
              {formatFixedToken(
                initialLoanValue,
                loan.purchaseCurrency.symbol,
                true
              )}
            </Text>
            {!initialLoanValue.isZero() ? (
              hasMultipleAuctionsWithSameTerm ? (
                <MultipleAuctionsTooltip
                  auctionInfo={loan.auctionInfo}
                  position="supply"
                  purchaseTokenSymbol={loan.purchaseCurrency.symbol}
                >
                  <Text
                    as="span"
                    variant="body-xs/medium"
                    fontSize="11px"
                    color="gray.4"
                    marginTop="0 !important"
                  >
                    <Trans>{loan.auctionInfo.length} auctions</Trans>
                  </Text>
                </MultipleAuctionsTooltip>
              ) : (
                <Text
                  as="span"
                  variant="body-xs/medium"
                  fontSize="11px"
                  color="gray.4"
                  marginTop="0 !important"
                >
                  {loan.auctionInfo?.[0]?.auctionEndTimestamp
                    ? dayjs
                        .unix(loan.auctionInfo[0].auctionEndTimestamp)
                        .format('MMM D')
                    : '-'}
                </Text>
              )
            ) : (
              <br />
            )}
          </VStack>
        </Td>
        {/* Interest */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text variant="body-xs/medium">
              {formatFixedToken(
                interestValue,
                loan.purchaseCurrency.symbol,
                true
              )}
            </Text>
            {!initialLoanValue.isZero() ? (
              hasMultipleAuctionsWithSameTerm ? (
                <MultipleAuctionsTooltip
                  auctionInfo={loan.auctionInfo}
                  position="supply"
                  purchaseTokenSymbol={loan.purchaseCurrency.symbol}
                >
                  <Text
                    as="span"
                    variant="body-xs/medium"
                    fontSize="11px"
                    color="gray.4"
                    marginTop="0 !important"
                  >
                    <Trans>Via {loan.auctionInfo.length} auctions</Trans>
                  </Text>
                </MultipleAuctionsTooltip>
              ) : (
                <Text
                  as="span"
                  variant="body-xs/medium"
                  fontSize="11px"
                  color="gray.4"
                  marginTop="0 !important"
                >
                  {auctionClearingRate
                    ? formatFixedPercentage(auctionClearingRate)
                    : '-'}
                </Text>
              )
            ) : (
              <br />
            )}
          </VStack>
        </Td>
        {/* Outstanding Credit */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text
              variant="body-xs/medium"
              fontWeight="semibold"
              marginBottom="4px"
            >
              {formatFixedToken(
                loan.outstandingCredit,
                loan.purchaseCurrency.symbol,
                true
              )}
            </Text>
            <br />
          </VStack>
        </Td>
        {/* Bomb Pot */}
        <Td leftBorder textAlign="right" large verticalAlign="bottom">
          {hasBombPot && (
            <HStack gap={'4px'} alignItems="start" justify="end">
              <Image
                boxSize="16px"
                src={tokenIcons[BOMB_POT_TOKEN_SYMBOL]}
                alt={BOMB_POT_TOKEN_SYMBOL}
              />
              <VStack gap={0}>
                <Text variant="body-xs" lineHeight="13px" color="blue.9">
                  {formatFixed(loan?.bombPotReward, { displayDecimals: 3 })}
                </Text>
                <Text fontSize="11px" lineHeight="13px" color="gray.4">
                  {formatFixedPercentage(bombPotApy)}
                </Text>
              </VStack>
            </HStack>
          )}
        </Td>
        {/* Implied APY */}
        <Td rightBorder textAlign="right" large verticalAlign="bottom">
          {hasBombPot && (
            <HStack justify="end">
              <Tooltip
                noDelay
                placement="bottom-start"
                aria-label="implied-apy-tooltip"
                offset={[-4, 8]}
                // rtl is not working need to open issue with chakra
                direction="rtl"
                label={
                  <Box w={'228px'}>
                    <HStack mb={4} justify="space-between">
                      <Text variant="body-md/bold">Implied APY:</Text>
                      <Text width="60px" variant="body-md/bold">
                        {formatFixedPercentage(impliedApy)}
                      </Text>
                    </HStack>
                    <HStack justify="space-between">
                      <Text variant="body-sm/normal">Clearing rate:</Text>
                      <Text variant="body-sm/normal">
                        {auctionClearingRate
                          ? formatFixedPercentage(auctionClearingRate)
                          : '-'}
                      </Text>
                    </HStack>
                    <HStack justify="space-between">
                      <Text variant="body-sm/normal">
                        {BOMB_POT_TOKEN_SYMBOL} rewards:
                      </Text>
                      <Text variant="body-sm/normal">
                        {bombPotApy ? formatFixedPercentage(bombPotApy) : '-'}
                      </Text>
                    </HStack>
                  </Box>
                }
              >
                <Image boxSize="16px" src={sparkle} alt={'Implied APY'} />
              </Tooltip>
              <Text width="60px">
                {formatFixedPercentage(impliedApy, 3, undefined)}
              </Text>
            </HStack>
          )}
        </Td>
        {/* Open Listings */}
        <Td leftBorder textAlign="right" large verticalAlign="bottom">
          {hasListings && (
            <VStack alignItems="flex-end" spacing={0}>
              <Text
                variant="body-xs/medium"
                fontWeight="semibold"
                marginBottom="4px"
              >
                {formatFixedToken(
                  loan.openListings,
                  loan.purchaseCurrency.symbol,
                  true
                )}
              </Text>
              <br />
            </VStack>
          )}
        </Td>
        {/* Sold Listings */}
        <Td rightBorder textAlign="right" large verticalAlign="bottom">
          {hasListings && (
            <VStack alignItems="flex-end" spacing={0}>
              <Text
                as="span"
                variant="body-xs/normal"
                color="blue.9"
                lineHeight={3}
                marginBottom="2px !important"
              >
                {formatFixedToken(
                  loan.soldListings,
                  loan.purchaseCurrency.symbol,
                  true
                )}
              </Text>
              <Text
                as="span"
                variant="body-xs/normal"
                color="gray.4"
                marginTop="0 !important"
                fontSize={'11px'}
              >
                {formatFixedUsd(loan.soldListingsUsd, false)}
              </Text>
            </VStack>
          )}
        </Td>
        {/* Actions */}
        <Td large verticalAlign="top">
          <HStack right={0} position="absolute" marginTop="-4px">
            <Spacer />
            {pastRedemption &&
              (hasOpenListings ? (
                <Tooltip
                  noDelay
                  infoIcon={false}
                  iconColor="gray"
                  aria-label="repo-token-matured-tooltip"
                  label="The repo token has matured. Cancel your listing first before redeeming. "
                >
                  <Button
                    size="xs"
                    variant="primary"
                    minW={16}
                    onClick={() => onCancelListingOpen()}
                  >
                    Cancel Listing
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  size="xs"
                  variant="primary"
                  minW={16}
                  onClick={() => onRedeemModalOpen()}
                >
                  <Trans>Redeem</Trans>
                </Button>
              ))}
            <LoanMorePopout
              isPastRedemption={pastRedemption}
              hasListings={hasListings}
              hasOpenListings={hasOpenListings}
              isFullyListed={isFullyListed}
              termTokenAddress={loan?.termTokenCurrency?.address}
              etherscanUrl={chain?.blockExplorerUrl}
              auctionId={loan?.auctionInfo?.[0]?.auctionId}
              onAddTermToken={() => {
                // TODO (m.walsh): add kyt status check here?
                onAddTermToken(
                  chainId,
                  loan.termTokenCurrency.address,
                  loan.termTokenCurrency.symbol,
                  loan.termTokenCurrency.decimals
                )
              }}
              onContractAddress={() => onContractAddressOpen()}
              onListTokens={() => onListTokensOpen()}
              onViewListings={() => onViewListingsOpen()}
              onCancelListing={() => onCancelListingOpen()}
            />
          </HStack>
        </Td>
      </Tr>
      <RedeemModal
        isOpen={isRedeemModalOpen}
        onClose={onRedeemModalClose}
        isReceivedViaTokenTransfer={loan.isRepoTokenTransfer}
        collateralCurrency={loan.collateralCurrency}
        purchaseCurrency={loan.purchaseCurrency}
        termTokenCurrency={loan.termTokenCurrency}
        purchasePrice={loan.purchaseTokenPrice}
        termTokenPrice={loan.termTokenPrice}
        purchaseBalance={loan.purchaseBalance}
        termTokenBalance={loan.termTokenBalance}
        termTokenInterest={fixedToBigNumber(interestValue)}
        termTokenPrincipal={fixedToBigNumber(initialLoanValue)}
        maturityTimestamp={loan.maturityTimestamp}
        rolloverAmount={
          loan.rolloverAmount
            ? fixedToBigNumber(loan.rolloverAmount)
            : BigNumber.from(0)
        }
        hasOutstandingRepoExposure={loan.hasOutstandingRepoExposure}
        onRedeem={onRedeem}
        auctionLabel=""
        onKytCheck={onKytCheck}
      />
      <ContractAddressModal
        isOpen={isContractAddressOpen}
        onClose={onContractAddressClose}
        chainId={chainId}
        auctionLabel={auctionLabel}
        auction={loan.contractAddresses.auction}
        bidLocker={loan.contractAddresses.auctionBidLocker}
        offerLocker={loan.contractAddresses.auctionOfferLocker}
        repoLocker={loan.contractAddresses.termRepoLocker}
        repoServicer={loan.contractAddresses.termRepoServicer}
        collateralManager={loan.contractAddresses.termCollateralManager}
        rolloverManager={loan.contractAddresses.termRolloverManager}
        termRepoToken={loan.contractAddresses.termRepoToken}
      />
      <ListTokensModal
        isOpen={isListTokensOpen}
        onClose={onListTokensClose}
        chainId={loan.chainId}
        listingContractAddress={chain.listingsContractAddress}
        listingRate={subtract(
          loan.auctionInfo?.[0]?.auctionClearingRate ??
            FixedNumber.fromString('0', 18),
          bigToFixedNumber(listingDiscountRateMarkup, 18)
        )}
        auctionClearingRate={
          loan.auctionInfo?.[0]?.auctionClearingRate ??
          FixedNumber.fromString('0', 18)
        }
        listingMarkup={bigToFixedNumber(listingDiscountRateMarkup, 18)}
        auctionId={loan.auctionInfo?.[0]?.auctionId ?? ''}
        purchaseTokenCurrency={loan.purchaseCurrency}
        termTokenCurrency={loan.termTokenCurrency}
        termTokenBalance={loan.termTokenBalance}
        minimumListingAmount={listingMinimumListingAmount}
        approvedAmount={loan.termRepoTokensApproved}
        onKytCheck={onKytCheck}
        onApproveTermRepoToken={onApproveTermRepoToken}
        onCreateListing={onCreateListing}
      />
      <CancelListingModal
        isOpen={isCancelListingOpen}
        isMature={pastRedemption}
        purchaseCurrencySymbol={loan.purchaseCurrency?.symbol}
        chainId={chainId}
        onClose={onCancelListingClose}
        termTokenCurrency={loan.termTokenCurrency}
        tokenListings={
          loan.listings?.listings?.transactions.filter(
            (tx) => !tx.cancelled && !tx.remainingAmount.isZero()
          ) ?? []
        }
        listingsChecked={false}
        onCancelListings={onCancelListings}
      />
      {loan.listings?.listings && (
        <ViewListingsModal
          isOpen={isViewListingsOpen}
          onClose={onViewListingsClose}
          chainId={chainId}
          listingPurchaseCurrency={loan.purchaseCurrency}
          totalListed={add(loan.openListings, loan.soldListings)}
          totalOpenListings={loan.openListings}
          totalProceedsPurchaseCurrency={loan.listings.listings.totalCost}
          listingTransactions={loan.listings.listings.transactions}
        />
      )}
    </>
  )
}

export const NetworkHeader = ({
  chainId,
  chainName,
  numTenders,
  allNetworks,
  spacing = 0,
}: {
  chainId?: ChainId
  chainName?: string
  numTenders?: number
  allNetworks?: boolean
  spacing?: StackProps['spacing']
}) => (
  // [DOSPORE] hacky fix of offset
  <HStack mt={'-2px'} spacing={spacing}>
    {allNetworks ? (
      <Image boxSize="16px" src={multiChainIcon} />
    ) : (
      !!chainId && <NetworkIcon chainId={chainId} />
    )}
    <Trans>
      <Text
        as="span"
        variant="body-sm/medium"
        color="blue.9"
        ml={'8px'}
        mr="8px"
      >
        {allNetworks ? 'All Networks' : (chainName ?? 'Unknown')}
      </Text>
    </Trans>
    {numTenders && <Text color="gray.4" ml={0}>{`| ${numTenders}`}</Text>}
  </HStack>
)

export function OpenBorrowHeader({
  asset,
  sortDirection,
  handleToggleSortOrder,
  onChangeAsset,
  chainId,
  numTenders,
  isFirst,
  columnWidths,
}: {
  asset: '' | 'ratio' | 'usd'
  onChangeAsset?: (asset: '' | 'ratio' | 'usd') => void
  sortDirection: SortOrder
  handleToggleSortOrder: () => void
  chainId?: string
  numTenders?: number
  isFirst?: boolean
  columnWidths?: ColumnWidths
}) {
  const chainConfig = useChainConfig(chainId)

  return (
    <Tr
      bg={chainConfig ? NETWORK_COLORS[chainConfig.chainId] : 'gray.1'}
      noBorder
    >
      {/* Network */}
      <Th
        data-column={'network'}
        sub={
          numTenders && numTenders > 0 ? (
            <NetworkHeader
              chainId={chainConfig?.chainId}
              chainName={chainConfig?.chainName}
              numTenders={numTenders}
            />
          ) : (
            t`Loan`
          )
        }
        width={columnWidths ? `${columnWidths.network}px` : ''}
        compact={!isFirst}
      />
      {/* Status */}
      <Th
        data-column={'status'}
        sub={t`Status`}
        width={columnWidths ? `${columnWidths.status}px` : ''}
        compact={!isFirst}
      />
      {/* Maturity */}
      <Th
        data-column={'maturity'}
        width={columnWidths ? `${columnWidths.maturity}px` : ''}
        sub={
          <HStack
            alignItems="center"
            justifyContent="flex-start"
            onClick={handleToggleSortOrder}
            cursor="pointer"
          >
            <Trans>
              <Text as="span" variant="body-xs/medium" color="blue.9">
                Maturity
              </Text>
            </Trans>
            <Box
              color="blue.9"
              as="span"
              transform={
                sortDirection === SortOrderEnum.ASC
                  ? 'rotate(0deg)'
                  : 'rotate(180deg)'
              }
              transition="transform 0.2s"
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-down']}
                role="button"
                width="10px"
              />
            </Box>
          </HStack>
        }
        compact={!isFirst}
      />
      {/* Repay By */}
      <Th
        data-column={'repayRedeem'}
        sub={t`Repay By`}
        width={columnWidths ? `${columnWidths.repayRedeem}px` : ''}
        compact={!isFirst}
      />
      {/* Initial Borrow */}
      <Th
        data-column={'initial'}
        sub={t`Initial Borrow`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.initial}px` : ''}
        compact={!isFirst}
      />
      {/* Interest */}
      <Th
        data-column={'interest'}
        sub={
          <>
            <AprInterestRateTooltip />
            <Trans>
              <Text as="span" pl={1}>
                Interest
              </Text>
            </Trans>
          </>
        }
        textAlign="right"
        width={columnWidths ? `${columnWidths.interest}px` : ''}
        compact={!isFirst}
      />
      {/* Outstanding Debt */}
      <Th
        data-column={'outstanding'}
        sub={t`Outstanding Debt`}
        width={columnWidths ? `${columnWidths.outstanding}px` : ''}
        textAlign="right"
        compact={!isFirst}
      />
      {/* Change Asset Dropdown */}
      <Th
        data-column={'assetSelection'}
        width={columnWidths ? `${columnWidths.assetSelection}px` : ''}
        sub={
          onChangeAsset ? (
            <Select
              size="sm"
              h={5}
              w="max-content"
              borderColor="transparent"
              fontWeight="semibold"
              borderRadius="md"
              color="gray.6"
              bg="gray.2"
              fontSize="12px"
              onChange={(e) =>
                onChangeAsset(e.target.value as '' | 'ratio' | 'usd')
              }
              value={asset}
            >
              <option value="">
                <Trans>Asset</Trans>
              </option>
              <option value="ratio">
                <Trans>Ratio</Trans>
              </option>
              <option value="usd">
                <Trans>USD</Trans>
              </option>
            </Select>
          ) : (
            t`Asset`
          )
        }
        leftBorder
        compact={!isFirst}
      />

      {/* Liquidation Price / Oracle */}
      <Th
        sub={t`Liq. Price`}
        data-column={'liquidationPrice'}
        textAlign="right"
        // leftBorder={!onChangeAsset}
        compact={!isFirst}
        width={columnWidths ? `${columnWidths.liquidationPrice}px` : ''}
      />
      {/* Collateral Required */}
      <Th
        sub={t`Required`}
        data-column={'required'}
        textAlign="right"
        compact={!isFirst}
        justifyChildren="flex-end"
        width={columnWidths ? `${columnWidths.required}px` : ''}
        addSubTextMargin={isFirst}
        spacing={0}
      >
        {isFirst ? (
          <Text h="20px" lineHeight="20px" fontWeight={'bold'}>
            Collateral
          </Text>
        ) : null}
      </Th>
      {/* Collateral Deposited/Excess */}
      <Th
        sub={t`Deposited`}
        data-column={'depositedAndExcess'}
        textAlign="right"
        compact={!isFirst}
        width={columnWidths ? `${columnWidths.depositedAndExcess}px` : ''}
      />
      {/* Empty Column */}
      <Th
        data-column={'emptyColumn'}
        {...invisibleStyles}
        width={columnWidths ? `${columnWidths.emptyColumn}px` : ''}
        compact={!isFirst}
      ></Th>
    </Tr>
  )
}

function OpenBorrowFooterHeader({ numTenders }: { numTenders?: number }) {
  return (
    <Tr bg="gray.1" noBorder>
      {/* Total USD */}
      <Th
        colSpan={4}
        sub={<NetworkHeader allNetworks={true} numTenders={numTenders} />}
        compact
      />
      {/* Total Initial Borrow USD */}
      <Th textAlign="right" sub={t`Initial Borrow`} compact />
      {/* Total Interest USD */}
      <Th textAlign="right" sub={t`Interest`} compact />
      {/* Total Outstanding Debt USD */}
      <Th textAlign="right" sub={t`Outstanding Debt`} compact />
      {/* Empty Column */}
      <Th leftBorder compact />
      {/* Empty Column (Liq. Price) */}
      <Th />
      {/* Total Required Collateral USD */}
      <Th textAlign="right" sub={t`Required`} compact />
      {/* Total Deposited Collateral USD */}
      <Th textAlign="right" sub={t`Deposited`} compact />
      {/* Total Excess Collateral USD */}
      <Th />
    </Tr>
  )
}

function OpenBorrowFooter({
  isSubtotal,

  outstandingDebtUsd,
  initialBorrowUsd,
  interestUsd,

  requiredCollateralUsd,
  depositedCollateralUsd,
  excessCollateralUsd,
}: {
  isSubtotal?: boolean

  outstandingDebtUsd: FixedNumber
  initialBorrowUsd: FixedNumber
  interestUsd: FixedNumber

  requiredCollateralUsd: FixedNumber
  depositedCollateralUsd: FixedNumber
  excessCollateralUsd: FixedNumber
}) {
  const tdProps = isSubtotal
    ? {
        pl: '29px',
      }
    : {
        h: '56px',
      }

  const _variant = isSubtotal ? 'body-xs/semibold' : 'body-xs/bold'
  const _title = isSubtotal ? t`Subtotal` : t`Total`

  return (
    <Tr noBorder>
      {/* Total USD */}
      <Td colSpan={4} {...tdProps}>
        <Trans>
          <Text as="span" variant="body-sm/semibold" color="blue.9" mr="4px">
            {_title}
          </Text>
          <Chip size="sm">USD</Chip>
        </Trans>
      </Td>
      {/* Total Initial Borrow USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(initialBorrowUsd)}</Text>
      </Td>
      {/* Total Interest USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(interestUsd)}</Text>
      </Td>
      {/* Total Outstanding Debt USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(outstandingDebtUsd)}</Text>
      </Td>
      {/* Empty Column */}
      <Td leftBorder />
      {/* Empty Column (Liq. Price) */}
      <Td />
      {/* Total Required Collateral USD */}
      <Td textAlign="right">
        <Text variant={_variant}>{formatFixedUsd(requiredCollateralUsd)}</Text>
      </Td>
      {/* Total Deposited Collateral USD */}
      <Td textAlign="right" lineHeight={'13px'} paddingTop={'13px'}>
        <Flex flexDirection="column" alignItems="flex-end">
          <Text variant={_variant}>
            {formatFixedUsd(depositedCollateralUsd)}
          </Text>
          <Text color="gray.4" as="span">
            {formatFixedUsd(excessCollateralUsd)}
          </Text>
        </Flex>
      </Td>
      {/* Total Excess Collateral USD */}
      <Td />
    </Tr>
  )
}

function OpenBorrowRow({
  chainId,
  asset,
  borrow,
  rolloverAuctions,
  prices,
  isLoadingMissingRates,
  onApprovePurchaseTokens,
  onApproveCollateralTokens,
  onRepay,
  onLockCollateral,
  onUnlockCollateral,
  onElectRollover,
  onEditRollover,
  onCancelRollover,
  onCollapse,
  onKytCheck,
  onLoadMissingTenderRates,
}: {
  chainId: string
  asset: '' | 'ratio' | 'usd'
  borrow: OpenBorrow
  rolloverAuctions: RolloverAuctionInfo[]
  prices: {
    [chainId: string]: {
      [address: string]: FixedNumber
    }
  }
  isLoadingMissingRates: boolean
  onApprovePurchaseTokens: (
    spender: string,
    amount: BigNumberish
  ) => Promise<void>
  onApproveCollateralTokens: (
    spender: string,
    amount: BigNumberish
  ) => Promise<void>
  onRepay: (
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  onLockCollateral: (amount: FixedNumber, isGasToken: boolean) => Promise<void>
  onUnlockCollateral: (
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  onElectRollover: (electedRollover: ElectRollover) => Promise<void>
  onEditRollover: (electedRollover: ElectRollover) => Promise<void>
  onCancelRollover: () => Promise<void>
  onCollapse: (tokens: FixedNumber, isGasToken: boolean) => Promise<void>
  onKytCheck: () => Promise<boolean>
  onLoadMissingTenderRates: (
    chainId: string,
    bids: string[] | undefined,
    offers: string[] | undefined,
    isRollover: boolean
  ) => Promise<void>
}) {
  const [marginModalTab, setMarginModalTab] = useState<'deposit' | 'withdraw'>(
    'withdraw'
  )
  const {
    isOpen: isRepayModalOpen,
    onOpen: onRepayModalOpen,
    onClose: onRepayModalClose,
  } = useDisclosure()
  const {
    isOpen: isManageMarginOpen,
    onOpen: onManageMarginOpen,
    onClose: onManageMarginClose,
  } = useDisclosure()
  const {
    isOpen: isElectRolloverOpen,
    onOpen: onElectRolloverOpen,
    onClose: onElectRolloverClose,
  } = useDisclosure()
  const {
    isOpen: isCancelRolloverOpen,
    onOpen: onCancelRolloverOpen,
    onClose: onCancelRolloverClose,
  } = useDisclosure()
  const {
    isOpen: isCollapseBorrowOpen,
    onOpen: onCollapseBorrowOpen,
    onClose: onCollapseBorrowClose,
  } = useDisclosure()
  const {
    isOpen: isLiquidationModalOpen,
    onOpen: onLiquidationModalOpen,
    onClose: onLiquidationModalClose,
  } = useDisclosure()
  const {
    isOpen: isLoadInterestRateOpen,
    onClose: onLoadInterestRateClose,
    onOpen: onOpenLoadInterestRate,
  } = useDisclosure()
  const {
    isOpen: isContractAddressOpen,
    onOpen: onContractAddressOpen,
    onClose: onContractAddressClose,
  } = useDisclosure()

  const currentTime = useCurrentTime()
  const isMatured = dayjs.unix(borrow.maturityTimestamp).isBefore(currentTime)
  const pastRepayment = dayjs
    .unix(borrow.endOfRepaymentTimestamp)
    .isBefore(currentTime)
  const pastRedemption = dayjs
    .unix(borrow.redemptionTimestamp)
    .isBefore(currentTime)

  const getLoanStatus = (borrow: OpenBorrow) => {
    const repurchasePriceBn = fixedToBigNumber(borrow.repurchasePrice)
    const amountCollectedBn = fixedToBigNumber(borrow.amountCollected)

    if (isMatured) {
      if (!pastRepayment || amountCollectedBn.eq(repurchasePriceBn)) {
        return LoanStatusEnum.Matured
      } else if (
        pastRepayment &&
        amountCollectedBn.gt(0) &&
        amountCollectedBn.lt(repurchasePriceBn)
      ) {
        return LoanStatusEnum.PartLiquidatedAndMatured
      } else {
        return LoanStatusEnum.LiquidatedAndClosed
      }
    } else {
      if (!borrow.amountBorrowLiquidated.isZero()) {
        return LoanStatusEnum.LiquidatedAndOpen
      } else {
        return LoanStatusEnum.Open
      }
    }
  }

  const lastLiquidation = borrow.liquidations?.[0]
  const pastLiquidations = borrow.liquidations?.slice(1)

  const electedRolloverAuctionInfo = rolloverAuctions.filter(
    (a) => a.auctionBidLocker === borrow.rolloverAuctionBidLocker
  )

  const rolloverAuctionLabel = getAuctionDisplayId({
    termSymbol: borrow.purchaseTokenSymbol,
    collateralSymbol: borrow.collateralTokenSymbol,
    maturityTimestamp: electedRolloverAuctionInfo[0]
      ? electedRolloverAuctionInfo[0]?.maturityTimestamp
      : 0,
    auctionEndTimestamp: electedRolloverAuctionInfo[0]
      ? electedRolloverAuctionInfo[0]?.auctionEndTimestamp
      : 0,
  })

  const auctionLabel = getAuctionDisplayId({
    termSymbol: borrow.purchaseTokenSymbol,
    collateralSymbol: borrow.collateralTokenSymbol,
    maturityTimestamp: borrow.maturityTimestamp,
    auctionEndTimestamp: borrow.lastAuctionEndTimestamp ?? 0,
  })

  /**
   * Borrower elected a rollover that did not get fulfilled.
   * Show them a modal that will combine unlocking funds + repayment
   */
  const showRollInfo =
    borrow.rolloverFailed ||
    borrow.rolloverPartiallyFailed ||
    borrow.rolloverElected

  const onApprovePurchaseToken = async (amount: BigNumberish) => {
    if (borrow.repoLocker) {
      await onApprovePurchaseTokens(borrow.repoLocker, amount)
    }
  }

  const excessCollateral = subtract(
    borrow.collateralDeposited,
    borrow.collateralRequired
  )

  const { account } = useEthers()

  return (
    <>
      <Tr noBorder>
        {/* Network */}
        <Td large verticalAlign="bottom" pl="34px">
          <LoanCurrenciesAndTerm
            purchaseTokenSymbol={borrow.purchaseCurrency.symbol}
            collateralTokenSymbol={borrow.collateralCurrency.symbol}
            loanTerm={borrow.termPeriodLength}
          />
        </Td>
        {/* Status */}
        <Td large verticalAlign="bottom">
          <VStack alignItems="left" spacing={0}>
            <LoanStatus
              defaultLiquidation={borrow.liquidations?.[0]?.defaultLiquidation}
              status={getLoanStatus(borrow)}
              repaymentTimestamp={borrow.endOfRepaymentTimestamp}
              onViewLiquidationDetailsClick={onLiquidationModalOpen}
              addPaddingBottom
            />
            <br />
          </VStack>
        </Td>
        {/* Maturity */}
        <Td textAlign="left" large verticalAlign="bottom">
          <MaturityDate
            maturityTimestamp={borrow.endOfRepaymentTimestamp}
            isLarge
          />
        </Td>
        {/* Repay By */}
        <Td large verticalAlign="bottom">
          <Tooltip
            shouldWrapChildren
            noDelay
            label={dayjs
              .unix(borrow.endOfRepaymentTimestamp)
              .format('MMM D, hh:mma')}
          >
            <VStack alignItems="flex-start" spacing="4px">
              <Text variant="body-xs/medium">
                {dayjs
                  .unix(borrow.endOfRepaymentTimestamp)
                  .from(currentTime)
                  .charAt(0)
                  .toUpperCase() +
                  dayjs
                    .unix(borrow.endOfRepaymentTimestamp)
                    .from(currentTime)
                    .slice(1)}
              </Text>
              <br />
            </VStack>
          </Tooltip>
        </Td>
        {/* Initial Borrow */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text variant="body-xs/medium">
              {formatFixedToken(
                borrow.principalDebt,
                borrow.purchaseCurrency.symbol,
                true
              )}
            </Text>
            <Text
              as="span"
              variant="body-xs/medium"
              fontSize="11px"
              color="gray.4"
              marginTop="0 !important"
            >
              {borrow.lastAuctionEndTimestamp
                ? dayjs.unix(borrow.lastAuctionEndTimestamp).format('MMM D')
                : '-'}
            </Text>
          </VStack>
        </Td>
        {/* Interest */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text variant="body-xs/medium">
              {formatFixedToken(
                borrow.interestDebt,
                borrow.purchaseCurrency.symbol,
                true
              )}
            </Text>
            <Text
              as="span"
              variant="body-xs/medium"
              fontSize="11px"
              color="gray.4"
              marginTop="0 !important"
            >
              {borrow.lastAuctionClearingRate
                ? formatFixedPercentage(borrow.lastAuctionClearingRate, 3)
                : '-'}
            </Text>
          </VStack>
        </Td>
        {/* Outstanding Debt */}
        <Td textAlign="right" large verticalAlign="bottom">
          {showRollInfo ? (
            <Box>
              <Text fontWeight="semibold" variant="body-xs/medium">
                {formatFixedToken(
                  borrow.outstandingDebt,
                  borrow.purchaseCurrency.symbol,
                  true
                )}
              </Text>
              {borrow.rolloverFailed && (
                <Text
                  fontWeight="700"
                  color="orange.500"
                  variant="body-xs/medium"
                  fontSize="11px"
                >
                  <Trans>Roll failed</Trans>
                </Text>
              )}
              {borrow.rolloverPartiallyFailed && (
                <Text
                  fontWeight="700"
                  color="orange.500"
                  variant="body-xs/medium"
                  fontSize="11px"
                >
                  <Trans>Roll partially failed</Trans>
                </Text>
              )}
              {borrow.rolloverElected && (
                <Skeleton isLoaded={!isLoadingMissingRates}>
                  <ElectedRolloverTooltip
                    borrow={borrow}
                    electedRolloverAuctionInfo={electedRolloverAuctionInfo}
                    rolloverAuctionLabel={rolloverAuctionLabel}
                    isMatured={isMatured}
                    onElectRolloverOpen={onElectRolloverOpen}
                    onOpenLoadInterestRate={onOpenLoadInterestRate}
                    isLoadingMissingRates={isLoadingMissingRates}
                  />
                </Skeleton>
              )}
            </Box>
          ) : (
            <VStack alignItems="flex-end" spacing={0}>
              <Text
                fontWeight="semibold"
                variant="body-xs/medium"
                marginBottom="4px"
              >
                {formatFixedToken(
                  borrow.outstandingDebt,
                  borrow.purchaseCurrency.symbol,
                  true
                )}
              </Text>
              <br />
            </VStack>
          )}
        </Td>
        {/* Asset */}
        <Td leftBorder large verticalAlign="bottom">
          {asset === 'usd' || asset === 'ratio' ? (
            <></>
          ) : (
            <VStack alignItems="flex-start" spacing="0" mb="4px">
              <Chip fontWeight="600">{borrow.collateralTokenSymbol}</Chip>
              <br />
            </VStack>
          )}
        </Td>
        {/* Liq Price */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text
              as="span"
              variant="body-xs/normal"
              color="blue.9"
              lineHeight={3}
              marginBottom="2px !important"
            >
              {formatFixedToken(borrow.liquidationPrice, 'USD', true)}
            </Text>
            <Text
              as="span"
              variant="body-xs/normal"
              color="gray.4"
              marginTop="0 !important"
              fontSize={'11px'}
            >
              {formatFixedUsd(
                prices?.[chainId]?.[borrow.collateralCurrency.address],
                true
              )}
            </Text>
          </VStack>
        </Td>
        {/* Required */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text variant="body-xs/medium" marginBottom="4px">
              {asset === 'usd'
                ? formatFixedUsd(borrow.collateralRequiredUsd)
                : asset === 'ratio'
                  ? formatFixedPercentage(borrow.collateralRequiredRatio, 0)
                  : formatFixedToken(
                      borrow.collateralRequired,
                      borrow.collateralCurrency?.symbol,
                      true
                    )}
            </Text>
            <br />
          </VStack>
        </Td>
        {/* Deposited */}
        <Td textAlign="right" large verticalAlign="bottom">
          <VStack alignItems="flex-end" spacing={0}>
            <Text
              as="span"
              variant="body-xs/normal"
              color="blue.9"
              lineHeight={3}
              marginBottom="2px !important"
            >
              {asset === 'usd'
                ? formatFixedUsd(borrow.collateralDepositedUsd)
                : asset === 'ratio'
                  ? !fixedToBigNumber(borrow.collateralDepositedRatio).eq(
                      constants.MaxUint256
                    )
                    ? formatFixedPercentage(
                        borrow.collateralDepositedRatio,
                        undefined,
                        undefined,
                        undefined,
                        true
                      )
                    : '-'
                  : formatFixedToken(
                      borrow.collateralDeposited,
                      borrow.collateralCurrency?.symbol,
                      true
                    )}
            </Text>
            <Text
              as="span"
              variant="body-xs/normal"
              color="gray.4"
              marginTop="0 !important"
              fontSize={'11px'}
            >
              {asset === 'usd'
                ? formatFixedUsd(
                    subtract(
                      borrow.collateralDepositedUsd,
                      borrow.collateralRequiredUsd
                    )
                  )
                : asset === 'ratio'
                  ? !fixedToBigNumber(borrow.collateralDepositedRatio).eq(
                      constants.MaxUint256
                    )
                    ? formatFixedPercentage(
                        subtract(
                          borrow.collateralDepositedRatio,
                          borrow.collateralRequiredRatio
                        ),
                        undefined,
                        undefined,
                        undefined,
                        true
                      )
                    : '-'
                  : formatFixedToken(
                      subtract(
                        borrow.collateralDeposited,
                        borrow.collateralRequired
                      ),
                      borrow.collateralCurrency?.symbol,
                      true
                    )}
            </Text>
            {/* Open Positions Section */}
          </VStack>
        </Td>
        {/* Action Buttons */}
        <Td large verticalAlign="top">
          <HStack right={0} position="absolute">
            <Spacer />
            {/* 0.5.5 contracts introduce repay before maturity ability */}
            {isMatured && !pastRepayment && (
              <Button
                size="xs"
                variant="primary"
                onClick={() => onRepayModalOpen()}
              >
                <Trans>Repay</Trans>
              </Button>
            )}
            {pastRedemption &&
              !borrow.collateralDeposited.isZero() &&
              !borrow.collateralDeposited.isNegative() && (
                <Button
                  size="xs"
                  variant="secondary"
                  onClick={() => {
                    setMarginModalTab('withdraw')
                    onManageMarginOpen()
                  }}
                >
                  <Trans>Withdraw</Trans>
                </Button>
              )}
            <BorrowMorePopout
              isMatured={isMatured}
              termVersion={borrow.termVersion}
              collateralDeposited={borrow.collateralDeposited}
              outstandingDebt={borrow.outstandingDebt}
              rolloverAmount={
                borrow.rolloverAmount || FixedNumber.fromString('0')
              }
              rolloverElected={borrow.rolloverElected}
              rolloverCancelled={borrow.rolloverCancelled}
              rolloverFailed={borrow.rolloverFailed}
              collateralSymbol={borrow.collateralTokenSymbol}
              auctionId={borrow?.lastAuctionId}
              onRepay={() => onRepayModalOpen()}
              onManageMargin={() => onManageMarginOpen()}
              onElectEditRollover={() => onElectRolloverOpen()}
              onCancelRollover={() => onCancelRolloverOpen()}
              onCollapseBorrow={() => onCollapseBorrowOpen()}
              onContractAddress={() => onContractAddressOpen()}
            />
          </HStack>
        </Td>
      </Tr>

      <RepayLoanModal
        isOpen={isRepayModalOpen}
        onClose={onRepayModalClose}
        collateralCurrency={borrow.collateralCurrency}
        collateralPrice={borrow.collateralPrice}
        collateralBalance={borrow.collateralBalance}
        purchaseCurrency={borrow.purchaseCurrency}
        purchasePrice={borrow.purchasePrice}
        purchaseBalance={borrow.purchaseBalance}
        termTokenBalance={borrow.termTokenBalance}
        excessCollateral={excessCollateral}
        collateralDeposited={borrow.collateralDeposited}
        maintenanceMarginRatio={borrow.maintenanceMarginRatio}
        loanPrincipal={borrow.principalDebt}
        onRepay={onRepay}
        onCollapse={onCollapse}
        endOfRepaymentTimestamp={borrow.endOfRepaymentTimestamp}
        rolloverAmount={
          borrow.rolloverFulfilled
            ? FixedNumber.fromString('0.0', borrow.principalDebt.format)
            : borrow.rolloverAmount
        }
        rolloverCancelled={borrow.rolloverCancelled}
        hasFailedRollover={borrow.rolloverFailed}
        outstandingDebt={borrow.outstandingDebt}
        auctionLabel={auctionLabel}
        approvedPurchaseTokens={borrow.purchaseTokensApproved}
        onApprovePurchaseToken={onApprovePurchaseToken}
        onCancelRollover={onCancelRollover}
      />
      <ManageMarginModal
        isOpen={isManageMarginOpen}
        onClose={onManageMarginClose}
        pastRedemption={pastRedemption}
        pastRepayment={pastRepayment}
        collateralCurrency={borrow.collateralCurrency}
        collateralTokenPrice={borrow.collateralPrice}
        collateralDeposited={borrow.collateralDeposited}
        collateralWalletBalance={borrow.collateralBalance}
        purchaseCurrency={borrow.purchaseCurrency}
        requiredMarginRatio={borrow.maintenanceMarginRatio}
        loanAmountUsd={multiply(
          borrow.outstandingDebt,
          borrow.purchasePrice,
          borrow.outstandingDebt.format.decimals
        )}
        onDepositCollateral={onLockCollateral}
        onWithdrawCollateral={onUnlockCollateral}
        auctionLabel={auctionLabel}
        defaultTab={marginModalTab}
        approvedCollateralTokens={borrow.collateralTokensApproved}
        onApproveCollateralTokens={async (amount) => {
          if (borrow.repoLocker) {
            await onApproveCollateralTokens(borrow.repoLocker, amount)
          }
        }}
      />
      <BorrowerRolloverModal
        isOpen={isElectRolloverOpen}
        onClose={onElectRolloverClose}
        chainId={chainId}
        onSwapToCancel={() => {
          onElectRolloverClose()
          onCancelRolloverOpen()
        }}
        collateralSymbol={borrow.collateralCurrency.symbol}
        collateralPrice={borrow.collateralPrice}
        purchaseSymbol={borrow.purchaseCurrency.symbol}
        purchaseDecimals={borrow.purchaseCurrency.decimals}
        purchasePrice={borrow.purchasePrice}
        edit={!!borrow.rolloverAmount && !borrow.rolloverAmount.isZero()}
        marginDeposited={borrow.collateralDeposited}
        depositedRatio={borrow.collateralDepositedRatio}
        maturityTimestamp={borrow.maturityTimestamp}
        outstandingDebt={borrow.outstandingDebt}
        rolloverAuctions={rolloverAuctions}
        defaultRolloverAmount={borrow.rolloverAmount}
        defaultRolloverAuction={electedRolloverAuctionInfo[0]}
        onElectRollover={onElectRollover}
        onEditRollover={onEditRollover}
        auctionLabel={auctionLabel}
        repurchaseWindowEnd={borrow.endOfRepaymentTimestamp}
        onKytCheck={onKytCheck}
      />
      <BorrowerCancelRolloverModal
        isOpen={isCancelRolloverOpen}
        onClose={onCancelRolloverClose}
        purchaseSymbol={borrow.purchaseCurrency.symbol}
        endOfRepaymentTimestamp={borrow.endOfRepaymentTimestamp}
        outstandingDebt={borrow.outstandingDebt}
        onCancelRollover={onCancelRollover}
        auctionLabel={auctionLabel}
        rolloverAmount={
          borrow.rolloverAmount ||
          FixedNumber.fromString('0', borrow.outstandingDebt.format)
        }
      />
      <CollapseBorrowModal
        isOpen={isCollapseBorrowOpen}
        onClose={onCollapseBorrowClose}
        outstandingDebt={borrow.outstandingDebt}
        rolloverAmount={
          borrow.rolloverFulfilled
            ? FixedNumber.fromString('0.0', borrow.principalDebt.format)
            : borrow.rolloverAmount
        }
        maturityDate={borrow.maturityTimestamp}
        purchaseCurrency={borrow.purchaseCurrency}
        purchasePrice={borrow.purchasePrice}
        termTokenBalance={borrow.termTokenBalance}
        termTokenPrice={borrow.termTokenPrice ?? one}
        termTokenCurrency={borrow.termTokenCurrency}
        onCollapse={onCollapse}
        auctionLabel={auctionLabel}
      />
      {lastLiquidation && (
        <LoanLiquidationModal
          isOpen={isLiquidationModalOpen}
          onClose={onLiquidationModalClose}
          chainId={chainId}
          currentLiquidation={lastLiquidation}
          pastLiquidations={pastLiquidations}
          auctionLabel={auctionLabel}
        />
      )}
      <LoadInterestRateModal
        isOpen={isLoadInterestRateOpen}
        onClose={onLoadInterestRateClose}
        onLoadRates={async () => {
          const rolloverBidId = solidityKeccak256(
            ['address', 'address'],
            [borrow.repoRolloverManager, account]
          )
          onLoadMissingTenderRates(chainId, [rolloverBidId], [], true)
          onLoadInterestRateClose()
        }}
      />
      <ContractAddressModal
        isOpen={isContractAddressOpen}
        onClose={onContractAddressClose}
        chainId={chainId}
        auctionLabel={auctionLabel}
        auction={borrow.contractAddresses.auction}
        bidLocker={borrow.contractAddresses.auctionBidLocker}
        offerLocker={borrow.contractAddresses.auctionOfferLocker}
        repoLocker={borrow.contractAddresses.termRepoLocker}
        repoServicer={borrow.contractAddresses.termRepoServicer}
        collateralManager={borrow.contractAddresses.termCollateralManager}
        rolloverManager={borrow.contractAddresses.termRolloverManager}
        termRepoToken={borrow.contractAddresses.termRepoToken}
      />
    </>
  )
}

function EmptyTableFooter({ emptyText }: { emptyText: string }) {
  return (
    <Tr noBorder>
      <Td colSpan={12} py="14px" bg="white">
        <Text as="span" variant="body-xs/medium">
          {emptyText}
        </Text>
      </Td>
    </Tr>
  )
}

export function LoanCurrenciesAndTerm({
  purchaseTokenSymbol,
  collateralTokenSymbol,
  loanTerm,
  ...props
}: {
  purchaseTokenSymbol: string
  collateralTokenSymbol: string
  loanTerm: string
}) {
  const purchaseTokenImage = useTokenIcon(purchaseTokenSymbol)

  const {
    displayToken: purchaseSymbol,
    isTruncated: isPurchaseTokenSymbolTruncated,
  } = getDisplayToken(purchaseTokenSymbol)
  const {
    displayToken: collatSymbol,
    isTruncated: isCollatTokenSymbolTruncated,
  } = getDisplayToken(collateralTokenSymbol)

  return (
    <HStack flex={1}>
      {purchaseTokenSymbol !== ERROR_STR && (
        <Image
          boxSize="16px"
          src={purchaseTokenImage}
          alt={purchaseTokenSymbol}
          verticalAlign="top"
          alignItems="center"
          marginTop="-16px !important"
        />
      )}
      <VStack
        alignItems="flex-start"
        verticalAlign="bottom"
        {...props}
        spacing={0}
      >
        <Tooltip
          shouldWrapChildren
          label={
            isPurchaseTokenSymbolTruncated || isCollatTokenSymbolTruncated
              ? `${purchaseTokenSymbol.toUpperCase()}(${collateralTokenSymbol.toUpperCase()})`
              : undefined
          }
          aria-label="auction-display-id-tooltip"
          placement="bottom"
        >
          <HStack spacing={0}>
            <Text
              color="blue.9"
              whiteSpace="nowrap"
              fontWeight="bold"
              variant="body-xs/normal"
            >
              {purchaseSymbol.toUpperCase()}
            </Text>
            <Text as="span" marginLeft="0 !important" variant="body-xs/normal">
              ({collatSymbol.toUpperCase()})
            </Text>
          </HStack>
        </Tooltip>
        <Text
          as="span"
          color="gray.4"
          fontSize="11px"
          marginTop="0 !important"
          variant="body-xs/normal"
        >
          {loanTerm.length > 0 ? loanTerm : '*'}
        </Text>
      </VStack>
    </HStack>
  )
}

export default function OpenPositionsSection({
  openLoans,
  openBorrows,
  listingDiscountRateMarkups,
  listingMinimumListingAmounts,
  rolloverAuctions,
  prices,
  isLoadingMissingRates,

  onApprovePurchaseTokens,
  onApproveCollateralTokens,
  onApproveTermRepoTokens,

  onAddTermToken,

  redeemTermTokens,
  repayTermTokens,
  collapseBorrow,

  onLockCollateral,
  onUnlockCollateral,
  onElectRollover,
  onEditRollover,
  onCancelRollover,
  onKytCheck,
  onLoadMissingTenderRates,

  onCreateListing,
  onCancelListings,
}: {
  openLoans: OpenLoans
  openBorrows: OpenBorrows
  listingDiscountRateMarkups: { [chainId: string]: BigNumber }
  listingMinimumListingAmounts: {
    [chainId: string]: { [termRepoToken: Address]: FixedNumber }
  }
  rolloverAuctions: RolloverAuctionInfo[]
  prices: { [chainId: string]: { [address: string]: FixedNumber } }
  isLoadingMissingRates: boolean

  onApprovePurchaseTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onApproveCollateralTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onApproveTermRepoTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onAddTermToken: (
    chainId: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number
  ) => Promise<boolean>

  redeemTermTokens: (
    chainId: string,
    loanManagerAddress: Address,
    termTokensToBurn: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  repayTermTokens: (
    chainId: string,
    loanManagerAddress: Address,
    repayAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  collapseBorrow: (
    chainId: string,
    loanManagerAddress: Address,
    collapseBorrowAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>

  onLockCollateral: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  onUnlockCollateral: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>

  onElectRollover: (
    chainId: string,
    rolloverManagerAddress: string,
    electedRollover: ElectRollover
  ) => Promise<void>
  onEditRollover: (
    chainId: string,
    rolloverManagerAddress: string,
    electedRollover: ElectRollover
  ) => Promise<void>
  onCancelRollover: (
    chainId: string,
    rolloverManagerAddress: string,
    termId: string
  ) => Promise<void>
  onKytCheck: () => Promise<boolean>
  onLoadMissingTenderRates: (
    chainId: string,
    bids: string[] | undefined,
    offers: string[] | undefined,
    isRollover: boolean
  ) => Promise<void>

  onCreateListing: (
    chainId: string,
    termRepoToken: Address,
    listingAmount: FixedNumber
  ) => Promise<void>
  onCancelListings: (
    chainId: string,
    listingIds: number[],
    skipRedeem?: boolean
  ) => Promise<void>
}) {
  const config = useConfig()
  const [asset, setAsset] = useState<'' | 'ratio' | 'usd'>('')
  const [borrowMaturitySortOrder, setBorrowMaturitySortOrder] =
    useState<SortOrder>('asc')
  const [loanMaturitySortOrder, setLoanMaturitySortOrder] =
    useState<SortOrder>('asc')

  const handleToggleBorrowMaturitySortOrder = () =>
    setBorrowMaturitySortOrder(
      borrowMaturitySortOrder === SortOrderEnum.ASC ? 'desc' : 'asc'
    )

  const handleToggleLoanMaturitySortOrder = () =>
    setLoanMaturitySortOrder(
      loanMaturitySortOrder === SortOrderEnum.ASC ? 'desc' : 'asc'
    )

  const sortArray = (
    a: OpenBorrow | OpenLoan,
    b: OpenBorrow | OpenLoan,
    order: SortOrder
  ) => {
    if (order === SortOrderEnum.ASC) {
      return a.maturityTimestamp - b.maturityTimestamp
    } else {
      return b.maturityTimestamp - a.maturityTimestamp
    }
  }

  const filteredBorrows = useMemo(() => {
    const result: { [chainId: string]: OpenBorrow[] } = {}

    Object.entries(openBorrows.borrows).forEach(([chainId, borrowsArray]) => {
      const filteredAndSorted = borrowsArray
        .filter(
          (borrow) =>
            borrow.collateralBalance &&
            borrow.collateralCurrency &&
            borrow.collateralPrice
        )
        .sort((a, b) => sortArray(a, b, borrowMaturitySortOrder))

      // Assign to the result object
      result[chainId] = filteredAndSorted
    })

    return result
  }, [openBorrows, borrowMaturitySortOrder])

  const filteredLoans = useMemo(() => {
    const result: { [chainId: string]: OpenLoan[] } = {}

    Object.entries(openLoans.loans).forEach(([chainId, loansArray]) => {
      const filteredAndSorted = loansArray
        .filter(
          (loan) =>
            loan.collateralBalance &&
            loan.collateralCurrency &&
            loan.collateralPrice &&
            loan.termTokenBalance &&
            loan.termTokenCurrency
        )
        .sort((a, b) => sortArray(a, b, loanMaturitySortOrder))

      // Assign to the result object
      result[chainId] = filteredAndSorted
    })

    return result
  }, [openLoans, loanMaturitySortOrder])

  const borrowMultipleNetworks = Object.keys(openBorrows.chainTotals).length > 1
  const loanMultipleNetworks = Object.keys(openLoans.chainTotals).length > 1

  // Refs to tables to calculate column widths
  const borrowTableRef = useRef(null)
  const loanTableRef = useRef(null)

  const tableRefs = useMemo(
    () => [borrowTableRef, loanTableRef],
    [borrowTableRef, loanTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set(['repayRedeem', 'outstanding', asset, 'required']),
    [asset]
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    Object.values(filteredBorrows).length > 0 ||
      Object.values(filteredLoans).length > 0
  )

  const emptyBorrows = openBorrows.totals.count === 0
  const emptyLoans = openLoans.totals.count === 0

  // TODO: wire up
  const emptyProceeds = true
  // eslint-disable-next-line unused-imports/no-unused-vars
  const withdrawProceedsFn = (tokensToWithdraw: FixedNumber) => {
    return Promise.resolve()
  }
  const listingProceedsMultipleNetworks = true
  const filteredListingProceeds = { '1': [] }

  return (
    <Box my={5} mr="20px !important">
      <HStack mb="8px">
        <Heading variant="heading/h3" color="blue.9">
          <Trans>Borrowing</Trans>
        </Heading>
        <Chip>{openBorrows.totals.count ?? 0}</Chip>
      </HStack>
      <Table tableRef={borrowTableRef} isFixedLayout noPadding>
        {emptyBorrows ? (
          <TableHead>
            <OpenBorrowHeader
              numTenders={0}
              isFirst={true}
              asset={asset}
              onChangeAsset={setAsset}
              handleToggleSortOrder={handleToggleBorrowMaturitySortOrder}
              sortDirection={borrowMaturitySortOrder}
              columnWidths={columnWidths}
            />
          </TableHead>
        ) : (
          Object.entries(filteredBorrows).map(([chainId, borrows], index) => (
            <Fragment key={index}>
              {borrows?.length > 0 && (
                <TableHead>
                  <OpenBorrowHeader
                    key={'header-' + chainId + '-' + index}
                    chainId={chainId}
                    numTenders={openBorrows.chainTotals[chainId]?.count}
                    isFirst={index === 0}
                    asset={asset}
                    onChangeAsset={
                      Object.values(filteredBorrows).length > 0
                        ? setAsset
                        : undefined
                    }
                    handleToggleSortOrder={handleToggleBorrowMaturitySortOrder}
                    sortDirection={borrowMaturitySortOrder}
                    columnWidths={columnWidths}
                  />
                </TableHead>
              )}
              <TableBody>
                {borrows.map((borrow, i) => (
                  <OpenBorrowRow
                    chainId={chainId}
                    key={borrow.termId + '-' + i + '-' + index}
                    asset={asset}
                    borrow={borrow}
                    prices={prices}
                    isLoadingMissingRates={isLoadingMissingRates}
                    rolloverAuctions={rolloverAuctions.filter(
                      (ra) => borrow.termId === ra.oldTermId
                    )}
                    onRepay={(amount, isGasToken) =>
                      repayTermTokens(
                        borrow.chainId.toString(),
                        borrow.repoServicer,
                        amount,
                        isGasToken
                      )
                    }
                    onLockCollateral={(amount, isGasToken) =>
                      onLockCollateral(
                        borrow.chainId.toString(),
                        borrow.repoCollateralManager,
                        borrow.collateralCurrency.address,
                        amount,
                        isGasToken
                      )
                    }
                    onUnlockCollateral={(amount, isGasToken) =>
                      onUnlockCollateral(
                        borrow.chainId.toString(),
                        borrow.repoCollateralManager,
                        borrow.collateralCurrency.address,
                        amount,
                        isGasToken
                      )
                    }
                    onElectRollover={(electedRollover) =>
                      onElectRollover(
                        borrow.chainId.toString(),
                        borrow.repoRolloverManager,
                        electedRollover
                      )
                    }
                    onEditRollover={(electedRollover) =>
                      onEditRollover(
                        borrow.chainId.toString(),
                        borrow.repoRolloverManager,
                        electedRollover
                      )
                    }
                    onCancelRollover={() =>
                      onCancelRollover(
                        borrow.chainId.toString(),
                        borrow.repoRolloverManager,
                        borrow.termId
                      )
                    }
                    onCollapse={(amount, isGasToken) =>
                      collapseBorrow(
                        borrow.chainId.toString(),
                        borrow.repoServicer,
                        amount,
                        isGasToken
                      )
                    }
                    onApprovePurchaseTokens={(spender, amount) =>
                      onApprovePurchaseTokens(
                        borrow.chainId.toString(),
                        borrow.purchaseCurrency.address,
                        spender,
                        amount
                      )
                    }
                    onApproveCollateralTokens={(spender, amount) =>
                      onApproveCollateralTokens(
                        borrow.chainId.toString(),
                        borrow.collateralCurrency.address,
                        spender,
                        amount
                      )
                    }
                    onKytCheck={onKytCheck}
                    onLoadMissingTenderRates={onLoadMissingTenderRates}
                  />
                ))}
                {borrowMultipleNetworks && openBorrows.chainTotals[chainId] && (
                  <OpenBorrowFooter
                    key={'footer-' + chainId + '-' + index}
                    depositedCollateralUsd={
                      openBorrows.chainTotals[chainId].collateralDepositedUsd
                    }
                    excessCollateralUsd={subtract(
                      openBorrows.chainTotals[chainId].collateralDepositedUsd,
                      openBorrows.chainTotals[chainId].collateralRequiredUsd
                    )}
                    initialBorrowUsd={
                      openBorrows.chainTotals[chainId].originalPrincipalUsd
                    }
                    interestUsd={
                      openBorrows.chainTotals[chainId].originalInterestUsd
                    }
                    outstandingDebtUsd={
                      openBorrows.chainTotals[chainId].outstandingDebtUsd
                    }
                    requiredCollateralUsd={
                      openBorrows.chainTotals[chainId].collateralRequiredUsd
                    }
                    isSubtotal
                  />
                )}
              </TableBody>
            </Fragment>
          ))
        )}
        <TableFoot noTopBorder>
          {borrowMultipleNetworks && !emptyBorrows && (
            <OpenBorrowFooterHeader numTenders={openBorrows.totals.count} />
          )}
          {emptyBorrows ? (
            <EmptyTableFooter emptyText={t`You have no open borrows.`} />
          ) : (
            <OpenBorrowFooter
              depositedCollateralUsd={openBorrows.totals.collateralDepositedUsd}
              excessCollateralUsd={subtract(
                openBorrows.totals.collateralDepositedUsd,
                openBorrows.totals.collateralRequiredUsd
              )}
              initialBorrowUsd={openBorrows.totals.principalDebtUsd}
              interestUsd={openBorrows.totals.interestDebtUsd}
              outstandingDebtUsd={openBorrows.totals.outstandingDebtUsd}
              requiredCollateralUsd={openBorrows.totals.collateralRequiredUsd}
            />
          )}
        </TableFoot>
      </Table>
      <HStack mb="8px" mt="40px">
        <Heading variant="heading/h3" color="blue.9">
          <Trans>Lending</Trans>
        </Heading>
        <Chip>{openLoans.totals.count ?? 0}</Chip>
      </HStack>
      <Table tableRef={loanTableRef} isFixedLayout noPadding cellSpacing={4}>
        {emptyLoans ? (
          <TableHead>
            <OpenLoanHeader
              numTenders={0}
              isFirst={true}
              hasAssetColumn={false}
              handleToggleSortOrder={handleToggleLoanMaturitySortOrder}
              sortDirection={loanMaturitySortOrder}
              columnWidths={columnWidths}
            />
          </TableHead>
        ) : (
          Object.entries(filteredLoans).map(([chainId, loans], index) => (
            <Fragment key={index}>
              {loans?.length > 0 && (
                <TableHead>
                  <OpenLoanHeader
                    key={'header-' + chainId + '-' + index}
                    chainId={chainId}
                    numTenders={openLoans.chainTotals[chainId]?.count}
                    isFirst={index === 0}
                    hasAssetColumn={!emptyLoans}
                    handleToggleSortOrder={handleToggleLoanMaturitySortOrder}
                    sortDirection={loanMaturitySortOrder}
                    columnWidths={columnWidths}
                  />
                </TableHead>
              )}
              <TableBody>
                {loans.map((loan, i) => (
                  <OpenLoanRow
                    chainId={chainId}
                    key={loan.termTokenCurrency + '-' + i + '-' + index}
                    loan={loan}
                    listingDiscountRateMarkup={
                      listingDiscountRateMarkups?.[chainId] ?? BigNumber.from(0)
                    }
                    listingMinimumListingAmount={
                      listingMinimumListingAmounts?.[chainId]?.[
                        loan.termTokenCurrency.address
                      ] ?? FixedNumber.fromString('0', `fixed128x8`)
                    }
                    onRedeem={(amount, isGasToken) =>
                      redeemTermTokens(
                        loan.chainId.toString(),
                        loan.repoServicerAddress,
                        amount,
                        isGasToken
                      )
                    }
                    onApproveTermRepoTokens={(spender, amount) =>
                      onApproveTermRepoTokens(
                        loan.chainId.toString(),
                        loan.termTokenCurrency.address,
                        spender,
                        amount
                      )
                    }
                    onAddTermToken={onAddTermToken}
                    onKytCheck={onKytCheck}
                    onCreateListing={onCreateListing}
                    onCancelListings={onCancelListings}
                  />
                ))}
                {loanMultipleNetworks && openLoans.chainTotals[chainId] && (
                  <OpenLoanFooter
                    key={'footer-' + chainId + '-' + index}
                    outstandingCreditUsd={
                      openLoans.chainTotals[chainId].outstandingCreditUsd
                    }
                    initialLoanUsd={
                      openLoans.chainTotals[chainId].loanPrincipalUsd
                    }
                    interestUsd={openLoans.chainTotals[chainId].loanInterestUsd}
                    openListings={openLoans.chainTotals[chainId].openListings}
                    openListingsUsd={
                      openLoans.chainTotals[chainId].openListingsUsd
                    }
                    soldListings={openLoans.chainTotals[chainId].soldListings}
                    soldListingsUsd={
                      openLoans.chainTotals[chainId].soldListingsUsd
                    }
                    isSubtotal
                  />
                )}
              </TableBody>
            </Fragment>
          ))
        )}
        <TableFoot noTopBorder>
          {loanMultipleNetworks && (
            <OpenLoanFooterHeader numTenders={openLoans.totals.count} />
          )}
          {emptyLoans ? (
            <EmptyTableFooter emptyText={t`You have no open loans.`} />
          ) : (
            <OpenLoanFooter
              outstandingCreditUsd={openLoans.totals.outstandingCreditUsd}
              initialLoanUsd={openLoans.totals.loanPrincipalUsd}
              interestUsd={openLoans.totals.loanInterestUsd}
              openListings={openLoans.totals.openListings}
              openListingsUsd={openLoans.totals.openListingsUsd}
              soldListings={openLoans.totals.soldListings}
              soldListingsUsd={openLoans.totals.soldListingsUsd}
            />
          )}
        </TableFoot>
      </Table>
      {
        // config.listingEnabled && (
        // <>
        //   <HStack mb="8px" mt="40px">
        //     <Heading variant="heading/h3" color="blue.9">
        //       <Trans>Listing Proceeds</Trans>
        //     </Heading>
        //     {/* TODO: wire up total count */}
        //     <Chip>{123}</Chip>
        //   </HStack>
        //   <Table tableRef={undefined} isFixedLayout noPadding>
        //     {emptyProceeds ? (
        //       <TableHead>
        //         <OpenListingProceedsHeader
        //           numProceeds={0}
        //           isFirst={true}
        //           columnWidths={columnWidths}
        //         />
        //       </TableHead>
        //     ) : (
        //       Object.entries(filteredListingProceeds).map(
        //         ([chainId], index) => (
        //           <Fragment key={index}>
        //             <TableHead>
        //               <OpenListingProceedsHeader
        //                 chainId={chainId}
        //                 numProceeds={12345}
        //                 isFirst={index === 0}
        //                 columnWidths={columnWidths}
        //               />
        //             </TableHead>
        //             <TableBody>
        //               <OpenListingProceedsRow
        //                 index={index}
        //                 listing={{
        //                   purchaseCurrency: {
        //                     address: '0x1',
        //                     symbol: 'USDC',
        //                     decimals: 6,
        //                     isRepoToken: false,
        //                   },
        //                   termCurrency: {
        //                     address: '0x2',
        //                     symbol: 'TTTT',
        //                     decimals: 6,
        //                     isRepoToken: true,
        //                     redemptionTimestamp: 0,
        //                   },
        //                   term: '3 months',
        //                   listedTokensBalance: FixedNumber.fromString(
        //                     '100',
        //                     `fixed128x${6}`
        //                   ),
        //                 }}
        //                 onWithdrawProceeds={withdrawProceedsFn}
        //               />
        //               {listingProceedsMultipleNetworks && (
        //                 <OpenListingProceedsFooter
        //                   key={'footer-' + chainId + '-' + index}
        //                   isSubtotal
        //                   proceedsAmountUsd={FixedNumber.fromString(
        //                     '100',
        //                     `fixed128x${6}`
        //                   )}
        //                 />
        //               )}
        //             </TableBody>
        //           </Fragment>
        //         )
        //       )
        //     )}
        //     <TableFoot noTopBorder>
        //       {listingProceedsMultipleNetworks && (
        //         <OpenListingProceedsFooterHeader numProceeds={1234} />
        //       )}
        //       {emptyProceeds ? (
        //         <EmptyTableFooter
        //           emptyText={t`You have no open listing proceeds.`}
        //         />
        //       ) : (
        //         <OpenListingProceedsFooter
        //           proceedsAmountUsd={FixedNumber.fromString(
        //             '1234',
        //             `fixed128x${6}`
        //           )}
        //         />
        //       )}
        //     </TableFoot>
        //   </Table>
        // </>
        //)
      }
    </Box>
  )
}

function AprInterestRateTooltip() {
  return (
    <Tooltip
      noDelay
      infoIcon={true}
      iconColor="gray"
      aria-label="min-interest-rate-tooltip"
      label={t`The interest rate (%) is expressed as an Annual Percentage Rate (APR).`}
    />
  )
}
