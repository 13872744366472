import { Image, Text } from '@chakra-ui/react'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import termIcon from '../../../../assets/icons/projects/term-default.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import TokenIcon from '../../../../components/elements/TokenIcon'
import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'

type WhiteBox = {
  icon: string
  label: string
  rate: number
}

const LOOP_DURATION = 3000

const STRATEGY_VAULTS: WhiteBox[] = [
  {
    icon: 'MEV',
    label: 'MEV USDC',
    rate: 33,
  },
  {
    icon: 'RE7',
    label: 'Re7 USDC',
    rate: 33,
  },
  {
    icon: 'ULTRA',
    label: 'Ultra USDC',
    rate: 33,
  },
]

const REPO_TOKENS: WhiteBox[] = [
  {
    icon: 'WBTC',
    label: 'Nov. 1 wBTC',
    rate: 7.5,
  },
  {
    icon: 'WSTETH',
    label: 'Nov. 1 wstETH',
    rate: 7.5,
  },
  {
    icon: 'WBTC',
    label: 'Nov. 15 wBTC',
    rate: 7.75,
  },
  {
    icon: 'WSTETH',
    label: 'Nov. 15 wstETH',
    rate: 7.75,
  },
  {
    icon: 'WBTC',
    label: 'Dec. 1 wBTC',
    rate: 7.75,
  },
  {
    icon: 'WSTETH',
    label: 'Dec 15 wstETH',
    rate: 7.5,
  },
]

//
// VARIANTS
//
const PATH_VARIANT = {
  fade: {
    pathLength: 1, // always fully drawn
    opacity: 0.3, // partially faded
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
  highlight: {
    pathLength: 1, // still fully drawn
    opacity: 1, // fully opaque
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
}

const RECT_VARIANT = {
  fade: {
    opacity: 0.3,
    scale: 1,
    transition: { duration: 0.6, ease: 'easeIn' },
  },
  highlight: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.6, ease: 'easeIn' },
  },
}

export default function MetaVaultSvg() {
  // Three separate animation controls: group0, group1, group2
  const controls0 = useAnimation()
  const controls1 = useAnimation()
  const controls2 = useAnimation()

  useEffect(() => {
    let isMounted = true
    // Loop forever in the sequence 0->1->2
    async function sequenceLoop() {
      while (isMounted) {
        // isMounted is checked before each control action to handle situation
        // where component is unmounted during the loop

        // highlight group0, fade group1 & 2

        if (!isMounted) break
        controls0.start('highlight')
        if (!isMounted) break
        controls1.start('fade')
        if (!isMounted) break
        controls2.start('fade')
        if (!isMounted) break
        await new Promise((r) => setTimeout(r, LOOP_DURATION))

        // highlight group1, fade group0 & 2

        if (!isMounted) break
        controls0.start('fade')
        if (!isMounted) break
        controls1.start('highlight')
        if (!isMounted) break
        controls2.start('fade')
        if (!isMounted) break
        await new Promise((r) => setTimeout(r, LOOP_DURATION))

        // highlight group2, fade group0 & 1

        if (!isMounted) break
        controls0.start('fade')
        if (!isMounted) break
        controls1.start('fade')
        if (!isMounted) break
        controls2.start('highlight')
        if (!isMounted) break
        await new Promise((r) => setTimeout(r, LOOP_DURATION))
      }
    }
    sequenceLoop()

    // stop loop on unmount
    return () => {
      isMounted = false
    }
  }, [controls0, controls1, controls2])

  return (
    <svg
      width="755"
      height="312"
      viewBox="0 0 755 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="commonGradient" x1="0.5" y1="0" x2="1" y2="0">
          <stop offset="0" stopColor="rgba(0,153,255,1)" />
          <stop offset="1" stopColor="rgba(147,117,255,1)" />
        </linearGradient>
      </defs>

      {/* COLUMN 1 (static content) */}
      <g id="column1">
        <rect y="115" width="181" height="52" rx="6" fill="transparent" />
        <foreignObject x="0" y="110" width="181" height="24">
          <HStack>
            <Text variant="body-sm/bold" color="white">
              Meta Vaults
            </Text>
          </HStack>
        </foreignObject>
        <rect y="149" width="181" height="52" rx="6" fill="white" />
        <foreignObject x="0" y="149" width="181" height="52">
          <HStack padding={1.5} h="52px" gap={3}>
            <Image width="26px" height="26px" src={termIcon} />
            <VStack align="start" gap={0}>
              <Text variant="body-sm/bold" lineHeight="14px" color="black">
                Term Meta Vaults
              </Text>
              <Text variant="body-sm/medium" lineHeight="14px" color="black">
                100%
              </Text>
            </VStack>
          </HStack>
        </foreignObject>
      </g>

      {/* Middle Column Title */}
      <foreignObject x="296" y="60" width="181" height="24">
        <HStack>
          <Text variant="body-sm/bold" color="white">
            Strategy Vaults
          </Text>
        </HStack>
      </foreignObject>

      {/* ========== GROUP 0 ========== */}
      {/* All paths & rects that animate with controls0 */}
      {/* Path uses pathVariant for fill effect, rect uses rectVariant for fade */}
      <AnimatedPath
        d="M181 163C216.819 162.865 240.051 116.028 296 116"
        animate={controls0}
      />
      <RectWithForeignObject
        x="296"
        y="99"
        width="170"
        icon={STRATEGY_VAULTS[0].icon}
        label={STRATEGY_VAULTS[0].label}
        rate={STRATEGY_VAULTS[0].rate}
        isStrategyVault
        animate={controls0}
      />
      <AnimatedPath
        d="M466 112.505C499.608 112 521.505 54.1529 574 53.9999"
        animate={controls0}
      />
      <AnimatedPath
        d="M466 120.5C508.951 120.5 532.992 247.728 574 246.993"
        animate={controls0}
      />
      <AnimatedPath
        d="M466 115.008C508.827 114.766 533.097 149.933 574 149.998"
        animate={controls0}
      />
      <RectWithForeignObject
        x="574"
        y="36"
        width="181"
        icon={REPO_TOKENS[0].icon}
        label={REPO_TOKENS[0].label}
        rate={REPO_TOKENS[0].rate}
        animate={controls0}
      />
      <RectWithForeignObject
        x="574"
        y="132"
        width="181"
        icon={REPO_TOKENS[2].icon}
        label={REPO_TOKENS[2].label}
        rate={REPO_TOKENS[2].rate}
        animate={controls0}
      />
      <RectWithForeignObject
        x="574"
        y="228"
        width="181"
        icon={REPO_TOKENS[4].icon}
        label={REPO_TOKENS[4].label}
        rate={REPO_TOKENS[4].rate}
        animate={controls0}
      />

      {/* ========== GROUP 1 ========== */}
      <AnimatedPath d="M181.009 175.103L295.997 175.355" animate={controls1} />
      <RectWithForeignObject
        x="296"
        y="159"
        width="170"
        icon={STRATEGY_VAULTS[1].icon}
        label={STRATEGY_VAULTS[1].label}
        rate={STRATEGY_VAULTS[1].rate}
        isStrategyVault
        animate={controls1}
      />
      <AnimatedPath
        d="M466 177C500.07 177 522.055 102.251 574.496 101.959"
        animate={controls1}
      />
      <RectWithForeignObject
        x="574"
        y="84"
        width="181"
        icon={REPO_TOKENS[1].icon}
        label={REPO_TOKENS[1].label}
        rate={REPO_TOKENS[1].rate}
        animate={controls1}
      />

      {/* ========== GROUP 2 ========== */}
      <AnimatedPath
        d="M181 188.011C226.64 187.574 252.412 235.014 296 234.998"
        animate={controls2}
      />
      <RectWithForeignObject
        x="296"
        y="219"
        width="170"
        icon={STRATEGY_VAULTS[2].icon}
        label={STRATEGY_VAULTS[2].label}
        rate={STRATEGY_VAULTS[2].rate}
        isStrategyVault
        animate={controls2}
      />
      <AnimatedPath
        d="M466 233.901C500.16 233.862 521.913 198.883 574.496 198.959"
        animate={controls2}
      />
      <AnimatedPath
        d="M466 239.959C510.355 240.026 534.04 296.402 574.496 295.95"
        animate={controls2}
      />
      <RectWithForeignObject
        x="574"
        y="180"
        width="181"
        icon={REPO_TOKENS[3].icon}
        label={REPO_TOKENS[3].label}
        rate={REPO_TOKENS[3].rate}
        animate={controls2}
      />
      <RectWithForeignObject
        x="574"
        y="276"
        width="181"
        icon={REPO_TOKENS[4].icon}
        label={REPO_TOKENS[4].label}
        rate={REPO_TOKENS[4].rate}
        animate={controls2}
      />

      {/* Last Column Title */}
      <foreignObject x="574" y="-3" width="181" height="24">
        <HStack>
          <Text variant="body-sm/bold" color="white">
            Repo Tokens
          </Text>
        </HStack>
      </foreignObject>
    </svg>
  )
}

const AnimatedPath = ({
  d,
  animate,
  ...props
}: {
  d: string
  animate: any
  [key: string]: any
}) => (
  <motion.path
    d={d}
    stroke="url(#commonGradient)"
    strokeWidth="17.3"
    animate={animate}
    variants={PATH_VARIANT}
    initial="fade" // Start partially unfilled
    {...props}
  />
)

const RectWithForeignObject = ({
  animate,
  x,
  y,
  width,
  icon,
  label,
  rate,
  isStrategyVault,
  ...props
}: {
  animate: any
  x: string
  y: string
  width: string
  icon: string
  label: string
  rate: number
  isStrategyVault?: boolean
  [key: string]: any
}) => (
  <motion.g
    animate={animate}
    variants={RECT_VARIANT}
    initial="fade" // Start partially faded
    {...props}
  >
    <rect x={x} y={y} width={width} height="36px" rx="6" fill="white" />
    <motion.foreignObject x={x} y={y} width={width} height="36px">
      <HStack padding={1.5} h="36px" gap={3}>
        {isStrategyVault ? (
          <VaultCuratorIcon vaultName={icon} boxSize="24px" />
        ) : (
          <TokenIcon token={icon} boxSize="24px" />
        )}
        <Text
          variant={`${isStrategyVault ? 'body-sm' : 'body-xs'}/bold`}
          color="black"
        >
          {label}{' '}
          <Text
            variant={`${isStrategyVault ? 'body-sm' : 'body-xs'}/normal`}
            as="span"
          >
            {rate}%
          </Text>
        </Text>
      </HStack>
    </motion.foreignObject>
  </motion.g>
)
