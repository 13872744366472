import { wait } from './wait'
import { getMockPointsData } from '../test-helpers/data-points'
import config from '../config'
import { components, paths } from '../models/profile-api'

const POINTS_API = config.profileServerUrl

// @ts-ignore basic fetcher
export const pointsFetcher = (path: string) => {
  const url = `${POINTS_API}${path}`

  // this is used for mocking api return data can be removed after testing this wait time can be changed
  if (process.env.REACT_APP_POINTS_TESTING) {
    return wait(2000).then(() => getMockPointsData(url)) as Promise<any>
  }

  return fetch(url).then((res) => res.json())
}

export const postAnalyzeWallet = async (
  url: string,
  { arg }: { arg: string }
): Promise<
  paths['/profile/wallet-analyzer/{walletAddress}']['post']['responses']['200']['content']['application/json']
> => {
  console.log('Fetching', `${POINTS_API}${url}/${arg}`)
  const res = await fetch(`${POINTS_API}${url}/${arg}`, { method: 'POST' })
  return res.json()
}

export const postValidateInviteCode = async (
  url: string,
  { arg }: { arg: string }
): Promise<components['schemas']['ValidatedInviteCode']> => {
  const res = await fetch(`${POINTS_API}${url}/${arg}`, { method: 'POST' })
  if (!res.ok) {
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const postValidateReferralCode = async (
  url: string,
  { arg }: { arg: string }
): Promise<components['schemas']['ValidatedProfileReferral']> => {
  const res = await fetch(`${POINTS_API}${url}/${arg}`, { method: 'POST' })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}/${arg}`)
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const postLogin = async (
  url: string,
  {
    arg,
  }: {
    arg: paths['/profile/login']['post']['requestBody']['content']['application/json'] & {
      token?: string
    }
  }
): Promise<
  paths['/profile/login']['post']['responses']['200']['content']['application/json']
> => {
  const { token, ...args } = arg
  const headers: HeadersInit = token
    ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' }
  const res = await fetch(`${POINTS_API}${url}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(args),
  })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}`)
    const error = new Error(JSON.stringify(await res.json()))
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const patchProfile = async (
  url: string,
  {
    arg,
  }: {
    arg: paths['/profile/profile']['patch']['requestBody']['content']['application/json'] & {
      token?: string
    }
  }
): Promise<
  paths['/profile/profile']['patch']['responses']['200']['content']['application/json']
> => {
  const { token, ...args } = arg
  const headers: HeadersInit = token
    ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' }
  const res = await fetch(`${POINTS_API}${url}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(args),
  })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}`)
    const error = new Error(JSON.stringify(await res.json()))
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const deleteProfileData = async (
  url: string,
  { arg }: { arg: string }
): Promise<
  paths['/profile/profile']['delete']['responses']['200']['content']['application/json']
> => {
  const headers: HeadersInit = {
    Authorization: `Bearer ${arg}`,
    'Content-Type': 'application/json',
  }
  const res = await fetch(`${POINTS_API}${url}`, {
    method: 'DELETE',
    headers,
  })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}`)
    const error = new Error(JSON.stringify(await res.json()))
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const deleteProfileTwitter = async (
  url: string,
  { arg }: { arg: string }
): Promise<
  paths['/profile/profile/twitter']['delete']['responses']['200']['content']['application/json']
> => {
  const headers: HeadersInit = {
    Authorization: `Bearer ${arg}`,
    'Content-Type': 'application/json',
  }
  const res = await fetch(`${POINTS_API}${url}`, { method: 'DELETE', headers })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}`)
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const deleteProfileDiscord = async (
  url: string,
  { arg }: { arg: string }
): Promise<
  paths['/profile/profile/discord']['delete']['responses']['200']['content']['application/json']
> => {
  const headers: HeadersInit = {
    Authorization: `Bearer ${arg}`,
    'Content-Type': 'application/json',
  }
  const res = await fetch(`${POINTS_API}${url}`, { method: 'DELETE', headers })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}`)
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const deleteProfileWallet = async (
  url: string,
  { arg }: { arg: { token: string; walletAddress: string } }
): Promise<
  paths['/profile/profile/wallet/{walletAddress}']['delete']['responses']['200']['content']['application/json']
> => {
  const { token, walletAddress } = arg
  const headers: HeadersInit = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
  const res = await fetch(`${POINTS_API}${url}/${walletAddress}`, {
    method: 'DELETE',
    headers,
  })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}/${walletAddress}`)
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}

export const patchProfileWallet = async (
  url: string,
  { arg }: { arg: { token: string; walletAddress: string } }
): Promise<
  paths['/profile/profile/wallet/{walletAddress}']['patch']['responses']['200']['content']['application/json']
> => {
  const { token, walletAddress } = arg
  const headers: HeadersInit = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
  const res = await fetch(`${POINTS_API}${url}/${walletAddress}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify({ isPrimary: true }),
  })
  if (!res.ok) {
    console.error(`Failed to fetch ${POINTS_API}${url}/${walletAddress}`)
    const error = new Error(await res.text())
    // @ts-ignore append status
    error.status = res.status
    throw error
  }
  return res.json()
}
