import { VStack, Text, HStack, Flex } from '@chakra-ui/layout'
import { Trans } from '@lingui/macro'
import EarningsStatistics from './EarningsStatistics'
import { FixedNumber } from 'ethers'
import ClaimableData from './ClaimableData'
import { SocialActions, Socials, TokenClaim } from '../../../../models/rewards'
import walletAnalyzerBackground from '../../../../assets/wallet-analyzer-background.svg'
import SignIn from '../SignIn'
import { Address } from '../../../../data/model'
import { fixedCompare } from '../../../../helpers/math'
import dayjs from 'dayjs'

export default function ClaimSection({
  isBasicPage = false,
  isDataLoading,
  selectedAccount,
  account,
  claimEarnings,
  termTokenPrice,
  isSignedIn,
  connectedSocials,
  onLinkWallet,
  onLinkTwitter,
  onLinkDiscord,
  onConnect,
}: {
  isBasicPage?: boolean
  isDataLoading?: boolean
  selectedAccount?: Address
  account?: Address
  claimEarnings: { [season: number]: TokenClaim }
  termTokenPrice: FixedNumber
  isSignedIn: boolean
  connectedSocials: Record<Socials, boolean>
  onLinkWallet?: () => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
  onConnect?: () => void
}) {
  const season1Earnings = claimEarnings?.[1]
  const hasExpired =
    !!season1Earnings?.expirationDate &&
    dayjs(season1Earnings.expirationDate).isBefore(dayjs())
  const hasSeason1Earnings =
    !!season1Earnings &&
    season1Earnings.expirationDate &&
    fixedCompare(
      season1Earnings.totalAwardedAmount,
      'gt',
      FixedNumber.fromString('0')
    ) &&
    !hasExpired

  return (
    <VStack spacing={6} alignItems="start">
      <Text color={'blue.9'} variant={'body-2xl/medium'}>
        <Trans>Claim your earnings</Trans>{' '}
        {isBasicPage && account ? `for ${account}` : ''}
      </Text>
      {isSignedIn ? (
        <>
          {hasExpired && (
            <Text color={'red.5'} variant={'body-md/medium'}>
              Claim expired on{' '}
              {dayjs(season1Earnings.expirationDate).format(
                'MMM DD, YYYY HH:mm:ssZ'
              )}
            </Text>
          )}
          <HStack
            bg="white"
            py={7}
            px={4}
            spacing={12}
            w="full"
            border="1px solid #E4E7EB"
          >
            <EarningsStatistics
              title="Total Claimable Earnings"
              isDataLoading={isDataLoading}
              amountEarnings={season1Earnings.claimableAmountUSD}
              expirationDate={season1Earnings.expirationDate}
            />
            <EarningsStatistics
              title="Claimable in Term Tokens"
              isDataLoading={isDataLoading}
              amountEarnings={season1Earnings.claimableAmountUSD}
              expirationDate={season1Earnings.expirationDate}
            />
          </HStack>
          {hasSeason1Earnings && (
            <VStack w="full" spacing={6} alignItems="start">
              <ClaimableData
                claimEarnings={season1Earnings}
                termTokenPrice={termTokenPrice}
                activeWallet={account}
                primaryWallet={selectedAccount}
                onConnect={onConnect}
              />
            </VStack>
          )}
        </>
      ) : (
        <Flex
          w="full"
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          height="400px"
          pt={10}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="center"
          bgImage={`url(${walletAnalyzerBackground})`}
        >
          <SignIn
            account={account}
            subText="To claim your rewards"
            connectedSocials={connectedSocials}
            onLinkWallet={onLinkWallet}
            onLinkTwitter={() =>
              onLinkTwitter
                ? onLinkTwitter(
                    `/rewards/invite?action=${SocialActions.SignInTwitter}`
                  )
                : undefined
            }
            onLinkDiscord={() =>
              onLinkDiscord
                ? onLinkDiscord(
                    `/rewards/invite?action=${SocialActions.SignInDiscord}`
                  )
                : undefined
            }
          />
        </Flex>
      )}
    </VStack>
  )
}
