import { Box, keyframes, Text, useMediaQuery } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import Tooltip from '../../../../components/elements/Tooltip'
import { Address } from '../../../../data/model'
import { fixedToBigNumber } from '../../../../helpers/conversions'
import { socialLinks } from '../../../../socials'
import CircleIcon from './CircleIcon'
import { THOUSAND_IN_CENTS } from '../../../../helpers/constants'
import { formatAmount } from '../../../../helpers/utils'

export default function LiveAuctionResults({
  totalBids,
  totalBidAmountUsd,
  totalOffers,
  totalOfferAmountUsd,
  repoLockerAddress,
}: {
  totalBids: number
  totalBidAmountUsd: FixedNumber
  totalOffers: number
  totalOfferAmountUsd: FixedNumber
  repoLockerAddress: Address
}) {
  const [isSmallScreen] = useMediaQuery('(max-width: 1280px)')
  const bidAmountBN = fixedToBigNumber(totalBidAmountUsd)
  const offerAmountBN = fixedToBigNumber(totalOfferAmountUsd)

  const formattedData = {} as {
    bid: string
    offer: string
  }

  if (totalBids === 0 && totalOffers === 0) {
    return null // Show nothing if both totals are zero
  } else if (totalBids === 0) {
    formattedData.bid = '< $1.00K'
    formattedData.offer = formatAmount(totalOfferAmountUsd, offerAmountBN)
  } else if (totalOffers === 0) {
    formattedData.bid = formatAmount(totalBidAmountUsd, bidAmountBN)
    formattedData.offer = '< $1.00K'
  } else if (
    offerAmountBN.lt(THOUSAND_IN_CENTS) ||
    bidAmountBN.lt(THOUSAND_IN_CENTS)
  ) {
    formattedData.bid = formatAmount(totalBidAmountUsd, THOUSAND_IN_CENTS)
    formattedData.offer = formatAmount(totalOfferAmountUsd, THOUSAND_IN_CENTS)
  } else {
    // Round totals by smaller amount
    const smallerAmount = bidAmountBN.lt(offerAmountBN)
      ? bidAmountBN
      : offerAmountBN
    formattedData.bid = formatAmount(totalBidAmountUsd, smallerAmount)
    formattedData.offer = formatAmount(totalOfferAmountUsd, smallerAmount)
  }

  const showMetrics = totalBids > 0 || totalOffers > 0

  return (
    <Tooltip label="View on DeBank">
      <>
        {/* VIEW: > 1280px */}
        {isSmallScreen ? (
          <HStack justify="space-between" w="full">
            <HStack>
              <Text color="gray.6" variant="body-xs/normal">
                Bids ({totalBids}):
              </Text>
              <Text color="blue.9" variant="body-xs/medium">
                {formattedData.bid}
              </Text>
            </HStack>
            <Text color="gray.2" variant="body-xs/normal">
              |
            </Text>
            <HStack>
              <Text color="gray.6" variant="body-xs/normal">
                Offers ({totalOffers}):
              </Text>
              <Text color="blue.9" variant="body-xs/medium">
                {formattedData.offer}
              </Text>
            </HStack>
            <Text color="gray.2" variant="body-xs/normal">
              |
            </Text>
          </HStack>
        ) : (
          <a
            href={socialLinks.debankBaseUrl + repoLockerAddress}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              animation={`${pulse} 2400ms infinite`}
              _hover={{
                cursor: 'pointer',
              }}
            >
              {showMetrics && (
                <>
                  <HStack spacing={1}>
                    <VStack spacing={0}>
                      <Box textAlign="right" ml="auto">
                        <Text as="span" variant="body-xs/normal" color="blue.9">
                          {`${totalBids.toString()} bid${
                            totalBids === 1 ? '' : 's'
                          }  `}
                        </Text>
                      </Box>
                      <Box textAlign="right" ml="auto">
                        <Text as="span" variant="body-xs/normal" color="blue.9">
                          {`${totalOffers.toString()} offer${
                            totalOffers === 1 ? '' : 's'
                          }  `}
                        </Text>
                      </Box>
                    </VStack>
                    <VStack spacing={0}>
                      <Box>
                        <CircleIcon />
                      </Box>
                      <Box>
                        <CircleIcon />
                      </Box>
                    </VStack>
                    <VStack spacing={0} alignItems="end">
                      <Box textAlign="right" ml="auto">
                        <Text
                          as="span"
                          variant="body-xs/semibold"
                          color="blue.9"
                        >
                          {formattedData.bid}
                        </Text>
                      </Box>
                      <Box textAlign="right" ml={0}>
                        <Text
                          as="span"
                          variant="body-xs/semibold"
                          color="blue.9"
                          ml={0}
                        >
                          {formattedData.offer}
                        </Text>
                      </Box>
                    </VStack>
                  </HStack>
                </>
              )}
            </Box>
          </a>
        )}
      </>
    </Tooltip>
  )
}

const pulse = keyframes({
  '0%': {
    opacity: 0.4,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.4,
  },
})
