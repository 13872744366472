import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import soldout from '../../../../assets/icons/sold-out.svg'
import sparkle from '../../../../assets/icons/sparkle.svg'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack, VStack } from '../../../../components/elements/Stack'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { ListingsData } from '../../../../data/model'
import { THOUSAND_IN_CENTS } from '../../../../helpers/constants'
import {
  fixedToBigNumber,
  fixedToFormattedPercentage,
  formatFixed,
} from '../../../../helpers/conversions'
import { divide, fixedCompare, multiply } from '../../../../helpers/math'
import {
  formatAmount,
  hasEModeLabel,
  hasHighLTVLabel,
  shortenAddress,
} from '../../../../helpers/utils'
import { useConfig } from '../../../../providers/config'
import { ApyTooltipLabel } from '../ApyTooltipLabel'
import RepoCardButton from '../RepoCardButton'
import RepoCardDetails from '../RepoCardDetails'
import RepoCardHeading from '../RepoCardHeading'
import ExternalProjectPoints from '../../../../components/elements/ExternalProjectPoints'
import { useSafary } from '../../../../data/analytics/use-safary'
import HighLTVLabel from '../../../../components/elements/HighLTVLabel'

const SoldOutBadge = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="6px"
      py={0.5}
      px={2}
      color="rgba(56, 10, 0, 0.8)"
      bg="#FDF2E9"
      w="72px"
      gap={1}
    >
      <Image src={soldout} alt="Sold Out" w="9px" h="9px" />
      <Text fontSize="10px">Sold Out</Text>
    </Box>
  )
}

const RiskBadge = ({ risk }: { risk: string }) => {
  const color =
    risk === 'High' ? '#EB1B0E' : risk === 'Medium' ? '#E07F00' : 'green.6'
  const backgroundColor =
    risk === 'High' ? '#FFDCD9' : risk === 'Medium' ? '#FFF7EB' : 'green.1'
  return (
    <Text
      size="md"
      w="fit-content"
      borderRadius="6px"
      py={0.5}
      px={2}
      color={color}
      bg={backgroundColor}
    >
      {risk}
    </Text>
  )
}

export default function RepoCard({
  lend,
  isActive = false,
  isTriggerHoverEffect = false,
  showDetails,
  activeLendId,
  setActiveLendId,
}: {
  lend: ListingsData
  isActive?: boolean
  isTriggerHoverEffect?: boolean
  showDetails?: boolean
  activeLendId: string | undefined
  setActiveLendId: (itemId: string | undefined) => void
}) {
  const config = useConfig()
  const now = useCurrentTime()
  const chainConfig = config.chains[lend.chainId]

  const [isOpenExternal, setIsOpenExternal] = useState(showDetails)
  const { getDisclosureProps, getButtonProps, isOpen, onOpen, onClose } =
    useDisclosure()

  const { trackSafaryEvent } = useSafary()

  useEffect(() => {
    if (isOpenExternal && !isOpen) {
      onOpen()
    } else if (!isOpenExternal && isOpen) {
      onClose()
    }
  }, [isOpenExternal, isOpen, onOpen, onClose])

  const buttonProps = getButtonProps({
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation()
      if (!isOpenExternal) {
        trackSafaryEvent(
          config?.safary?.earn?.expandDetails?.type ?? 'click',
          'expand details',
          {
            purchaseToken: lend.purchaseToken,
            termRepo: lend.termRepoToken,
            chainId: lend.chainId,
            termRepoToken: lend.termRepoToken,
            collateralTokens: lend.collateralTokens.join(','),
          }
        )
      }
      setIsOpenExternal(!isOpenExternal) // Toggle isOpenExternal state
    },
  })

  const disclosureProps = getDisclosureProps()
  //const [active, setActive] = useState(isActive)
  const [focus, setFocus] = useState(false)

  const setActiveState = (itemId: string) => {
    setActiveLendId(activeLendId === itemId ? undefined : itemId)
    //setActive(!active)
  }

  // triggered by external prop, used when user clicks Lend box with no card selected
  const triggerHoverEffect = useCallback(() => {
    setFocus(true)
    setTimeout(() => setFocus(false), 1000) // Reset after 1 second
  }, [])

  useEffect(() => {
    if (isTriggerHoverEffect) {
      triggerHoverEffect()
    }
  }, [isTriggerHoverEffect, triggerHoverEffect])

  const auctionClearingRatePercentageValue = lend.lastAuctionClearingPrice
    ? multiply(
        lend.lastAuctionClearingPrice,
        FixedNumber.fromString('100', lend.lastAuctionClearingPrice.format)
      )
    : FixedNumber.fromString('0', 18)

  const fixedApyPercentage = useMemo(() => {
    return lend.lendApy
      ? multiply(
          lend.lendApy,
          FixedNumber.fromString('100', lend.lendApy.format)
        )
      : FixedNumber.fromString('0', 18)
  }, [lend.lendApy])

  const { formattedPercentage: formattedFixedApyPercentage } =
    fixedToFormattedPercentage(fixedApyPercentage, 2, true)

  const maintenanceCollatPercentage = lend.collateralRatios?.[0]
    ?.maintenanceRatio
    ? multiply(
        lend.collateralRatios[0].maintenanceRatio,
        FixedNumber.fromString(
          '100',
          lend.collateralRatios[0].maintenanceRatio.format
        )
      )
    : FixedNumber.fromString('0', 18)

  const { formattedPercentage: formattedCollateralRequirementPercentage } =
    fixedToFormattedPercentage(maintenanceCollatPercentage, 2)

  const collateralHealthPercentage = useMemo(() => {
    if (
      !lend?.totalDebtUsd ||
      !lend?.totalCollateralUsd ||
      lend.totalDebtUsd?.isZero() ||
      lend.totalCollateralUsd?.isZero()
    )
      return FixedNumber.fromString('0', 18)

    return multiply(
      divide(lend.totalCollateralUsd, lend.totalDebtUsd),
      FixedNumber.fromString('100', 18)
    )
  }, [lend.totalDebtUsd, lend.totalCollateralUsd])

  const { formattedPercentage: formattedCollateralHealthPercentage } =
    fixedToFormattedPercentage(collateralHealthPercentage, 2)

  const formattedRemainingAmount = formatFixed(lend.remainingAmount)

  const isCardDisabled =
    fixedCompare(
      lend.remainingAmount,
      'eq',
      FixedNumber.fromString('0', lend.remainingAmount.format)
    ) ||
    (formattedRemainingAmount[1] && formattedRemainingAmount[2])

  const [roundedLendDebtUsd, roundedCollateralDebtUsd] = useMemo(() => {
    const lendDebtUsdBN = lend.totalDebtUsd
      ? fixedToBigNumber(lend.totalDebtUsd, 2)
      : BigNumber.from(0)
    const lendCollateralUsdBN = lend?.totalCollateralUsd
      ? fixedToBigNumber(lend.totalCollateralUsd, 2)
      : BigNumber.from(0)

    let formattedLendDebt: string, formattedCollateralDebt: string
    if (
      !lend.totalDebtUsd ||
      !lend.totalCollateralUsd ||
      lend.totalDebtUsd?.isZero() ||
      lend.totalCollateralUsd?.isZero()
    ) {
      formattedLendDebt = '0'
      formattedCollateralDebt = '0'
    } else if (
      lendDebtUsdBN.lt(THOUSAND_IN_CENTS) ||
      lendCollateralUsdBN.lt(THOUSAND_IN_CENTS)
    ) {
      formattedLendDebt = formatAmount(lend.totalDebtUsd, THOUSAND_IN_CENTS)
      formattedCollateralDebt = formatAmount(
        lend.totalCollateralUsd,
        THOUSAND_IN_CENTS
      )
    } else {
      // Round totals by smaller amount
      const smallerAmount = lendCollateralUsdBN.lt(lendDebtUsdBN)
        ? lendCollateralUsdBN
        : lendDebtUsdBN

      formattedLendDebt = formatAmount(lend.totalDebtUsd, smallerAmount)
      formattedCollateralDebt = formatAmount(
        lend.totalCollateralUsd,
        smallerAmount
      )
    }

    return [formattedLendDebt, formattedCollateralDebt]
  }, [lend.totalCollateralUsd, lend.totalDebtUsd])

  const isHighLTV =
    lend &&
    hasHighLTVLabel(
      lend?.purchaseTokenMeta?.symbol ?? '',
      lend?.collateralTokensMeta?.[0]?.symbol ?? '',
      lend?.collateralRatios?.[0]?.maintenanceRatio
    )
  const isEMode =
    lend &&
    hasEModeLabel(
      lend?.purchaseTokenMeta?.symbol ?? '',
      lend?.collateralTokensMeta?.[0]?.symbol ?? '',
      lend?.collateralRatios?.[0]?.maintenanceRatio
    )

  return (
    <>
      <Flex
        key={lend.termRepoToken}
        flexDir="column"
        width={{
          base: '654px',
          '1xl': '816px',
          '3xl': '1023px',
        }}
        position="relative"
        borderRadius="6px"
        overflow="visible"
        boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
        cursor={isCardDisabled ? 'not-allowed' : 'pointer'}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        onClick={() => {
          if (!isCardDisabled) {
            setActiveState(lend.termRepoToken)

            if (lend.termRepoToken !== activeLendId) {
              trackSafaryEvent(
                config?.safary?.earn?.selectRepo?.type ?? 'click',
                'select active repo',
                {
                  purchaseToken: lend.purchaseToken,
                  termRepo: lend.termRepoToken,
                  chainId: lend.chainId,
                  termRepoToken: lend.termRepoToken,
                  collateralTokens: lend.collateralTokens.join(','),
                }
              )
            }
          }
        }}
      >
        {lend.externalProjects?.[0] && (
          <Flex position="absolute" right={'-16px'} top={'-16px'} zIndex={2}>
            <ExternalProjectPoints project={lend.externalProjects[0].project} />
          </Flex>
        )}
        {/* First Row */}
        <Flex
          {...(isOpen && { borderTopRadius: '6px' })}
          {...(!isOpen && { borderRadius: '6px' })}
          justifyContent="space-between"
          flexDir={{
            base: 'row',
          }}
          p={3}
          background={
            lend.termRepoToken === activeLendId || focus
              ? 'white'
              : chainConfig.gradient
          }
          opacity={
            lend.termRepoToken !== activeLendId && activeLendId ? 0.5 : 1
          }
          transition="all .25s ease-in-out"
          _hover={{
            transform:
              lend.termRepoToken !== activeLendId && !isOpen && 'scale(1.01)',
          }}
        >
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            flexDirection={{
              base: 'row',
            }}
            py={2.5}
          >
            {/* Fixed APY */}
            <Box
              h="100%"
              w="130px"
              borderRight={{
                base: '1px solid',
              }}
              borderRightColor={{
                base: 'gray.3',
              }}
              pr={2}
            >
              <RepoCardHeading title="Fixed APY" mb={2} />
              <HStack w="100%">
                <Text variant="body-2xl/bold" color="black" mr="auto">
                  {formattedFixedApyPercentage}%
                </Text>
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={ApyTooltipLabel(
                    fixedApyPercentage,
                    auctionClearingRatePercentageValue,
                    lend.externalProjects?.[0]?.label,
                    lend.externalProjects?.[0]?.pointsMultiplier,
                    lend.termPointsMultiplier
                  )}
                >
                  <Image boxSize="24px" src={sparkle} alt={'Fixed APY'} />
                </Tooltip>
              </HStack>
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '60px 90px 60px',
              }}
              gap={{
                base: '38px',
                '1xl': 16,
                '3xl': 36,
              }}
            >
              {/* Maturity */}
              <Flex
                flexDirection="column"
                alignItems="left"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Maturity" mb={2} />
                <Text
                  variant="body-l/normal"
                  color="blue.9"
                  whiteSpace="nowrap"
                >
                  {dayjs.unix(lend.redemptionTimestamp).format('MMM D')}
                </Text>
              </Flex>
              {/* Earn Limit */}
              <Flex
                flexDirection="column"
                align="end"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Earn Limit" mb={2} />
                <HStack>
                  {isCardDisabled ? (
                    <SoldOutBadge />
                  ) : (
                    <Text
                      variant="body-l/normal"
                      color="blue.9"
                      whiteSpace="nowrap"
                    >
                      {
                        formatFixed(lend.remainingAmount, {
                          displayDecimals: 2,
                        })[0]
                      }
                    </Text>
                  )}
                </HStack>
              </Flex>
              {/* Risk */}
              {/* <Flex
                flexDirection="column"
                alignItems="left"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Risk" mb={2} />
                <RiskBadge risk={lend.risk} />
              </Flex> */}
              {/* Multiplier */}
              <Flex
                flexDirection="column"
                align="end"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Multiplier" mb={2} />
                <Tooltip
                  placement="bottom"
                  label={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Text as="span" textAlign="left">
                        Lenders earn 1x Term points for holding this loan.{' '}
                        <Link isExternal href="/rewards" color="white">
                          <Text as="u">Learn more</Text>
                        </Link>
                      </Text>
                    </Box>
                  }
                >
                  <Text
                    variant="body-l/normal"
                    color="blue.9"
                    whiteSpace="nowrap"
                  >
                    {lend.termPointsMultiplier}x
                  </Text>
                </Tooltip>
              </Flex>
            </Grid>
          </Flex>

          {/* HighLTV Label */}
          {(isHighLTV || isEMode) && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              display={{
                base: 'none',
                xl: 'flex',
              }}
              px={{
                base: '20px',
                xl: '0',
              }}
            >
              <VStack w="64px" h="32px" justify="center" align="start" mt={3}>
                <HighLTVLabel
                  ltvRatio={divide(
                    FixedNumber.fromString(
                      '1',
                      lend?.collateralRatios?.[0]?.maintenanceRatio?.format
                    ),
                    lend?.collateralRatios?.[0]?.maintenanceRatio
                  )}
                  maintenanceCollateralRatio={
                    lend?.collateralRatios?.[0]?.maintenanceRatio
                  }
                />
              </VStack>
            </Flex>
          )}
          {/* Button */}
          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={2}
          >
            <HStack pt={4} spacing={0.5} justifyContent="right" h="16px">
              <Text
                variant="body-sm/medium"
                color="gray.5.5"
                mb="4px"
                textAlign={{
                  base: 'right',
                }}
                whiteSpace="nowrap"
                fontSize={{ base: '2xs', '1xl': 'xs' }}
                m={0}
              >
                Matures: {dayjs.unix(lend.repurchaseTimestamp).from(now)}
              </Text>
              <Flex align="center" justify="center" mb={1}>
                <NetworkIcon
                  key={lend.chainId}
                  chainId={lend.chainId}
                  boxSize={'16px'}
                  variant="faded"
                  withTooltip
                />
              </Flex>
            </HStack>
            <RepoCardButton
              isActive={lend.termRepoToken === activeLendId}
              isDisabled={isCardDisabled}
              isHovered={focus}
              handleClick={() => {
                if (!isCardDisabled) {
                  setActiveState(lend.termRepoToken)
                }
              }}
            >
              Earn
            </RepoCardButton>
            <Button
              size="xs"
              variant="unstyled"
              alignSelf="flex-end"
              color="blue.5"
              h="16px"
              {...buttonProps}
            >
              <HStack justifyContent="flex-end" pr={1}>
                <Text as="span">Details</Text>
                <Box
                  marginInlineStart={0}
                  color="blue.5"
                  as="span"
                  transform={!isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
                  transition="transform 0.2s"
                >
                  <FontAwesomeIcon
                    icon={['far', 'chevron-down']}
                    role="button"
                    width="10px"
                  />
                </Box>
              </HStack>
            </Button>
          </Flex>
        </Flex>

        {/* Second Row */}
        <Flex
          borderBottomRadius="6px"
          {...disclosureProps}
          w="full"
          flexDir={{
            base: 'row',
          }}
          px={{
            base: '12px',
          }}
          py={{
            base: '24px',
          }}
          bg={
            lend.termRepoToken === activeLendId
              ? 'rgba(228, 231, 235, 0.2)'
              : 'rgba(250, 250, 251, 1)'
          }
          opacity={
            lend.termRepoToken !== activeLendId && activeLendId ? 0.5 : 1
          }
        >
          <Grid flexGrow={1} gridTemplateColumns="repeat(4, 1fr)" w="100%">
            {/* Col1*/}
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="flex-start"
            >
              <RepoCardDetails
                text="Collateral Asset"
                isFirst={true}
                emboldened={
                  <TokenIconLabel
                    key={lend.collateralTokens[0]}
                    token={lend.collateralTokensMeta?.[0].symbol}
                    isInheritParentTextVariant
                    boxSize="16px"
                  />
                }
              />
            </Flex>
            {/* Col2*/}
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="flex-start"
              gap={2}
              borderLeft="1px solid"
              borderRight="1px solid"
              borderColor="gray.2"
            >
              <RepoCardDetails
                text="Collateral Requirement"
                emboldened={formattedCollateralRequirementPercentage}
              />
              <RepoCardDetails
                text="Collateral Health"
                emboldened={
                  lend.isEarlyRepay
                    ? 'Repaid Early'
                    : formattedCollateralHealthPercentage
                }
              />
            </Flex>
            {/* Col3 */}
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="flex-start"
              gap={2}
              borderRight="1px solid"
              borderColor="gray.2"
            >
              <RepoCardDetails
                text="Total Debt"
                emboldened={roundedLendDebtUsd}
                tooltipLabel={lend.totalDebtUsd?.toString()}
              />
              <RepoCardDetails
                text="Total Collateral"
                emboldened={roundedCollateralDebtUsd}
                tooltipLabel={lend.totalCollateralUsd?.toString()}
              />
            </Flex>
            {/* Col4 */}
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="flex-start"
              gap={2}
            >
              <RepoCardDetails
                isLast
                text="Receipt Token"
                emboldened={shortenAddress(lend.termRepoToken, {
                  charsFront: 2,
                  charsBack: 2,
                })}
                link={chainConfig?.getExplorerAddressLink(lend.termRepoToken)}
              />
              <RepoCardDetails
                isLast
                text="Repo Locker"
                emboldened={shortenAddress(lend.termRepoLocker, {
                  charsFront: 2,
                  charsBack: 2,
                })}
                link={chainConfig?.getExplorerAddressLink(lend.termRepoLocker)}
              />
            </Flex>
          </Grid>
        </Flex>
      </Flex>
    </>
  )
}
