import { useMemo } from 'react'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { useConfig } from '../../providers/config'
import { Address } from '../model'

export type MerkleRootClaim = {
  merkleRoot: string
  tokenTotal: string
  claims: {
    [address: string]: {
      index: number
      amount: string
      proof: string[]
    }
  }
}

type MerkleRootAPI = {
  merkleRoot: string
  tokenTotal: string
  index: number
  amount: string
  proof: string[]
}

// note this is currently hardcoded to season 1
export function useMerkleRootClaim(
  account: Address | undefined,
  airdropCA: Address | undefined
): MerkleRootClaim | undefined {
  const config = useConfig()

  const airdropCheckerUrl = useMemo(() => {
    if (account && airdropCA) {
      return `${config.protocolServerUrl}/protocol/airdrop/${airdropCA}/claimable/${account.toLowerCase()}/1`
    } else {
      return undefined
    }
  }, [account, airdropCA, config.protocolServerUrl])

  const { data: season1TokenRewardsAllWallets } = useJsonRestCalls<
    { '#call': RequestInfo },
    MerkleRootAPI
  >({
    '#call': {
      url: airdropCheckerUrl,
      method: 'GET',
    } as RequestInfo,
  })

  const transformedMerkleRoot = transformMerkleRoot(
    account?.toLowerCase(),
    season1TokenRewardsAllWallets
  )

  return transformedMerkleRoot
}

export function transformMerkleRoot(
  account?: Address,
  api?: MerkleRootAPI
): MerkleRootClaim | undefined {
  if (!api || !account) {
    return undefined
  } else {
    return {
      merkleRoot: api.merkleRoot,
      tokenTotal: api.tokenTotal,
      claims: {
        [account]: {
          index: api.index,
          amount: api.amount,
          proof: api.proof,
        },
      },
    }
  }
}
