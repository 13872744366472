import dayjs from 'dayjs'
import { components as componentsProfile } from '../models/profile-api'
import { paths as pathsProtocol } from '../models/protocol-api'
import { SignUpValidation, TokenClaim } from '../models/rewards'
import { FixedNumber } from 'ethers'

export const mockPublicProfileApiDataObjectWalletOnly: componentsProfile['schemas']['ProfilePublicData'] =
  {
    totalPoints: 0,
    lastUpdated: 0,
    pointsBreakdown: {},
    wallet: {
      totalPoints: 5575079.706611934,
      lastUpdated: 0,
      pointsBreakdown: {
        0: {
          points: {
            auctionPoints: {
              _subtotal: 0,
            },
            lendingPoints: {
              _subtotal: 0,
            },
            otherPoints: {
              _subtotal: 0,
            },
            migrationPoints: {
              _subtotal: 0,
            },
            invitePoints: {
              _subtotal: 0,
            },
            preseasonPoints: {
              meta: {
                debank: 70000,
                discord: 0,
                mainnet: 70000,
                testnet: 0,
                twitter: 0,
                supporter: 0,
              },
              _subtotal: 140000,
            },
          },
          seasonId: 0,
        },
        1: {
          points: {
            auctionPoints: {
              meta: {
                totalBids: 20,
                totalOffers: 0,
                totalBidAmount: 7903855.857798292,
                noAuctionsFilled: 16,
                totalOfferAmount: 4299.84366136,
                totalAuctionPoints: 5435079.706611934,
                noWeeksParticipated: 14,
                multiplierConsistency: 1.1,
                noAuctionsParticipated: 19,
                totalAssignedBidAmount: 6796521.067235013,
                uniqueAuctionPairsCount: 3,
                totalAssignedOfferAmount: 4299.84366136,
              },
              _subtotal: 54545.706611934,
            },
            lendingPoints: {
              _subtotal: 100,
              meta: {
                averageBalance: 100,
              },
            },
            otherPoints: {
              _subtotal: 54545.706611934,
            },
            migrationPoints: {
              _subtotal: 0,
            },
            invitePoints: {
              _subtotal: 5575079.706611934,
            },
            preseasonPoints: {
              _subtotal: 0,
            },
          },
          seasonId: 1,
        },
      },
      wallet: '0x1235719',
    },
    hasLinkedDiscord: false,
    hasLinkedTwitter: false,
    profileExists: false,
    walletExists: true,
  }

export const mockPublicProfileApiDataObjectProfile: componentsProfile['schemas']['ProfilePublicData'] =
  {
    totalPoints: 253537.33875155885,
    lastUpdated: 0,
    pointsBreakdown: {
      0: {
        points: {
          auctionPoints: {
            _subtotal: 0,
          },
          lendingPoints: {
            _subtotal: 0,
          },
          otherPoints: {
            _subtotal: 0,
          },
          migrationPoints: {
            _subtotal: 0,
          },
          invitePoints: {
            _subtotal: 0,
          },
          preseasonPoints: {
            meta: {
              debank: 0,
              discord: 0,
              mainnet: 140000,
              testnet: 4000,
              twitter: 0,
              supporter: 0,
            },
            _subtotal: 140000,
          },
        },
        seasonId: 0,
      },
      1: {
        points: {
          auctionPoints: {
            meta: {
              totalBids: 0,
              totalOffers: 2,
              totalBidAmount: 0,
              noAuctionsFilled: 2,
              totalOfferAmount: 4299.84366136,
              totalAuctionPoints: 11292.981519785524,
              noWeeksParticipated: 2,
              multiplierConsistency: 2,
              noAuctionsParticipated: 2,
              totalAssignedBidAmount: 0,
              uniqueAuctionPairsCount: 2,
              totalAssignedOfferAmount: 4299.84366136,
            },
            _subtotal: 11292.981519785524,
          },
          lendingPoints: {
            meta: {
              averageBalance: 311.8761333493663,
            },
            _subtotal: 102244.35723177333,
          },
          otherPoints: {
            _subtotal: 0,
          },
          migrationPoints: {
            _subtotal: 0,
          },
          invitePoints: {
            _subtotal: 0,
          },
          preseasonPoints: {
            _subtotal: 0,
          },
        },
        seasonId: 1,
      },
    },
    profile: 6,
    wallet: {
      totalPoints: 174564.98706947736,
      lastUpdated: 0,
      pointsBreakdown: {
        0: {
          points: {
            auctionPoints: {
              _subtotal: 0,
            },
            lendingPoints: {
              _subtotal: 0,
            },
            otherPoints: {
              _subtotal: 0,
            },
            migrationPoints: {
              _subtotal: 0,
            },
            invitePoints: {
              _subtotal: 0,
            },
            preseasonPoints: {
              meta: {
                debank: 0,
                discord: 0,
                mainnet: 70000,
                testnet: 0,
                twitter: 0,
                supporter: 0,
              },
              _subtotal: 70000,
            },
          },
          seasonId: 0,
        },
        1: {
          points: {
            auctionPoints: {
              meta: {
                totalBids: 0,
                totalOffers: 1,
                totalBidAmount: 0,
                noAuctionsFilled: 1,
                totalOfferAmount: 3280.65918196,
                totalAuctionPoints: 10482.48719569124,
                noWeeksParticipated: 1,
                multiplierConsistency: 1,
                noAuctionsParticipated: 1,
                totalAssignedBidAmount: 0,
                uniqueAuctionPairsCount: 1,
                totalAssignedOfferAmount: 3280.65918196,
              },
              _subtotal: 10482.48719569124,
            },
            lendingPoints: {
              meta: {
                averageBalance: 286.9800062409737,
              },
              _subtotal: 94082.49987378612,
            },
            otherPoints: {
              _subtotal: 0,
            },
            migrationPoints: {
              _subtotal: 0,
            },
            invitePoints: {
              _subtotal: 0,
            },
            preseasonPoints: {
              _subtotal: 0,
            },
          },
          seasonId: 1,
        },
      },
      wallet: '0x123456',
    },
    hasLinkedDiscord: false,
    hasLinkedTwitter: true,
    profileExists: true,
    walletExists: true,
  }

export const mockProfileApiDataObject: componentsProfile['schemas']['ProfileData'] =
  {
    profile: {
      id: 6,
      twitterId: '',
      discordId: '',
      referralCode: '',
      meta: {
        accountName: '0x972a',
        isPreseason: true,
      },
      twitterData: undefined,
      discordData: undefined,
      createdAt: '2024-05-02T14:51:45.706Z',
      lastUpdated: '2024-05-02T14:51:45.706Z',
    },
    badges: [],
    inviteCodes: [
      {
        code: 'DULWJ',
        profileId: 6,
        refereeId: undefined,
        meta: {
          progress: {
            signedUp: false,
            usedInvite: true,
            earnedPoints: false,
          },
        },
        consumedTimestamp: null,
      },
      {
        code: '6OFGG',
        profileId: 6,
        refereeId: undefined,
        meta: {
          progress: {
            signedUp: false,
            usedInvite: true,
            earnedPoints: false,
          },
        },
        consumedTimestamp: null,
      },
      {
        code: 'S29V3',
        profileId: 6,
        refereeId: undefined,
        meta: {
          progress: {
            signedUp: false,
            usedInvite: true,
            earnedPoints: false,
          },
        },
        consumedTimestamp: null,
      },
      {
        code: '7TIY8',
        profileId: 6,
        refereeId: 11,
        meta: {
          progress: {
            signedUp: true,
            usedInvite: true,
            earnedPoints: false,
          },
        },
        consumedTimestamp: '2024-05-02T19:24:45.938Z',
      },
      {
        code: 'EO3SX',
        profileId: 6,
        refereeId: undefined,
        meta: {
          progress: {
            signedUp: false,
            usedInvite: true,
            earnedPoints: false,
          },
        },
        consumedTimestamp: null,
      },
    ],
    pointsBreakdown: {
      0: {
        points: {
          auctionPoints: {
            _subtotal: 0,
          },
          lendingPoints: {
            _subtotal: 0,
          },
          otherPoints: {
            _subtotal: 0,
          },
          migrationPoints: {
            _subtotal: 0,
          },
          invitePoints: {
            _subtotal: 0,
          },
          preseasonPoints: {
            meta: {
              debank: 0,
              discord: 0,
              mainnet: 140000,
              testnet: 0,
              twitter: 0,
              supporter: 0,
            },
            _subtotal: 140000,
          },
        },
        seasonId: 0,
      },
      1: {
        points: {
          auctionPoints: {
            meta: {
              totalBids: 0,
              totalOffers: 2,
              totalBidAmount: 0,
              noAuctionsFilled: 2,
              totalOfferAmount: 4299.84366136,
              totalAuctionPoints: 11292.981519785524,
              noWeeksParticipated: 2,
              multiplierConsistency: 2,
              noAuctionsParticipated: 2,
              totalAssignedBidAmount: 0,
              uniqueAuctionPairsCount: 2,
              totalAssignedOfferAmount: 4299.84366136,
            },
            _subtotal: 11292.981519785524,
          },
          lendingPoints: {
            meta: {
              averageBalance: 311.8761333493663,
            },
            _subtotal: 102244.35723177333,
          },
          otherPoints: {
            meta: {
              twelveDaysHayes: 300,
              discordRoles: 60000,
              GALXE: 95472,
              discord: 17500,
              twitter: 17500,
              guildMember: 1000,
              uniqueTasks: {
                hasRetweetedVaultsAnnouncement: 10000,
                hasRetweetedRewardsAnnouncement: 17500,
                hasRetweetedWalletAnalyzerAnnouncement: 5000,
              },
              guildFarmooor: 4000,
              guildEnthusiast: 1000,
              guildXSpaceUsual: 1000,
              guildOGSupporter: 945646,
            },
            _subtotal: 0,
          },
          migrationPoints: {
            meta: {
              noAuctionsFilled: 2,
              totalEligiblePoints: 198155.25717117352,
              firstDate: '2020-04-30',
              lastDate: '2024-03-06',
              a2NumTransactions: 4,
              a3NumTransactions: 3,
              c2NumTransactions: 5,
              c3NumTransactions: 2,
              m1NumTransactions: 2,
              s1NumTransactions: 15,
              a3HasChurned: undefined,
              a2HasChurned: undefined,
              c3HasChurned: undefined,
              c2HasChurned: undefined,
              m1HasChurned: undefined,
              s1HasChurned: false,
            },
            _subtotal: 198155.25717117352,
          },
          invitePoints: {
            _subtotal: 10030,
            meta: {
              totalInviteePoints: 100300,
              inviteePoints: {
                7: 100300,
              },
            },
          },
          preseasonPoints: {
            _subtotal: 0,
          },
        },
        seasonId: 1,
      },
    },
    wallets: [
      {
        address: '0x1',
        signature: 'test',
        meta: {
          isContractAccount: false,
        },
        isPrimary: false,
        createdAt: '2024-05-01T14:17:42.119Z',
        lastUpdated: '2024-05-01T14:17:42.119Z',
      },
      {
        address: '0x2',
        signature: '0xSign',
        meta: {
          isContractAccount: false,
        },
        isPrimary: true,
        createdAt: '2024-05-01T17:47:41.302Z',
        lastUpdated: '2024-05-01T17:47:41.302Z',
      },
    ],
    totalInvitesUsed: 1,
    totalInvitesAvailable: 4,
    totalPoints: 253537.33875155885,
    lastUpdated: 0,
  }

export const mockSeasonApiDataObject: componentsProfile['schemas']['Season'] = {
  id: 1,
  start: dayjs('2023-06-01').toISOString(),
  end: dayjs('2024-06-01').toISOString(),
  name: 'Season 1',
  isLocked: false,
  meta: {
    socialPoints: 123,
  },
}

export const mockInviteCodes: componentsProfile['schemas']['InviteCode'][] = [
  {
    code: 'tf544',
    refereeId: undefined,
    meta: {
      progress: {
        usedInvite: false,
        signedUp: false,
        earnedPoints: false,
      },
      appPoints: 700,
      inviteeName: undefined,
      inviteePhoto: undefined,
    },
  },
  {
    code: 'tf545',
    refereeId: undefined,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: false,
        earnedPoints: false,
      },
      appPoints: 400000,
      inviteeName: undefined,
      inviteePhoto: undefined,
    },
  },
  {
    code: 'tf547',
    refereeId: undefined,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: false,
        earnedPoints: false,
      },
      appPoints: 120000,
      inviteeName: undefined,
      inviteePhoto: undefined,
    },
  },
  {
    code: 'tf548',
    refereeId: undefined,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: true,
        earnedPoints: false,
      },
      appPoints: 99000,
      inviteeName: 'WiggieWiggieWiggie',
      inviteePhoto: undefined,
    },
    consumedTimestamp: '2024-05-02T19:24:45.938Z',
  },
  {
    code: 'tf549',
    refereeId: 7,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: true,
        earnedPoints: true,
      },
      appPoints: 17000,
      inviteeName: 'SammieSong',
      inviteePhoto: undefined,
    },
    consumedTimestamp: '2024-06-02T19:24:45.938Z',
  },
  {
    code: 'tf549',
    refereeId: 7,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: true,
        earnedPoints: true,
      },
      appPoints: 78000,
      inviteeName: 'SammieSong',
      inviteePhoto: undefined,
    },
    consumedTimestamp: '2024-07-02T19:24:45.938Z',
  },
]

export const mockprofileBadges: componentsProfile['schemas']['Badge'][] = [
  {
    id: 1,
    meta: {
      name: 'OG DeFi lender',
      description: '',
      image: '',
      points: 1000,
    },
    pointsEarned: 1000,
  },
  {
    id: 1,
    meta: {
      name: 'Testnet user',
      description: '',
      image: '',
      points: 1000,
    },
    pointsEarned: 1000,
  },
  {
    id: 1,
    meta: {
      name: 'OG DeFi borrower',
      description: '',
      image: '',
      points: 1000,
    },
    pointsEarned: 1000,
  },
  {
    id: 1,
    meta: {
      name: 'First 100 sign up',
      description: '',
      image: '',
      points: 1000,
    },
    pointsEarned: 1000,
  },
  {
    id: 1,
    meta: {
      name: 'Preseason user',
      description: '',
      image: '',
      points: 1000,
    },
    pointsEarned: 1000,
  },
]

export const mockValidatedInviteCode: componentsProfile['schemas']['ValidatedInviteCode'] =
  {
    code: 'tf548',
    inviter: {
      name: '@SammieSong',
      image:
        'https://pbs.twimg.com/profile_images/1684200132873424896/NNN9mHzH_400x400.png',
    },
  }

export const mockSignUpValidation: SignUpValidation = {
  type: 'invite',
  code: 'tf548',
  name: '@SammieSong',
  image:
    'https://pbs.twimg.com/profile_images/1684200132873424896/NNN9mHzH_400x400.png',
}

export const mockTokenClaim: TokenClaim = {
  seasonId: 1,
  seasonName: 'Season 1',
  airdropCA: 'airdropCA',
  expirationDate: dayjs('2025-06-01').unix(),
  claimableAmount: FixedNumber.fromString('100000'),
  claimableAmountUSD: FixedNumber.fromString('100005'),
  totalAwardedAmount: FixedNumber.fromString('100000'),
  totalAwardedAmountUSD: FixedNumber.fromString('100005'),
  hasClaimedTokens: false,
  hasStakedTokens: false,
  claimTokens: async () => {
    return 'Success'
  },
  importTokens: async () => {
    return true
  },
  stakeTokens: async () => {},
}

export const emptyTokenClaim: TokenClaim = {
  seasonId: 0,
  seasonName: '',
  airdropCA: undefined,
  expirationDate: undefined,
  claimableAmount: FixedNumber.fromString('0'),
  claimableAmountUSD: FixedNumber.fromString('0'),
  totalAwardedAmount: FixedNumber.fromString('0'),
  totalAwardedAmountUSD: FixedNumber.fromString('0'),
  hasClaimedTokens: false,
  hasStakedTokens: false,
  claimTokens: async () => {
    return 'Success'
  },
  importTokens: async () => {
    return true
  },
  stakeTokens: () => {},
}

export const mockAirdropAPIClaimedData: pathsProtocol['/protocol/airdrop/{airdropAddress}/claimed/{claimerAddress}/{chainId}']['get']['responses']['200']['content']['application/json'] =
  [
    {
      wallet: '0x3240fcf870cd13bfff806362f9f4723bbf8bf3d9',
      index: 1,
      amount: '0',
    },
  ]
