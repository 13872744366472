import { Flex, Image, Link, Text } from '@chakra-ui/react'
import arrow_up_right from '../../../assets/icons/arrow-up-right.svg'
import { useTokenIcon } from '../../../data/hooks/use-token-icons'
import { ERROR_STR } from '../../../helpers/constants'
import { HStack } from '../Stack'
import Tooltip from '../Tooltip'
import { getDisplayToken } from '../../../helpers/utils'

const TokenIconLabel = ({
  isSmall,
  isSkipTruncation,
  isSkipCapitalization,
  priceDisplay = {
    base: 'flex',
  },
  token,
  displayTokenLabel,
  price,
  href,
  isBold,
  isSemiBold,
  isInheritParentTextVariant = false,
  boxSize = '24px',
  customVariant,
  colorSymbol,
  isMedium,
  columnGap,
}: {
  isSmall?: boolean
  isSkipTruncation?: boolean
  isSkipCapitalization?: boolean
  priceDisplay?: {} | string
  token: string
  displayTokenLabel?: string // this is used to display a different token label than what is used for mapping, (eg term repo token label)
  price?: string
  href?: string
  isBold?: boolean
  isSemiBold?: boolean
  isInheritParentTextVariant?: boolean
  boxSize?: string
  customVariant?: string
  colorSymbol?: string
  isMedium?: boolean
  columnGap?: string
}) => {
  const src = useTokenIcon(token)
  const fontWeight = isBold
    ? 'bold'
    : isSemiBold
      ? 'semibold'
      : isMedium
        ? 'medium'
        : 'normal'
  let textVariant = isSmall ? 'body-sm/normal' : 'body-md/normal'
  textVariant = isInheritParentTextVariant ? 'inherit' : textVariant

  const baseLabel = displayTokenLabel ?? token
  const { displayToken, isTruncated } = getDisplayToken(baseLabel, {
    skipTruncation: isSkipTruncation,
  })

  const tokenText = isSkipCapitalization
    ? displayToken
    : displayToken.toUpperCase()
  const tooltipLabel = isSkipCapitalization
    ? baseLabel
    : baseLabel.toUpperCase()

  return (
    <Flex alignItems="center" columnGap={columnGap || '4px'}>
      {token !== ERROR_STR && <Image boxSize={boxSize} src={src} alt={token} />}
      <Tooltip
        leftAlign
        label={isTruncated ? tooltipLabel : undefined}
        placement="bottom"
      >
        <Text
          color={colorSymbol || 'blue.9'}
          whiteSpace="nowrap"
          variant={customVariant || textVariant}
          fontWeight={fontWeight}
        >
          {tokenText}
        </Text>
      </Tooltip>
      {price && (
        <HStack display={priceDisplay}>
          <Text color="blue.9" whiteSpace="nowrap" variant={textVariant}>
            •
          </Text>
          <Text color="gray.5" whiteSpace="nowrap" variant={textVariant}>
            {price}
          </Text>
          {href && (
            <Link isExternal href={href}>
              <Image boxSize={boxSize} src={arrow_up_right} alt="arrow" />
            </Link>
          )}
        </HStack>
      )}
    </Flex>
  )
}

export default TokenIconLabel
