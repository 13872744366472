export type VaultType = 'Yearn V3'

export enum TabOption {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export enum TabVaultOption {
  Chart = 0,
  Holdings = 1,
  Collateral = 2,
  CuratorInfo = 3,
  Governance = 4,
}
